import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImageType } from "../../types/imageTypes";

type InitialStateType = {
  images: ImageType[];
  imagesByBusinessId: ImageType[];
};

const initialState: InitialStateType = {
  images: [],
  imagesByBusinessId: [],
};

const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    setImages: (state, action: PayloadAction<ImageType[]>) => {
      state.images = action.payload;
    },
    setImagesByBusinessId: (state, action: PayloadAction<ImageType[]>) => {
      state.imagesByBusinessId = action.payload;
    },
    addImage: (state, action: PayloadAction<ImageType>) => {
      state.images.push(action.payload);
    },
    // ... other reducers if necessary
  },
});

export const imageActions = imageSlice.actions;
export default imageSlice.reducer;