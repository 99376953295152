import { businessActions } from "../slice/business";
import { AppDispatch } from "../store";
import { url } from "../../App";
// import axios from "axios";

export function fetchBusinessData() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetch(`${url}/business`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      dispatch(businessActions.getAllBusinesses(data));
    } catch (error) {
      console.log(
        "There was a problem with the fetch operation:",
        (error as Error).message
      );
    } 
  };
}
