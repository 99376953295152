import { Modal, Button } from "@mui/material";

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onResendConfirmation: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onResendConfirmation,
}) => {
  return (
    <Modal sx={{ width: "400px" }} open={open} onClose={onClose}>
      <div>
        <h2>Confirmation Email Sent</h2>
        <p>
          A confirmation email has been sent to your email address. Please check
          your inbox and follow the instructions to confirm your account.
        </p>
        <Button onClick={onResendConfirmation}>
          Resend Confirmation Email
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
