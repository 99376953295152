import { url } from "../../App";
import { AppDispatch } from "../store";
import { businessActions } from "./../slice/business";


// export function fetchBusinessDetail(id: string | undefined) {
//   return async (dispatch: AppDispatch) => {
//     try {
//       // Check if 'id' is not undefined before making the fetch request
//       if (id) {
//         const response = await fetch(`${url}/business/${id}`);
//         if (!response.ok) {
//           throw new Error("Failed to fetch business details");
//         }
//         const data = await response.json();
//         // Dispatch the action with the fetched business data
//         dispatch(businessActions.getBusinessDetails(data));
//       } else {
  
//         console.error("Error: Business ID is undefined.");
     
//       }
//     } catch (error) {
//       console.error("Error fetching business details:", error);
 
//     }
//   };
// }
export function fetchBusinessDetail(id: string | undefined) {
  return async (dispatch: AppDispatch) => {
    // Return early if 'id' is undefined
    if (!id) {
      console.error("Error: Business ID is undefined.");
      return;
    }

    try {
      // Dispatch an action to clear previous business details and set loading state
      dispatch(businessActions.clearBusinessDetails());
      dispatch(businessActions.setBusinessLoading(true));

      const response = await fetch(`${url}/business/${id}`);

      if (!response.ok) {
        throw new Error(`Failed to fetch business details. Status: ${response.status}`);
      }

      const data = await response.json();

      // Dispatch the action with the fetched business data
      dispatch(businessActions.getBusinessDetails(data));

    } catch (error: any) {
      console.error("Error fetching business details:", error.message || error);

      // Optional: Dispatch an error action to update state
      dispatch(businessActions.fetchBusinessDetailsError(error.message || "Unknown error"));
    } finally {
      // Set loading state to false after the request is complete
      dispatch(businessActions.setBusinessLoading(false));
    }
  };
}

