import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import NavBar from "./components/navbar/NavBar";
import Business from "./pages/business/Business";
import LogIn from "./pages/user/LogIn";
import Registration from "./pages/registration/Registration";
import UserInformation from "./pages/UserInformation";
import UserMng from "./pages/UserMng";
import BusinessMng from "./pages/BusinessMng";
import CommentMng from "./pages/CommentMng";
import Favorite from "./pages/Favorite";
import DetailedBusiness from "./pages/DetailedBusiness";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import Footer from "./components/footer/Footer";
import GoogleLogIn from "./components/users/googleLogIn/GoogleLogIn";
import { LanguageProvider } from "./components/languages/LanguageContext";
import TermsOfUse from "./pages/legal/TermsOfUse";
import Policy from "./pages/legal/Policy";
import ConfirmEmail from "./components/users/registrationForm/ConfirmEmail";
import About from "./pages/About";
import useAuth from "./hooks/useAuth";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./redux/store";
import { useEffect } from "react";
import TopReviews from "./components/topReviews/TopReviews";
import Contact from "./pages/Contact";
import LoginPopup from "./components/users/LoginPopup";
import PrivateRoute from "./components/PrivateRoute";

export const url = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000";

//export const url = "http://localhost:8000";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
});

const App = () => {
  const dispatch = useDispatch<AppDispatch>();

  useAuth();

  useEffect(() => {}, [dispatch]);
  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <Box className="App">
          <NavBar />

          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/users/confirm-email" element={<ConfirmEmail />} />

            {/* Protected Routes */}
            <Route element={<PrivateRoute />}>
              <Route path="/business" element={<Business />} />
              <Route path="/comment/:id" element={<TopReviews />} />
              <Route path="/user" element={<UserInformation />} />
              <Route path="/user-mng" element={<UserMng />} />
              <Route path="/business-mng" element={<BusinessMng />} />
              <Route path="/cmt-mng" element={<CommentMng />} />
              <Route path="/favorites/:userId" element={<Favorite />} />
              <Route path="/business/:id" element={<DetailedBusiness />} />
            </Route>

            <Route path="/google-logIn" element={<GoogleLogIn />} />
          </Routes>
          <Footer />
          <LoginPopup />
        </Box>
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default App;
