

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserType } from "../../types/userTypes";

// Type for Users
type InitialType = {
  usersData: UserType[];
  error: string;
};

// Initial Value
const initialState: InitialType = {
  usersData: [],
  error: "",
};

// Slice
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    getUsersList: (state, action: PayloadAction<UserType[]>) => {
      state.usersData = action.payload;
    },
    // toggleBanStatus: (state, action: PayloadAction<string>) => {
    //   const userId = action.payload;
    //   const userToToggle = state.usersData.find((user) => user._id === userId);
    //   if (userToToggle) {
    //     userToToggle.isBanned = !userToToggle.isBanned;
    //   }
    // },
    // toggleAdminStatus: (state, action: PayloadAction<string>) => {
    //   const userId = action.payload;
    //   const userToToggle = state.usersData.find((user) => user._id === userId);
    //   if (userToToggle) {
    //     userToToggle.isAdmin = !userToToggle.isAdmin;
    //   }
    // },
    toggleBanStatus: (state, action: PayloadAction<string>) => {
      const userId = action.payload;
      const userToToggle = state.usersData.find((user) => user._id === userId);
      if (userToToggle) {
        console.log("Before toggling ban status:", userToToggle);
        userToToggle.isBanned = !userToToggle.isBanned;
        console.log("After toggling ban status:", userToToggle);
      } else {
        console.log("User not found with ID:", userId);
      }
    },
    toggleAdminStatus: (state, action: PayloadAction<string>) => {
      const userId = action.payload;
      const userToToggle = state.usersData.find((user) => user._id === userId);
      if (userToToggle) {
        console.log("Before toggling admin status:", userToToggle);
        userToToggle.isAdmin = !userToToggle.isAdmin;
        console.log("After toggling admin status:", userToToggle);
      } else {
        console.log("User not found with ID:", userId);
      }
    },
    
    deleteUser: (state, action: PayloadAction<string>) => {
      const userIdToDelete = action.payload;
      console.log('Deleting user:', userIdToDelete); // Log the user ID being deleted
      state.usersData = state.usersData.filter((user) => user._id !== userIdToDelete);
      console.log('Updated users data:', state.usersData); // Log the updated users data after deletion
    },
   
  
    errorHandler: (state, action: PayloadAction<string>) => {
      console.error('Error occurred:', action.payload);
      state.error = action.payload;
    },
  },
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;
