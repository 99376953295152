import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "../../../App";
import { Box, Typography } from "@mui/material";
import { emailConfirmAnimation } from "../../../constants/styleConstants";

const ConfirmEmail = () => {
  const [message, setMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    if (token) {
      console.log("Sending confirmation request with token:", token);
      axios
        .put(`${url}/users/confirm-email?token=${token}`)
        .then((response) => {
          console.log("Confirmation response received:", response.data);
          setMessage(response.data.message);
          // Redirect to the login page after a short delay
          setTimeout(() => {
            navigate("/login"); // Redirect to login page
          }, 3000); // 3-second delay
        })
        .catch((error) => {
          console.error("Error confirming email:", error);
          setMessage(error.response?.data?.message || "Error confirming email");
        });
    } else {
      console.error("Token is invalid or missing");
      setMessage("Invalid or missing token");
    }
  }, [location, navigate]);

  return (
    <Box sx={{ ...emailConfirmAnimation, height: "100vh" }}>
      {" "}
      {/* Apply the animation style */}
      <Typography variant="h3" sx={{ paddingTop: "100px" }}>
        Email Confirmation
      </Typography>
      <p className="confirmation-message">{message}</p>{" "}
      {/* Add the class for animation */}
    </Box>
  );
};

export default ConfirmEmail;
