import { Box, Grid, Typography } from "@mui/material";
import {
  ASSISTANCE_TITLE,
  ASSISTANCE_TITLE_SE,
  ASSISTANCE_SUBTITLE,
  ASSISTANCE_SUBTITLE_SE,
  ASSISTANCE_TEXT1,
  ASSISTANCE_TEXT1_SE,
  ASSISTANCE_TEXT2,
  ASSISTANCE_TEXT2_SE,
  ASSISTANCE_TEXT3,
  ASSISTANCE_TEXT4_SE,
  ASSISTANCE_TEXT4,
  ASSISTANCE_TEXT3_SE,
} from "../../constants/textConstants";
import {
  answerStyle,
  contentAssistance,
  discoverTitle,
  introTextStyle,
  questionStyle,
} from "../../constants/styleConstants";
import { useLanguage } from "../languages/LanguageContext";

function Assistance() {
  const { currentLanguage } = useLanguage();

  return (
    <Box>
      <Box>
        <Typography sx={discoverTitle}>
          {currentLanguage === "se" ? ASSISTANCE_TITLE_SE : ASSISTANCE_TITLE}
        </Typography>
        <Typography textAlign={"left"} sx={introTextStyle}>
          {currentLanguage === "se"
            ? ASSISTANCE_SUBTITLE_SE
            : ASSISTANCE_SUBTITLE}
        </Typography>
      </Box>
      <Box style={contentAssistance}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography style={questionStyle}>
                {currentLanguage === "se"
                  ? "Hur recenserar jag en produkt?"
                  : "How do I review a product?"}
              </Typography>
              <Typography style={answerStyle}>
                {currentLanguage === "se"
                  ? ASSISTANCE_TEXT1_SE
                  : ASSISTANCE_TEXT1}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography style={questionStyle}>
                {currentLanguage === "se"
                  ? "Kan jag redigera min recension?"
                  : "Can I edit my review?"}
              </Typography>
              <Typography style={answerStyle}>
                {currentLanguage === "se"
                  ? ASSISTANCE_TEXT2_SE
                  : ASSISTANCE_TEXT2}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography style={questionStyle}>
                {currentLanguage === "se"
                  ? "Hur hittar jag recensioner för en specifik produkt?"
                  : "How do I find reviews for a specific product?"}
              </Typography>
              <Typography style={answerStyle}>
                {currentLanguage === "se"
                  ? ASSISTANCE_TEXT3_SE
                  : ASSISTANCE_TEXT3}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography style={questionStyle}>
                {currentLanguage === "se"
                  ? "Hur beräknas betygen?"
                  : "How are the ratings calculated?"}
              </Typography>
              <Typography style={answerStyle}>
                {currentLanguage === "se"
                  ? ASSISTANCE_TEXT4_SE
                  : ASSISTANCE_TEXT4}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
export default Assistance;
