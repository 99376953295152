import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the type for the language context
type LanguageContextType = {
  currentLanguage: "en" | "se";
  toggleLanguage: () => void;
};

// Create the context with an undefined initial value
export const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

// Custom hook to use the LanguageContext
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};

// LanguageProvider component to provide the context to its children
export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentLanguage, setCurrentLanguage] = useState<"en" | "se">("se");

  const toggleLanguage = () => {
    setCurrentLanguage((prevLanguage) => (prevLanguage === "en" ? "se" : "en"));
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
