import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { userUpdate } from "./../../../redux/thunk/userUpdate";
import { deleteUser } from "./../../../redux/thunk/userList";

import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  DialogContentText,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./userProfile.css";
import { userActions } from "./../../../redux/slice/user";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { userProfileAvatar } from "../../../constants/styleConstants";
import { fetchUser } from "../../../redux/thunk/user";

export type InitialUpdateType = {
  firstName: string;
  lastName: string;
  imageUrl?: string | null;
};

const UserProfile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const user = useSelector((state: RootState) => state.user.user);
  const userId = user._id;
  const dispatch = useDispatch<AppDispatch>();

  const initialValues: InitialUpdateType = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    imageUrl: user?.image || null,
  };

  const SinginSchema = Yup.object().shape({
    firstName: Yup.string().required("Please Enter your first name"),
    lastName: Yup.string().required("Please Enter your last name"),
  });

  useEffect(() => {
    if (userId) {
      dispatch(fetchUser(userId));
    }
  }, [dispatch, userId]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleDeleteClick = () => {
    setUserIdToDelete(userId);
    setOpenDeleteConfirm(true); // Open the confirmation dialog
  };

  const handleCloseDialog = () => {
    setOpenDeleteConfirm(false); // Close the dialog without deleting
  };

  const deleteUserAccount = async () => {
    try {
      dispatch(deleteUser(userIdToDelete));
      setOpenDeleteConfirm(false);
      dispatch(userActions.loginHandler(false));
      navigate("/login");
    } catch (error) {
      console.error("Error deleting user account:", error);
    }
  };

  const submitHandler = (values: InitialUpdateType) => {
    try {
      const updateData = new FormData();
      updateData.append("firstName", values.firstName);
      updateData.append("lastName", values.lastName);
      if (selectedFile) {
        updateData.append("image", selectedFile);
      }

      setIsEdit(false);
      dispatch(userUpdate(user._id, updateData));
    } catch (error) {
      // Handle any errors that occur during the update process
      console.error("Error updating profile:", error);
      // Provide feedback to the user that there was an error updating the profile
    }
  };

  const CheckOutBTN = styled(Button)({
    color: "#fff",
    backgroundColor: "black",
    border: "none",
    "&:hover": {
      backgroundColor: "red",
      border: "none",
    },
  });
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.clear();
    dispatch(userActions.loginHandler(false));
    navigate("/login");
  };

  return (
    <Box sx={{ paddingInline: "20px", position: "relative" }}>
      <Box sx={{ marginBlock: "20px" }}>
        <Typography variant="h5">Welcome {user.firstName}!</Typography>
      </Box>

      <Box
        className="user-profile-container"
        sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Formik
            initialValues={initialValues}
            onSubmit={submitHandler}
            validationSchema={SinginSchema}
          >
            {({ errors, touched, handleChange }) => {
              return (
                <Form className="user-profile-form">
                  <Box>
                    {isEdit ? (
                      <Box
                        sx={{
                          margin: "auto",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                      >
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          id="avatar-upload"
                        />
                        <Box sx={userProfileAvatar}>
                          <label htmlFor="avatar-upload">Choose Avatar</label>
                        </Box>
                      </Box>
                    ) : (
                      <Avatar
                        sx={{ width: "100px", height: "100px", margin: "auto" }}
                        aria-label="recipe"
                      >
                        <img
                          src={user?.image}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "top",
                          }}
                          alt={user?.firstName}
                           loading = "lazy"
                        />
                      </Avatar>
                    )}
                    {isEdit ? (
                      <Box
                        sx={{
                          margin: "auto",
                          width: "100%",
                        }}
                      >
                        <TextField
                          required
                          name="firstName"
                          label="First name"
                          defaultValue={user?.firstName}
                          onChange={handleChange}
                        />
                        {errors.firstName && touched.firstName ? (
                          <p className="user-profile-error">
                            {errors.firstName}
                          </p>
                        ) : null}
                      </Box>
                    ) : (
                      <Typography
                        variant="h6"
                        textAlign={"start"}
                        sx={{ marginBlock: "15px" }}
                      >
                        <strong>
                          {user?.firstName} {user?.lastName}
                        </strong>
                      </Typography>
                    )}

                    <Box>
                      {isEdit ? (
                        <Box
                          sx={{
                            margin: "auto",
                            width: "100%",
                            marginBottom: "20px",
                          }}
                        >
                          <TextField
                            required
                            name="lastName"
                            label="Last name"
                            defaultValue={user?.lastName}
                            onChange={handleChange}
                            style={{ marginTop: "10px" }}
                          />
                          {errors.lastName && touched.lastName ? (
                            <Typography className="user-profile-error">
                              {errors.lastName}
                            </Typography>
                          ) : null}
                        </Box>
                      ) : (
                        <Typography
                          variant="h6"
                          textAlign={"start"}
                          sx={{ marginBottom: "15px" }}
                        ></Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        {" "}
                        <strong>{user?.email}</strong>
                      </Typography>
                    </Box>
                  </Box>
                  {isEdit ? (
                    <Box className="user-profile-btn">
                      <Button
                        variant="outlined"
                        onClick={() => setIsEdit(false)}
                      >
                        cancel
                      </Button>
                      <Button variant="contained" type="submit">
                        Save
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => setIsEdit(true)}
                      type="button"
                      sx={{ marginTop: "20px" }}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  )}

                  <CheckOutBTN
                    variant="outlined"
                    onClick={logOut}
                    type="button"
                    sx={{ marginTop: "20px" }}
                  >
                    log out
                  </CheckOutBTN>
                  {/*               .---------- DELETE BUTTON */}
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteClick}
                    sx={{
                      mt: "50px",
                    }}
                  >
                    Delete Account
                  </Button>
                  <Dialog open={openDeleteConfirm} onClose={handleCloseDialog}>
                    <DialogTitle>Delete Your Account?</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure you want to delete your account? This
                        action cannot be undone.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={deleteUserAccount} color="error">
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Form>
              );
            }}
          </Formik>
        </Box>
        <Box>
          {user.isAdmin ? (
            <Box className="user-profile-management">
              <Button variant="outlined" onClick={() => navigate("/user-mng")}>
                User management
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/business-mng")}
              >
                Business management
              </Button>
              <Button variant="outlined" onClick={() => navigate("/cmt-mng")}>
                CMT management
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
export default UserProfile;
