import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FavoriteList from "../components/favorite/favoriteList/FavoriteList";
import { useEffect } from "react";
import { RootState, AppDispatch } from "../redux/store";
import { fetchFavoriteBusinesses } from "../redux/thunk/user";

const Favorite = () => {
  const { userId } = useParams<{ userId: string }>();
  const isLogin = useSelector((state: RootState) => state.user.isLogin);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (userId && isLogin) {
      dispatch(fetchFavoriteBusinesses(userId));
    }
  }, [dispatch, isLogin, userId]);

  return (
    <Box sx={{ minHeight: "50vh" }}>
      <FavoriteList />
    </Box>
  );
};
export default Favorite;
