import BusinessInformation from "../components/admin/businessInformation/BusinessInformation";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Box } from "@mui/material";
import AddBusiness from "../components/admin/addBusiness/AddBusiness";

const BusinessMng = () => {
  const token = localStorage.getItem("token");
  const user = useSelector((state: RootState) => state.user.user);
  const isLogin = useSelector((state: RootState) => state.user.isLogin);
  return (
    <Box className="business-mng-page-container">
      {token && isLogin && user.isAdmin === true ? (
        <Box className="business-mng-page">
          <AddBusiness />
          <BusinessInformation />
        </Box>
      ) : (
        <h2>You don't have access to this page!</h2>
      )}
    </Box>
  );
};
export default BusinessMng;
