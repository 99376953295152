import * as React from "react";

import { Backdrop, Box, Modal, Fade } from "@mui/material";

import UpdateSubComment from "./UpdateSubComment";
import { SubCommentType } from "../../../types/subCommentTypes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type PropType = {
  open: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  subCommentToModal: SubCommentType | undefined;
  userId: string; // Add userId prop
};

export default function UpdateSubCommentModal({
  open,
  setOpenModal,
  subCommentToModal,
  userId,
}: PropType) {
  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <UpdateSubComment
              subCommentToUpdate={subCommentToModal}
              setOpenModal={setOpenModal}
              userId={userId}
            />
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}
