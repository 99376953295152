import { Box, Typography, Divider } from "@mui/material";
import { useLanguage } from "../../components/languages/LanguageContext";
import {
  PRIVACY_POLICY_LAST_UPDATED,
  PRIVACY_POLICY_INTRODUCTION,
  PRIVACY_POLICY_INFORMATION_WE_COLLECT,
  PRIVACY_POLICY_PERSONAL_DATA,
  PRIVACY_POLICY_HOW_WE_USE_YOUR_INFORMATION,
  PRIVACY_POLICY_PROVIDE_AND_MAINTAIN_SERVICE,
  PRIVACY_POLICY_COMMUNICATE_WITH_YOU,
  PRIVACY_POLICY_COOKIES,
  PRIVACY_POLICY_LEGAL_BASIS_FOR_PROCESSING,
  PRIVACY_POLICY_CONSENT,
  PRIVACY_POLICY_DATA_STORAGE_AND_SECURITY,
  PRIVACY_POLICY_DATA_RETENTION,
  PRIVACY_POLICY_YOUR_RIGHTS,
  PRIVACY_POLICY_ACCESS,
  PRIVACY_POLICY_RECTIFICATION,
  PRIVACY_POLICY_ERASURE,
  PRIVACY_POLICY_RESTRICTION,
  PRIVACY_POLICY_DATA_PORTABILITY,
  PRIVACY_POLICY_OBJECTION,
  PRIVACY_POLICY_EXERCISE_RIGHTS,
  PRIVACY_POLICY_THIRD_PARTY_SERVICES,
  PRIVACY_POLICY_CHANGES_TO_POLICY,
  PRIVACY_POLICY_CONTACT_US,
  PRIVACY_POLICY_LAST_UPDATED_SV,
  PRIVACY_POLICY_INTRODUCTION_SV,
  PRIVACY_POLICY_INFORMATION_WE_COLLECT_SV,
  PRIVACY_POLICY_PERSONAL_DATA_SV,
  PRIVACY_POLICY_HOW_WE_USE_YOUR_INFORMATION_SV,
  PRIVACY_POLICY_PROVIDE_AND_MAINTAIN_SERVICE_SV,
  PRIVACY_POLICY_COMMUNICATE_WITH_YOU_SV,
  PRIVACY_POLICY_COOKIES_SV,
  PRIVACY_POLICY_LEGAL_BASIS_FOR_PROCESSING_SV,
  PRIVACY_POLICY_CONSENT_SV,
  PRIVACY_POLICY_DATA_STORAGE_AND_SECURITY_SV,
  PRIVACY_POLICY_DATA_RETENTION_SV,
  PRIVACY_POLICY_YOUR_RIGHTS_SV,
  PRIVACY_POLICY_ACCESS_SV,
  PRIVACY_POLICY_RECTIFICATION_SV,
  PRIVACY_POLICY_ERASURE_SV,
  PRIVACY_POLICY_RESTRICTION_SV,
  PRIVACY_POLICY_DATA_PORTABILITY_SV,
  PRIVACY_POLICY_OBJECTION_SV,
  PRIVACY_POLICY_EXERCISE_RIGHTS_SV,
  PRIVACY_POLICY_THIRD_PARTY_SERVICES_SV,
  PRIVACY_POLICY_CHANGES_TO_POLICY_SV,
  PRIVACY_POLICY_CONTACT_US_SV,
} from "../../constants/textConstants";

function Policy() {
  const { currentLanguage } = useLanguage();

  const terms =
    currentLanguage === "se"
      ? {
          lastUpdated: PRIVACY_POLICY_LAST_UPDATED_SV,
          introduction: PRIVACY_POLICY_INTRODUCTION_SV,
          informationWeCollect: PRIVACY_POLICY_INFORMATION_WE_COLLECT_SV,
          personalData: PRIVACY_POLICY_PERSONAL_DATA_SV,
          howWeUseYourInformation:
            PRIVACY_POLICY_HOW_WE_USE_YOUR_INFORMATION_SV,
          provideAndMaintainService:
            PRIVACY_POLICY_PROVIDE_AND_MAINTAIN_SERVICE_SV,
          communicateWithYou: PRIVACY_POLICY_COMMUNICATE_WITH_YOU_SV,
          cookies: PRIVACY_POLICY_COOKIES_SV,
          legalBasisForProcessing: PRIVACY_POLICY_LEGAL_BASIS_FOR_PROCESSING_SV,
          consent: PRIVACY_POLICY_CONSENT_SV,
          dataStorageAndSecurity: PRIVACY_POLICY_DATA_STORAGE_AND_SECURITY_SV,
          dataRetention: PRIVACY_POLICY_DATA_RETENTION_SV,
          yourRights: PRIVACY_POLICY_YOUR_RIGHTS_SV,
          access: PRIVACY_POLICY_ACCESS_SV,
          rectification: PRIVACY_POLICY_RECTIFICATION_SV,
          erasure: PRIVACY_POLICY_ERASURE_SV,
          restriction: PRIVACY_POLICY_RESTRICTION_SV,
          dataPortability: PRIVACY_POLICY_DATA_PORTABILITY_SV,
          objection: PRIVACY_POLICY_OBJECTION_SV,
          exerciseRights: PRIVACY_POLICY_EXERCISE_RIGHTS_SV,
          thirdPartyServices: PRIVACY_POLICY_THIRD_PARTY_SERVICES_SV,
          changesToPolicy: PRIVACY_POLICY_CHANGES_TO_POLICY_SV,
          contactUs: PRIVACY_POLICY_CONTACT_US_SV,
        }
      : {
          lastUpdated: PRIVACY_POLICY_LAST_UPDATED,
          introduction: PRIVACY_POLICY_INTRODUCTION,
          informationWeCollect: PRIVACY_POLICY_INFORMATION_WE_COLLECT,
          personalData: PRIVACY_POLICY_PERSONAL_DATA,
          howWeUseYourInformation: PRIVACY_POLICY_HOW_WE_USE_YOUR_INFORMATION,
          provideAndMaintainService:
            PRIVACY_POLICY_PROVIDE_AND_MAINTAIN_SERVICE,
          communicateWithYou: PRIVACY_POLICY_COMMUNICATE_WITH_YOU,
          cookies: PRIVACY_POLICY_COOKIES,
          legalBasisForProcessing: PRIVACY_POLICY_LEGAL_BASIS_FOR_PROCESSING,
          consent: PRIVACY_POLICY_CONSENT,
          dataStorageAndSecurity: PRIVACY_POLICY_DATA_STORAGE_AND_SECURITY,
          dataRetention: PRIVACY_POLICY_DATA_RETENTION,
          yourRights: PRIVACY_POLICY_YOUR_RIGHTS,
          access: PRIVACY_POLICY_ACCESS,
          rectification: PRIVACY_POLICY_RECTIFICATION,
          erasure: PRIVACY_POLICY_ERASURE,
          restriction: PRIVACY_POLICY_RESTRICTION,
          dataPortability: PRIVACY_POLICY_DATA_PORTABILITY,
          objection: PRIVACY_POLICY_OBJECTION,
          exerciseRights: PRIVACY_POLICY_EXERCISE_RIGHTS,
          thirdPartyServices: PRIVACY_POLICY_THIRD_PARTY_SERVICES,
          changesToPolicy: PRIVACY_POLICY_CHANGES_TO_POLICY,
          contactUs: PRIVACY_POLICY_CONTACT_US,
        };

  return (
    <Box sx={{ width: "90%", margin: "auto" }}>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBlock: "50px" }}
        gutterBottom
      >
        {currentLanguage === "se" ? "Integritetspolicy" : "Privacy Policy"}
      </Typography>
      <Typography variant="subtitle1" align="left" gutterBottom>
        {terms.lastUpdated}
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      <Typography variant="h6" align="left" gutterBottom>
        {currentLanguage === "se" ? "1. Introduktion" : "1. Introduction"}
      </Typography>
      <Typography paragraph align="left">
        {terms.introduction}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" align="left" gutterBottom>
        {currentLanguage === "se"
          ? "2. Information vi samlar in"
          : "2. Information We Collect"}
      </Typography>
      <Typography paragraph align="left">
        {terms.informationWeCollect}
      </Typography>
      <Typography paragraph align="left">
        {terms.personalData}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" align="left" gutterBottom>
        {currentLanguage === "se"
          ? "3. Hur vi använder din information"
          : "3. How We Use Your Information"}
      </Typography>
      <Typography paragraph align="left">
        {terms.howWeUseYourInformation}
      </Typography>
      <Typography paragraph align="left">
        {terms.provideAndMaintainService}
      </Typography>
      <Typography paragraph align="left">
        {terms.communicateWithYou}
      </Typography>
      <Typography id="cookies" paragraph align="left">
        {terms.cookies}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" align="left" gutterBottom>
        {currentLanguage === "se"
          ? "4. Rättslig grund för behandling"
          : "4. Legal Basis for Processing"}
      </Typography>
      <Typography paragraph align="left">
        {terms.legalBasisForProcessing}
      </Typography>
      <Typography paragraph align="left">
        {terms.consent}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" align="left" gutterBottom>
        {currentLanguage === "se"
          ? "5. Dataintegritet och säkerhet"
          : "5. Data Storage and Security"}
      </Typography>
      <Typography paragraph align="left">
        {terms.dataStorageAndSecurity}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" align="left" gutterBottom>
        {currentLanguage === "se" ? "6. Datalagring" : "6. Data Retention"}
      </Typography>
      <Typography paragraph align="left">
        {terms.dataRetention}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" align="left" gutterBottom>
        {currentLanguage === "se" ? "7. Dina rättigheter" : "7. Your Rights"}
      </Typography>
      <Typography paragraph align="left">
        {terms.yourRights}
      </Typography>
      <Typography paragraph align="left">
        {terms.access}
      </Typography>
      <Typography paragraph align="left">
        {terms.rectification}
      </Typography>
      <Typography paragraph align="left">
        {terms.erasure}
      </Typography>
      <Typography paragraph align="left">
        {terms.restriction}
      </Typography>
      <Typography paragraph align="left">
        {terms.dataPortability}
      </Typography>
      <Typography paragraph align="left">
        {terms.objection}
      </Typography>
      <Typography paragraph align="left">
        {terms.exerciseRights}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" align="left" gutterBottom>
        {currentLanguage === "se"
          ? "8. Tredjepartstjänster"
          : "8. Third-Party Services"}
      </Typography>
      <Typography paragraph align="left">
        {terms.thirdPartyServices}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" align="left" gutterBottom>
        {currentLanguage === "se"
          ? "9. Ändringar av denna integritetspolicy"
          : "9. Changes to This Privacy Policy"}
      </Typography>
      <Typography paragraph align="left">
        {terms.changesToPolicy}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" align="left" gutterBottom>
        {currentLanguage === "se" ? "10. Kontakta oss" : "10. Contact Us"}
      </Typography>
      <Typography paragraph align="left">
        {terms.contactUs}
      </Typography>
    </Box>
  );
}

export default Policy;
