import React, { useState } from "react";
import FormSchema from "../loginSchema/formSchema";
import Snackbar from "@mui/material/Snackbar";
import { Alert, Box, Button, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../../../App";
import "./loginForm.css";
import { userActions } from "../../../redux/slice/user";

export type InitialTypes = {
  email: string;
  password?: string;
};

const LoginForm = () => {
  const [open, setOpen] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: RootState) => state.user.error);
  const navigate = useNavigate();

  const showPassHandler = () => {
    setShowPass(!showPass);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const initialValues: InitialTypes = {
    email: "",
    password: "",
  };

  const submitHandler = async (values: InitialTypes) => {
    try {
      const res = await axios.post(`${url}/users/logIn`, values, {
        withCredentials: true, // ensure cookies are included in the request
      });
      const data = res.data;
      console.log(data, "data from loginform");
      if (data.message === "invalid") {
        dispatch(userActions.errorHandler("This email is not registered!"));
        handleClick();
        return;
      } else if (data.message === "wrong password!") {
        dispatch(userActions.errorHandler("Email or password is wrong!"));
        handleClick();
        return;
      } else {
        dispatch(userActions.getUser(data.userData));
        console.log(data.userData, "data.userData from loginform");
        dispatch(userActions.loginHandler(true));

        // Set token in localStorage and cookies
        const token = data.token;
        localStorage.setItem("token", token);
        document.cookie = `token=${token}; path=/; secure; HttpOnly; SameSite=Strict`;

        navigate("/user");
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        dispatch(
          userActions.errorHandler(
            "Please confirm your email before logging in"
          )
        );
        handleClick();
      } else {
        console.error(error);
        dispatch(
          userActions.errorHandler("An error occurred. Please try again later.")
        );
        handleClick();
      }
    }
  };

  return (
    <>
      <Box>
        <h2>Login Your Account</h2>
        <span>We're missing your contribution.</span>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={submitHandler}
      >
        {({ errors, touched, handleChange }) => {
          return (
            <Form className="form-container">
              <Box className="form-field">
                <TextField
                  className="textBox"
                  label="Email"
                  name="email"
                  onChange={handleChange}
                />
                {errors.email && touched.email ? (
                  <p className="error-message">{errors.email}</p>
                ) : null}
              </Box>

              <Box className="form-field">
                <TextField
                  className="textBox"
                  label="Password"
                  name="password"
                  type={showPass ? "text" : "password"}
                  onChange={handleChange}
                />
                <span className="visibility">
                  {showPass ? (
                    <Button onClick={showPassHandler}>
                      <VisibilityOff />
                    </Button>
                  ) : (
                    <Button onClick={showPassHandler}>
                      <Visibility />
                    </Button>
                  )}
                </span>
                {errors.password && touched.password ? (
                  <p className="error-message">{errors.password}</p>
                ) : null}
              </Box>

              <Box>
                <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                  Login
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoginForm;
