import React, { useState } from "react";

import { updateCommentSchema } from "./updateCommentSchema";

import { useDispatch } from "react-redux";
import { commentActions } from "../../../redux/slice/comment";
import { AppDispatch } from "../../../redux/store";

import { Alert, Box, Button, Snackbar, TextField } from "@mui/material";

import { Form, Formik } from "formik";

import axios from "axios";
import imageCompression from "browser-image-compression";

import { CommentType } from "../../../types/commentTypes";

import { url } from "../../../App";

type InitialValues = {
  text: string;
  imageUrl?: string | null;
};

type PropType = {
  commentToUpdate: CommentType | undefined; // Updated type
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  userId: string;
};

export default function UpdateComment({
  commentToUpdate,
  setOpenModal,
  userId,
}: PropType) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageSizeError, setImageSizeError] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const initialValues: InitialValues = {
    text: commentToUpdate?.text || "",
    imageUrl: commentToUpdate?.image || null,
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Options for image compression
      const options = {
        maxSizeMB: 2, // Max file size in MB (100 KB)
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        initialQuality: 0.8, // Start with the highest quality setting
        alwaysKeepResolution: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        setImageSizeError(false); // Reset imageSizeError
        setSelectedFile(compressedFile); // Set the compressed file as selectedFile
      } catch (error) {
        console.error("Image compression failed:", error);
        setImageSizeError(true); // Set imageSizeError if compression fails
        setSelectedFile(null); // Clear selectedFile
      }
    }
  };

  const token = localStorage.getItem("token");

  const handleCloseErrorAlert = () => {
    setImageSizeError(false); // Reset imageSizeError when closing the alert
  };

  const submitHandler = async (values: InitialValues) => {
    // Ensure that userId is available
    if (!userId) {
      console.error("User ID not provided");
      return;
    }

    const updateData = new FormData();

    // Append all other form fields to FormData
    updateData.append("text", values.text);

    // Append image if selected
    if (selectedFile) {
      updateData.append("image", selectedFile); // Use the correct field name as expected by the backend
    }

    // Append userId to the FormData
    updateData.append("userId", userId);

    try {
      const response = await axios.put(
        `${url}/comment/update/${commentToUpdate?._id}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // This is necessary for file upload
          },
        }
      );

      if (response.status === 200) {
        dispatch(commentActions.updateComment(response.data));
        setOpenModal(false);
        setSelectedFile(null); // Clear the selected file after successful upload
      } else {
        // Handle non-200 responses here
        console.error(
          "Server responded with non-200 status:",
          response.status,
          response.data
        );
      }
    } catch (error) {
      console.error("Error during submission:", error);
      if (axios.isAxiosError(error) && error.response) {
        // You can access the response data from here if needed
        console.error("Server responded with error:", error.response.data);
      }
    }
  };

  return (
    <Box className="update-business-form-container">
      <h2>Update Comment</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={updateCommentSchema}
        onSubmit={submitHandler}
      >
        {({ errors, touched, handleChange }) => {
          return (
            <Form className="update-comment-form">
              <Box>
                <TextField
                  className="update-comment-text"
                  label="Text"
                  name="text"
                  onChange={handleChange}
                  defaultValue={commentToUpdate?.text}
                />
                {errors.text && touched.text ? (
                  <Box className="error-message">{errors.text}</Box>
                ) : null}
              </Box>

              <Box>
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Box>

              <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                Update Comment
              </Button>
            </Form>
          );
        }}
      </Formik>
      {imageSizeError && (
        <Snackbar
          open={imageSizeError}
          autoHideDuration={6000}
          onClose={handleCloseErrorAlert}
        >
          <Alert onClose={handleCloseErrorAlert} severity="error">
            The selected image exceeds the size limit (10 KB). Please choose a
            smaller image.
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}
