// import "./registrationForm.css";
// import RegistrationSchema from "../registrationSchema/registrationSchema";

// import { Alert, Box, Button, TextField } from "@mui/material";
// import Snackbar from "@mui/material/Snackbar";

// import { Form, Formik } from "formik";
// import { useState, useRef } from "react";
// import SuccessModal from "./SuccessModal";
// import axios from "axios";
// import { RiImageAddLine } from "react-icons/ri";

// import { url } from "../../../App";
// import GoogleLogIn from "../googleLogIn/GoogleLogIn";

// // Type Declaration
// type InitialValues = {
//   firstName: string;
//   lastName: string;
//   email: string;
//   password?: string;
//   imageUrl?: string | null;
//   confirmPassword: string;
// };

// const RegistrationForm = () => {
//   const [open, setOpen] = useState(false);
//   const [regClicked, setRegClicked] = useState(false);
//   const [openModal, setOpenModal] = useState(false);
//   const [userName, setUserName] = useState("");
//   const [selectedFile, setSelectedFile] = useState<File | null>(null);
//   const inputRef = useRef<HTMLInputElement>(null);

//   const handleClick = () => {
//     setOpen(true);
//     setRegClicked(false);
//   };

//   const handleClose = (
//     event?: React.SyntheticEvent | Event,
//     reason?: string
//   ) => {
//     if (reason === "clickaway") {
//       return;
//     }

//     setOpen(false);
//   };
//   // Initial Values
//   const initialValues: InitialValues = {
//     firstName: "",
//     lastName: "",
//     email: "",
//     password: "",
//     imageUrl: null,
//     confirmPassword: "",
//   };

//   const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.files && e.target.files[0]) {
//       setSelectedFile(e.target.files[0]);
//     }
//   };

//   const submitHandler = (values: InitialValues) => {
//     setRegClicked(true);
//     const commentData = new FormData();
//     commentData.append("firstName", values.firstName || "");
//     commentData.append("lastName", values.lastName || "");
//     commentData.append("email", values.email || "");
//     commentData.append("password", values.password || "");
//     commentData.append("confirmPassword", values.confirmPassword || "");

//     if (selectedFile) {
//       commentData.append("image", selectedFile);
//     }

//     axios.post(`${url}/users`, commentData).then((res) => {
//       if (res.status === 409) {
//         handleClick();
//       } else if (res.status === 201) {
//         setOpenModal(true);
//         setUserName(values.firstName || "");
//       }
//     });
//   };

//   return (
//     <Box>
//       <Box>
//         <h1>Create a new account</h1>
//         <span>It's free and always will be.</span>
//       </Box>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={RegistrationSchema}
//         onSubmit={submitHandler}
//       >
//         {({ errors, touched, handleChange }) => {
//           return (
//             <Form>
//               <RiImageAddLine
//                 size={"1.5em"}
//                 style={{ cursor: "pointer" }}
//                 onClick={() => inputRef.current?.click()}
//               />
//               <input
//                 ref={inputRef}
//                 type="file"
//                 name="image"
//                 accept="image/*"
//                 onChange={handleImageChange}
//                 style={{ display: "none" }}
//               />

//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",

//                   alignItems: "center",
//                   gap: "20px",
//                   marginTop: "20px",
//                 }}
//               >
//                 <TextField
//                   label="First Name"
//                   name="firstNameRegister"
//                   onChange={handleChange}
//                 />
//                 {errors.firstName && touched.firstName ? (
//                   <Box className="error-message">{errors.firstName}</Box>
//                 ) : null}

//                 <TextField
//                   label="Last Name"
//                   name="lastNameRegister"
//                   onChange={handleChange}
//                 />
//                 {errors.lastName && touched.lastName ? (
//                   <Box className="error-message">{errors.lastName}</Box>
//                 ) : null}

//                 <TextField label="Email" name="emailRegister" onChange={handleChange} />
//                 {errors.email && touched.email ? (
//                   <Box className="error-message">{errors.email}</Box>
//                 ) : null}

//                 <TextField
//                   label="Password"
//                   name="passwordRegister"
//                   type="password"
//                   onChange={handleChange}
//                 />
//                 {errors.password && touched.password ? (
//                   <Box className="error-message">{errors.password}</Box>
//                 ) : null}

//                 <TextField
//                   label="Confirm Password"
//                   name="confirmPassword"
//                   type="password"
//                   onChange={handleChange}
//                 />
//                 {errors.confirmPassword && touched.confirmPassword ? (
//                   <Box className="error-message">{errors.confirmPassword}</Box>
//                 ) : null}
//               </Box>

//               <Box>
//                 <Button
//                   variant="contained"
//                   type="submit"
//                   sx={{ mt: 5, width: "100px" }}
//                 >
//                   {regClicked && !openModal ? (
//                     <span>
//                       <i className="fas fa-spinner fa-spin fa-xl" />
//                     </span>
//                   ) : (
//                     "register"
//                   )}
//                 </Button>
//               </Box>
//             </Form>
//           );
//         }}
//       </Formik>
//       <GoogleLogIn />
//       <SuccessModal open={openModal} setOpen={setOpenModal} name={userName} />
//       <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
//           The email is already registerd!!
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default RegistrationForm;

import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import axios from "axios";
import { Alert, Box, Button, TextField, Snackbar } from "@mui/material";
import { RiImageAddLine } from "react-icons/ri";
import { url } from "../../../App";
import GoogleLogIn from "../googleLogIn/GoogleLogIn";
import RegistrationSchema from "../registrationSchema/registrationSchema";
import SuccessModal from "./SuccessModal";
import ConfirmationModal from "./ConfirmationModal";
import "./registrationForm.css";

type InitialValues = {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  imageUrl?: string | null;
  confirmPassword: string;
};

const RegistrationForm = () => {
  const [open, setOpen] = useState(false);
  const [regClicked, setRegClicked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    setOpen(true);
    setRegClicked(false);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
  };

  const handleResendConfirmationEmail = () => {
    axios
      .post(`${url}/users/resend-confirmation`, { email: userEmail })
      .then((response) => {
        console.log("Confirmation email resent");
      })
      .catch((error) => {
        console.error("Error resending confirmation email:", error);
      });
  };

  const initialValues: InitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    imageUrl: null,
    confirmPassword: "",
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const submitHandler = (values: InitialValues) => {
    setRegClicked(true);
    const commentData = new FormData();
    commentData.append("firstName", values.firstName || "");
    commentData.append("lastName", values.lastName || "");
    commentData.append("email", values.email || "");
    commentData.append("password", values.password || "");
    commentData.append("confirmPassword", values.confirmPassword || "");

    if (selectedFile) {
      commentData.append("image", selectedFile);
    }

    axios.post(`${url}/users/register`, commentData).then((res) => {
      if (res.status === 409) {
        handleClick();
      } else if (res.status === 201) {
        setOpenModal(true);
        setUserName(values.firstName || "");
        setUserEmail(values.email || "");
      } else if (res.status === 200) {
        setConfirmationModalOpen(true);
        setUserEmail(values.email || "");
      }
    });
  };

  return (
    <Box>
      <Box>
        <h1>Create a new account</h1>
        <span>It's free and always will be.</span>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={RegistrationSchema}
        onSubmit={submitHandler}
      >
        {({ errors, touched, handleChange, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <RiImageAddLine
                size={"1.5em"}
                style={{ cursor: "pointer" }}
                onClick={() => inputRef.current?.click()}
              />
              <input
                ref={inputRef}
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <TextField
                  label="First Name"
                  name="firstName"
                  onChange={handleChange}
                />
                {errors.firstName && touched.firstName ? (
                  <Box className="error-message">{errors.firstName}</Box>
                ) : null}

                <TextField
                  label="Last Name"
                  name="lastName"
                  onChange={handleChange}
                />
                {errors.lastName && touched.lastName ? (
                  <Box className="error-message">{errors.lastName}</Box>
                ) : null}

                <TextField label="Email" name="email" onChange={handleChange} />
                {errors.email && touched.email ? (
                  <Box className="error-message">{errors.email}</Box>
                ) : null}

                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  onChange={handleChange}
                />
                {errors.password && touched.password ? (
                  <Box className="error-message">{errors.password}</Box>
                ) : null}

                <TextField
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  onChange={handleChange}
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <Box className="error-message">{errors.confirmPassword}</Box>
                ) : null}
              </Box>

              <Box>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ mt: 5, width: "100px" }}
                >
                  {regClicked && !openModal ? (
                    <span>
                      <i className="fas fa-spinner fa-spin fa-xl" />
                    </span>
                  ) : (
                    "register"
                  )}
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
      <GoogleLogIn />
      <SuccessModal open={openModal} setOpen={setOpenModal} name={userName} />
      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={handleConfirmationModalClose}
        onResendConfirmation={handleResendConfirmationEmail}
      />
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          The email is already registered!!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RegistrationForm;

// import "./registrationForm.css";
// import RegistrationSchema from "../registrationSchema/registrationSchema";

// import { Alert, Box, Button, TextField } from "@mui/material";
// import Snackbar from "@mui/material/Snackbar";

// import { Form, Formik } from "formik";
// import { useState, useRef } from "react";
// import SuccessModal from "./SuccessModal";
// import ConfirmationModal from "./ConfirmationModal"; // Import the ConfirmationModal
// import axios from "axios";
// import { RiImageAddLine } from "react-icons/ri";

// import { url } from "../../../App";
// import GoogleLogIn from "../googleLogIn/GoogleLogIn";

// // Type Declaration
// type InitialValues = {
//   firstName: string;
//   lastName: string;
//   email: string;
//   password?: string;
//   imageUrl?: string | null;
//   confirmPassword: string;
// };

// const RegistrationForm = () => {
//   const [open, setOpen] = useState(false);
//   const [regClicked, setRegClicked] = useState(false);
//   const [openModal, setOpenModal] = useState(false);
//   const [confirmationModalOpen, setConfirmationModalOpen] = useState(false); // State for confirmation modal
//   const [userName, setUserName] = useState("");
//   const [userEmail, setUserEmail] = useState(""); // State to store user email
//   const [selectedFile, setSelectedFile] = useState<File | null>(null);
//   const inputRef = useRef<HTMLInputElement>(null);

//   const handleClick = () => {
//     setOpen(true);
//     setRegClicked(false);
//   };

//   const handleClose = (
//     event?: React.SyntheticEvent | Event,
//     reason?: string
//   ) => {
//     if (reason === "clickaway") {
//       return;
//     }

//     setOpen(false);
//   };

//   const handleConfirmationModalClose = () => {
//     setConfirmationModalOpen(false);
//   };

//   const handleResendConfirmationEmail = () => {
//     axios
//       .post(`${url}/users/resend-confirmation`, { email: userEmail })
//       .then((response) => {
//         console.log("Confirmation email resent");
//       })
//       .catch((error) => {
//         console.error("Error resending confirmation email:", error);
//       });
//   };

//   // Initial Values
//   const initialValues: InitialValues = {
//     firstName: "",
//     lastName: "",
//     email: "",
//     password: "",
//     imageUrl: null,
//     confirmPassword: "",
//   };

//   const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.files && e.target.files[0]) {
//       setSelectedFile(e.target.files[0]);
//     }
//   };

//   const submitHandler = (values: InitialValues) => {
//     setRegClicked(true);
//     const commentData = new FormData();
//     commentData.append("firstName", values.firstName || "");
//     commentData.append("lastName", values.lastName || "");
//     commentData.append("email", values.email || "");
//     commentData.append("password", values.password || "");
//     commentData.append("confirmPassword", values.confirmPassword || "");

//     if (selectedFile) {
//       commentData.append("image", selectedFile);
//     }

//     axios.post(`${url}/users/register`, commentData).then((res) => {
//       if (res.status === 409) {
//         handleClick();
//       } else if (res.status === 201) {
//         setOpenModal(true);
//         setUserName(values.firstName || "");
//         setUserEmail(values.email || ""); // Set user email
//       } else if (res.status === 200) {
//         // Show confirmation modal if the email was already sent but not confirmed
//         setConfirmationModalOpen(true);
//         setUserEmail(values.email || ""); // Set user email
//       }
//     });
//   };

//   return (
//     <Box>
//       <Box>
//         <h1>Create a new account</h1>
//         <span>It's free and always will be.</span>
//       </Box>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={RegistrationSchema}
//         onSubmit={submitHandler}
//       >
//         {({ errors, touched, handleChange, handleSubmit }) => {
//           return (
//             <Form onSubmit={handleSubmit}>
//               <RiImageAddLine
//                 size={"1.5em"}
//                 style={{ cursor: "pointer" }}
//                 onClick={() => inputRef.current?.click()}
//               />
//               <input
//                 ref={inputRef}
//                 type="file"
//                 name="image"
//                 accept="image/*"
//                 onChange={handleImageChange}
//                 style={{ display: "none" }}
//               />

//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   gap: "20px",
//                   marginTop: "20px",
//                 }}
//               >
//                 <TextField
//                   label="First Name"
//                   name="firstName"
//                   onChange={handleChange}
//                 />
//                 {errors.firstName && touched.firstName ? (
//                   <Box className="error-message">{errors.firstName}</Box>
//                 ) : null}

//                 <TextField
//                   label="Last Name"
//                   name="lastName"
//                   onChange={handleChange}
//                 />
//                 {errors.lastName && touched.lastName ? (
//                   <Box className="error-message">{errors.lastName}</Box>
//                 ) : null}

//                 <TextField label="Email" name="email" onChange={handleChange} />
//                 {errors.email && touched.email ? (
//                   <Box className="error-message">{errors.email}</Box>
//                 ) : null}

//                 <TextField
//                   label="Password"
//                   name="password"
//                   type="password"
//                   onChange={handleChange}
//                 />
//                 {errors.password && touched.password ? (
//                   <Box className="error-message">{errors.password}</Box>
//                 ) : null}

//                 <TextField
//                   label="Confirm Password"
//                   name="confirmPassword"
//                   type="password"
//                   onChange={handleChange}
//                 />
//                 {errors.confirmPassword && touched.confirmPassword ? (
//                   <Box className="error-message">{errors.confirmPassword}</Box>
//                 ) : null}
//               </Box>

//               <Box>
//                 <Button
//                   variant="contained"
//                   type="submit"
//                   sx={{ mt: 5, width: "100px" }}
//                 >
//                   {regClicked && !openModal ? (
//                     <span>
//                       <i className="fas fa-spinner fa-spin fa-xl" />
//                     </span>
//                   ) : (
//                     "register"
//                   )}
//                 </Button>
//               </Box>
//             </Form>
//           );
//         }}
//       </Formik>
//       <GoogleLogIn />
//       <SuccessModal open={openModal} setOpen={setOpenModal} name={userName} />
//       <ConfirmationModal
//         open={confirmationModalOpen}
//         onClose={handleConfirmationModalClose}
//         onResendConfirmation={handleResendConfirmationEmail}
//       />
//       <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
//           The email is already registered!!
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default RegistrationForm;
