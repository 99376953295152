import BusinessList from '../../components/business/BusinessList'
import { Box } from '@mui/material'


function Business() {
  return (
    <Box>
      <BusinessList/>
    </Box>
  )
}

export default Business