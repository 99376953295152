import { Box, Grid, Typography } from "@mui/material";
import { useLanguage } from "../../components/languages/LanguageContext";
import {
  WHY_TRUST_US_TITLE,
  WHY_TRUST_US_TITLE_SE,
  WHY_TRUST_US,
  WHY_TRUST_US_SE,
  OUR_MISSION_TITLE,
  OUR_MISSION_TITLE_SE,
  OUR_MISSION_SUB_TITLE,
  OUR_MISSION_SUB_TITLE_SE,
  OUR_MISSION_SUB_P1_TITLE,
  OUR_MISSION_SUB_P1_TITLE_SE,
  OUR_MISSION_SUB_P1,
  OUR_MISSION_SUB_P1_SE,
  OUR_MISSION_SUB_P2_TITLE,
  OUR_MISSION_SUB_P2_TITLE_SE,
  OUR_MISSION_SUB_P2,
  OUR_MISSION_SUB_P2_SE,
  OUR_MISSION_SUB_P3_TITLE,
  OUR_MISSION_SUB_P3_TITLE_SE,
  OUR_MISSION_SUB_P3,
  OUR_MISSION_SUB_P3_SE,
  DISCOVER_MORE_TITLE,
  DISCOVER_MORE_TITLE_SE,
  DISCOVER_MORE_SUBTITLE,
  DISCOVER_MORE_SUBTITLE_SE,
  DISCOVER_EASY_SEARCH,
  DISCOVER_EASY_SEARCH_SE,
  DISCOVER_USER,
  DISCOVER_USER_SE,
  DISCOVER_PHOTOS,
  DISCOVER_PHOTOS_SE,
  WHY_TRUST_US_TEXT1_SE,
  WHY_TRUST_US_TEXT2_SE,
  WHY_TRUST_US_TEXT3_SE,
  WHY_TRUST_US_TEXT1,
  WHY_TRUST_US_TEXT2,
  WHY_TRUST_US_TEXT3,
} from "../../constants/textConstants";
import {
  discoverTitle,
  introTextStyle,
  ourMission,
  ourMissionTitle,
  whyTrustUs,
  contentMission,
  contentMissionIcons,
  contentDiscoverIcons,
  allTytles,
} from "../../constants/styleConstants";
import veg from "../../assets/veg.jpg";

import { VscFeedback } from "react-icons/vsc";
import { RiFeedbackLine } from "react-icons/ri";
import { SlPicture } from "react-icons/sl";
import { FiSearch } from "react-icons/fi";
import { IoMdTime } from "react-icons/io";
import { MdCloudQueue } from "react-icons/md";

function OurMission() {
  const { currentLanguage } = useLanguage();

  return (
    <>
      <Box>
        <Typography sx={allTytles}>
          {currentLanguage === "se"
            ? WHY_TRUST_US_TITLE_SE
            : WHY_TRUST_US_TITLE}
        </Typography>
        <Typography sx={introTextStyle}>
          {currentLanguage === "se" ? WHY_TRUST_US_SE : WHY_TRUST_US}
        </Typography>

        <Box sx={ourMission}>
          <Box style={whyTrustUs}>
            <Box>
              <Typography
                sx={{ fontSize: "14px" }}
                style={contentDiscoverIcons}
                fontWeight="bold"
              >
                +
              </Typography>
              <Typography>
                {currentLanguage === "se"
                  ? WHY_TRUST_US_TEXT1_SE
                  : WHY_TRUST_US_TEXT1}
              </Typography>
            </Box>
            <Box>
              <Typography
                style={contentDiscoverIcons}
                variant="h5"
                fontWeight="bold"
              >
                +
              </Typography>
              <Typography>
                {currentLanguage === "se"
                  ? WHY_TRUST_US_TEXT2_SE
                  : WHY_TRUST_US_TEXT2}
              </Typography>
            </Box>
            <Box>
              <Typography
                style={contentDiscoverIcons}
                variant="h5"
                fontWeight="bold"
              >
                20 +
              </Typography>
              <Typography>
                {currentLanguage === "se"
                  ? WHY_TRUST_US_TEXT3_SE
                  : WHY_TRUST_US_TEXT3}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* SECOND PART */}
      <Box className="container-main" sx={ourMission}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className="first-column">
            <Typography sx={ourMissionTitle}>
              {currentLanguage === "se"
                ? OUR_MISSION_TITLE_SE
                : OUR_MISSION_TITLE}
            </Typography>
            <Box>
              <Typography style={whyTrustUs}>
                {currentLanguage === "se"
                  ? OUR_MISSION_SUB_TITLE_SE
                  : OUR_MISSION_SUB_TITLE}
              </Typography>
            </Box>
            <Box className="content-mission">
              <Box>
                <Typography fontWeight="bold" textAlign="start">
                  <VscFeedback size="30" style={contentMissionIcons} />
                  {currentLanguage === "se"
                    ? OUR_MISSION_SUB_P1_TITLE_SE
                    : OUR_MISSION_SUB_P1_TITLE}
                </Typography>
                <Typography style={contentMission}>
                  {currentLanguage === "se"
                    ? OUR_MISSION_SUB_P1_SE
                    : OUR_MISSION_SUB_P1}
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold" textAlign="start">
                  <RiFeedbackLine size="30" style={contentMissionIcons} />
                  {currentLanguage === "se"
                    ? OUR_MISSION_SUB_P2_TITLE_SE
                    : OUR_MISSION_SUB_P2_TITLE}
                </Typography>
                <Typography style={contentMission}>
                  {currentLanguage === "se"
                    ? OUR_MISSION_SUB_P2_SE
                    : OUR_MISSION_SUB_P2}
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold" textAlign="start">
                  <SlPicture size="30" style={contentMissionIcons} />
                  {currentLanguage === "se"
                    ? OUR_MISSION_SUB_P3_TITLE_SE
                    : OUR_MISSION_SUB_P3_TITLE}
                </Typography>
                <Typography style={contentMission}>
                  {currentLanguage === "se"
                    ? OUR_MISSION_SUB_P3_SE
                    : OUR_MISSION_SUB_P3}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="second-column"
            style={{ paddingBlock: "50px" }}
          >
            <img
              width="100%"
              height="auto"
              src={veg}
              alt="veg"
              loading="lazy"
            />
          </Grid>
        </Grid>
      </Box>

      {/* THIRD PART */}
      <Box className="discover-more">
        <Typography sx={discoverTitle}>
          {currentLanguage === "se"
            ? DISCOVER_MORE_TITLE_SE
            : DISCOVER_MORE_TITLE}
        </Typography>
        <Typography textAlign={"left"} sx={introTextStyle}>
          {currentLanguage === "se"
            ? DISCOVER_MORE_SUBTITLE_SE
            : DISCOVER_MORE_SUBTITLE}
        </Typography>

        <Box sx={ourMission}>
          <Grid container spacing={2} style={whyTrustUs}>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h5" fontWeight="bold">
                  <FiSearch style={contentDiscoverIcons} />
                </Typography>
                <Typography>
                  {currentLanguage === "se"
                    ? DISCOVER_EASY_SEARCH_SE
                    : DISCOVER_EASY_SEARCH}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h5" fontWeight="bold">
                  <IoMdTime style={contentDiscoverIcons} />
                </Typography>
                <Typography>
                  {currentLanguage === "se" ? DISCOVER_USER_SE : DISCOVER_USER}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h5" fontWeight="bold">
                  <MdCloudQueue style={contentDiscoverIcons} />
                </Typography>
                <Typography>
                  {currentLanguage === "se"
                    ? DISCOVER_PHOTOS_SE
                    : DISCOVER_PHOTOS}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default OurMission;
