import React, { useState } from "react";
import axios from "axios";

import { Form, Formik } from "formik";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

import updateBusinessSchema from "./updateBusinessSchema";
import "./updateBusiness.css";
import { BusinessType } from "../../../types/businessTypes";
import { url } from "../../../App";

type InitialValues = {
  title: string;
  description: string;
  category: string;
  rate: number;
  imageUrl?: string | null;
  iconUrl?: string | null;
  branches: { location: string; address: string }[];
};

type PropType = {
  businessToUpdate: BusinessType | undefined;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const UpdateBusiness = ({ businessToUpdate, setOpenModal }: PropType) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedIconFile, setSelectedIconFile] = useState<File | null>(null);

  // Initial Values
  const initialValues: InitialValues = {
    title: businessToUpdate?.title || "",
    description: businessToUpdate?.description || "",
    category: businessToUpdate?.category || "",
    imageUrl: businessToUpdate?.image || null,
    iconUrl: businessToUpdate?.icon || null,
    rate: businessToUpdate?.rate || 0,
    branches: businessToUpdate?.branches || [],
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedIconFile(e.target.files[0]);
    }
  };
  // Function Call on Submit
  const token = localStorage.getItem("token");

  const submitHandler = async (values: InitialValues) => {
    const updateData = new FormData();

    // Append all other form fields to FormData
    updateData.append("title", values.title);
    updateData.append("description", values.description);
    updateData.append("category", values.category);
    updateData.append("rate", values.rate.toString());
    values.branches.forEach((branch, index) => {
      updateData.append(`branches[${index}][location]`, branch.location);
      updateData.append(`branches[${index}][address]`, branch.address);
    });
    // Append image if selected
    if (selectedFile) {
      updateData.append("image", selectedFile); // Use the correct field name as expected by the backend
    }
    if (selectedIconFile) {
      updateData.append("icon", selectedIconFile); // Use the correct field name as expected by the backend
    }
    try {
      const response = await axios.put(
        `${url}/business/${businessToUpdate?._id}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setOpenModal(false);
        setSelectedFile(null);
      } else {
        // Handle non-200 responses here
        console.error(
          "Server responded with non-200 status:",
          response.status,
          response.data
        );
      }
    } catch (error) {
      console.error("Error during submission:", error);
      if (axios.isAxiosError(error) && error.response) {
        console.error("Server responded with error:", error.response.data);
      }
    }
  };

  return (
    <Box
      className="update-business-form-container"
      style={{ border: "1px solid red" }}
    >
      <h2>Update Business</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={updateBusinessSchema}
        onSubmit={submitHandler}
      >
        {({ values, errors, touched, handleChange }) => {
          return (
            <Form className="update-business-form">
              <Box>
                <TextField
                  className="update-business-text"
                  label="Title"
                  name="titleUpdate"
                  onChange={handleChange}
                  defaultValue={businessToUpdate?.title}
                />
                {errors.title && touched.title ? (
                  <Box className="error-message">{errors.title}</Box>
                ) : null}
              </Box>

              <Box>
                <TextField
                  className="update-business-text"
                  label="Rate"
                  name="rateUpdate"
                  type="number"
                  onChange={handleChange}
                  defaultValue={businessToUpdate?.rate}
                />
                {errors.rate && touched.rate ? (
                  <Box className="error-message">{errors.rate}</Box>
                ) : null}
              </Box>
              <Box>
                <TextField
                  className="update-business-text"
                  label="Category"
                  name="categoryUpdate"
                  multiline
                  rows={2}
                  onChange={handleChange}
                  defaultValue={initialValues.category}
                />
                {errors.category && touched.category ? (
                  <Box className="error-message">{errors.category}</Box>
                ) : null}
              </Box>
              <Box>
                <TextField
                  className="update-business-text"
                  label="Description"
                  name="descriptionUpdate"
                  multiline
                  rows={2}
                  onChange={handleChange}
                  defaultValue={businessToUpdate?.description}
                />
                {errors.description && touched.description ? (
                  <Box className="error-message">{errors.description}</Box>
                ) : null}
              </Box>

              {/* {values.branches.map((branch, index) => (
              <Box key={index}>
               <TextField
                className="update-business-text" 
  label={`Branch Location ${index + 1}`}
  name={`branches[${index}].location`}
  onChange={handleChange}
  value={branch.location}
/>
<TextField
  label={`Branch Address ${index + 1}`}
  name={`branches[${index}].address`}
  onChange={handleChange}
  value={branch.address}
/>
              </Box>
            ))} */}

              <Box>
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={handleImageChange}
                />
                image
              </Box>
              <Box>
                <input
                  type="file"
                  name="icon"
                  accept="image/*"
                  onChange={handleIconChange}
                />
                icon
              </Box>
              <Box>
                <Button sx={{ mr: 1 }} type="submit" variant="outlined">
                  Update Business
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default UpdateBusiness;
