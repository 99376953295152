import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserType,FavoriteBusinessPayload } from "../../types/userTypes";
import { BusinessType } from "../../types/businessTypes";

type InitialType = {
  user: UserType;
  isLogin: boolean; 
  error: string;

}; 

const initialState: InitialType = { 
  user: {
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    isAdmin: false,
    isBanned: false,
    image: "",
    isGoogleLogin: false,
    avatar: "", 
    favoriteBusinesses:[], 
    googleId: "",
    emailConfirmed: false, // New field for email confirmation status
    confirmationToken: undefined, // New field for the confirmation token
    confirmationExpires: undefined, // New field for token expiration time
  },
  isLogin: false,
  error: "",
}; 


const userSlice = createSlice({ 
  name: "user",
  initialState,
  reducers: {
    getUser: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
      state.isLogin = true;
    },
    loginHandler: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    logout: (state) => {
      state.user = initialState.user;
      state.isLogin = false;
    },
    addFavoriteBusiness(state, action: PayloadAction<BusinessType>) {
      // Assuming action.payload is a BusinessType object to be added
      state.user.favoriteBusinesses.push(action.payload);
    },
    removeFavoriteBusiness(state, action: PayloadAction<string>) { 
      state.user.favoriteBusinesses = state.user.favoriteBusinesses.filter(business => business._id !== action.payload);
    },
    setFavoriteBusinesses: (state, action: PayloadAction<BusinessType[]>) => {
      state.user.favoriteBusinesses = action.payload;
    },
  
    errorHandler: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setEmailConfirmed: (state, action: PayloadAction<boolean>) => {
      state.user.emailConfirmed = action.payload;
    },
    setConfirmationToken: (state, action: PayloadAction<string | undefined>) => {
      state.user.confirmationToken = action.payload;
    },
    setConfirmationExpires: (state, action: PayloadAction<Date | undefined>) => {
      state.user.confirmationExpires = action.payload;
    },
  },
});
 
export const userActions = userSlice.actions;
export default userSlice.reducer;
