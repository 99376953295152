import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import { Alert, Box, Button, TextField } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import axios from "axios";

import addBusinessSchema from "./addBusinessSchema";
import { url } from "../../../App";
import "./addBusiness.css";
import { AppDispatch } from "../../../redux/store";
import { businessActions } from "../../../redux/slice/business";

type InitialType = {
  title: string;
  description: string;
  category: string;
  rate: number;
  imageUrl?: string | null;
  iconUrl?: string | null;
  branches: { location: string; address: string; _id: string }[];
};

const initialValues: InitialType = {
  title: "",
  description: "",
  category: "",
  rate: 0,
  imageUrl: null,
  iconUrl: null,
  branches: [{ location: "", address: "", _id: "" }],
};

const AddBusiness = () => {
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedIconFile, setSelectedIconFile] = useState<File | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = () => setOpen(true);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedIconFile(e.target.files[0]);
    }
  };

  const submitHandlerAdd = async (
    values: InitialType,
    { resetForm }: FormikHelpers<InitialType>
  ) => {
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("rate", String(values.rate));
      formData.append("category", values.category);

      values.branches.forEach((branch, index) => {
        formData.append(`branches[${index}][location]`, branch.location);
        formData.append(`branches[${index}][address]`, branch.address);
      });
      // If a file has been selected, append it to the formData
      if (selectedFile) {
        formData.append("image", selectedFile);
      }
      if (selectedIconFile) {
        formData.append("icon", selectedIconFile); // Use the correct field name as expected by the backend
      }
      const response = await axios.post(`${url}/business/upload`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 201) {
        handleClick();
        setOpen(true);
        resetForm({ values: initialValues });
        setSelectedFile(null);
        dispatch(businessActions.addBusiness(response.data));
        // dispatch(businessActions.getAllBusinesses(businessList));
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  return (
    <Box className="add-business-container">
      <h2>Add Business</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={addBusinessSchema}
        onSubmit={submitHandlerAdd}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <TextField
              label="Title"
              name="titleAddBusiness"
              onChange={(e) =>
                handleChange({
                  target: { name: "title", value: e.target.value },
                })
              }
              value={values.title}
              error={touched.title && Boolean(errors.title)}
            />

            <TextField
              label="Rate"
              name="rateBusiness"
              type="number"
              onChange={(e) =>
                handleChange({
                  target: { name: "rate", value: e.target.value },
                })
              }
              value={values.rate}
              error={touched.rate && Boolean(errors.rate)}
            />
            <TextField
              label="Category"
              name="categoryBusiness"
              onChange={(e) =>
                handleChange({
                  target: { name: "category", value: e.target.value },
                })
              }
              value={values.category}
              error={touched.category && Boolean(errors.category)}
            />
            <TextField
              label="Description"
              name="descriptionBusiness"
              multiline
              rows={4}
              onChange={(e) =>
                handleChange({
                  target: { name: "description", value: e.target.value },
                })
              }
              value={values.description}
              error={touched.description && Boolean(errors.description)}
            />

            {values.branches.map((branch, index) => (
              <Box key={branch._id}>
                <TextField
                  label={`Branch Location ${index + 1}`}
                  name={`branches[${index}].location`}
                  onChange={handleChange}
                  value={branch.location}
                />
                <TextField
                  label={`Branch Address ${index + 1}`}
                  name={`branches[${index}].address`}
                  onChange={handleChange}
                  value={branch.address}
                />
              </Box>
            ))}

            <Box>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
              />
              image
            </Box>
            <Box>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleIconChange}
              />
              icon
            </Box>
            <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
              Add Business
            </Button>
          </Form>
        )}
      </Formik>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Business added successfully Alina!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddBusiness;
