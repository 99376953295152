import { Box, Typography, Button, Divider } from "@mui/material";
import { useLanguage } from "../../components/languages/LanguageContext";
import {
  TERMS_ACCEPTANCE_OF_TERMS,
  TERMS_USER_CONDUCT_PART1,
  TERMS_USER_CONDUCT_PART2,
  TERMS_USER_GENERATED_CONTENT,
  TERMS_PROHIBITED_CONTENT_PART1,
  TERMS_PROHIBITED_CONTENT_PART2,
  TERMS_MONITORING_AND_ENFORCEMENT_PART1,
  TERMS_MONITORING_AND_ENFORCEMENT_PART2,
  TERMS_INTELLECTUAL_PROPERTY_RIGHTS,
  TERMS_PRIVACY,
  TERMS_DISCLAIMER_OF_WARRANTIES,
  TERMS_LIMITATION_OF_LIABILITY,
  TERMS_ACCEPTANCE_OF_TERMS_SV,
  TERMS_USER_CONDUCT_PART1_SV,
  TERMS_USER_CONDUCT_PART2_SV,
  TERMS_USER_GENERATED_CONTENT_SV,
  TERMS_PROHIBITED_CONTENT_PART1_SV,
  TERMS_PROHIBITED_CONTENT_PART2_SV,
  TERMS_MONITORING_AND_ENFORCEMENT_PART1_SV,
  TERMS_MONITORING_AND_ENFORCEMENT_PART2_SV,
  TERMS_INTELLECTUAL_PROPERTY_RIGHTS_SV,
  TERMS_PRIVACY_SV,
  TERMS_DISCLAIMER_OF_WARRANTIES_SV,
  TERMS_LIMITATION_OF_LIABILITY_SV,
} from "../../constants/textConstants"; // Assuming the constants are exported from a file named termsConstants.js

function TermsOfUse() {
  const { currentLanguage, toggleLanguage } = useLanguage();

  const terms =
    currentLanguage === "se"
      ? {
          acceptance: TERMS_ACCEPTANCE_OF_TERMS_SV,
          conductPart1: TERMS_USER_CONDUCT_PART1_SV,
          conductPart2: TERMS_USER_CONDUCT_PART2_SV,
          userGenerated: TERMS_USER_GENERATED_CONTENT_SV,
          prohibitedPart1: TERMS_PROHIBITED_CONTENT_PART1_SV,
          prohibitedPart2: TERMS_PROHIBITED_CONTENT_PART2_SV,
          monitoringPart1: TERMS_MONITORING_AND_ENFORCEMENT_PART1_SV,
          monitoringPart2: TERMS_MONITORING_AND_ENFORCEMENT_PART2_SV,
          ipRights: TERMS_INTELLECTUAL_PROPERTY_RIGHTS_SV,
          privacy: TERMS_PRIVACY_SV,
          disclaimer: TERMS_DISCLAIMER_OF_WARRANTIES_SV,
          liability: TERMS_LIMITATION_OF_LIABILITY_SV,
        }
      : {
          acceptance: TERMS_ACCEPTANCE_OF_TERMS,
          conductPart1: TERMS_USER_CONDUCT_PART1,
          conductPart2: TERMS_USER_CONDUCT_PART2,
          userGenerated: TERMS_USER_GENERATED_CONTENT,
          prohibitedPart1: TERMS_PROHIBITED_CONTENT_PART1,
          prohibitedPart2: TERMS_PROHIBITED_CONTENT_PART2,
          monitoringPart1: TERMS_MONITORING_AND_ENFORCEMENT_PART1,
          monitoringPart2: TERMS_MONITORING_AND_ENFORCEMENT_PART2,
          ipRights: TERMS_INTELLECTUAL_PROPERTY_RIGHTS,
          privacy: TERMS_PRIVACY,
          disclaimer: TERMS_DISCLAIMER_OF_WARRANTIES,
          liability: TERMS_LIMITATION_OF_LIABILITY,
        };

  return (
    <Box sx={{ width: "90%", margin: "auto" }}>
      <Typography variant="h4" style={{ marginBlock: "50px" }} gutterBottom>
        {currentLanguage === "se" ? "Användarvillkor" : "Terms of Use"}
      </Typography>

      <Divider sx={{ marginBottom: 2 }} />
      <Typography variant="h6" gutterBottom>
        {currentLanguage === "se"
          ? "Godkännande av Villkor"
          : "Acceptance of Terms"}
      </Typography>
      <Typography align="left" paragraph>
        {terms.acceptance}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" gutterBottom>
        {currentLanguage === "se" ? "Användarbeteende" : "User Conduct"}
      </Typography>
      <Typography align="left" paragraph>
        {terms.conductPart1}
      </Typography>
      <Typography align="left" paragraph>
        {terms.conductPart2}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" gutterBottom>
        {currentLanguage === "se"
          ? "Användargenererat Innehåll"
          : "User-Generated Content"}
      </Typography>
      <Typography align="left" paragraph>
        {terms.userGenerated}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" gutterBottom>
        {currentLanguage === "se" ? "Förbjudet Innehåll" : "Prohibited Content"}
      </Typography>
      <Typography align="left" paragraph>
        {terms.prohibitedPart1}
      </Typography>
      <Typography align="left" paragraph>
        {terms.prohibitedPart2}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" gutterBottom>
        {currentLanguage === "se"
          ? "Övervakning och Tillämpning"
          : "Monitoring and Enforcement"}
      </Typography>
      <Typography align="left" paragraph>
        {terms.monitoringPart1}
      </Typography>
      <Typography align="left" paragraph>
        {terms.monitoringPart2}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" gutterBottom>
        {currentLanguage === "se"
          ? "Immateriella Rättigheter"
          : "Intellectual Property Rights"}
      </Typography>
      <Typography align="left" paragraph>
        {terms.ipRights}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" gutterBottom>
        {currentLanguage === "se" ? "Sekretess" : "Privacy"}
      </Typography>
      <Typography align="left" paragraph>
        {terms.privacy}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" gutterBottom>
        {currentLanguage === "se"
          ? "Ansvarsfriskrivning"
          : "Disclaimer of Warranties"}
      </Typography>
      <Typography align="left" paragraph>
        {terms.disclaimer}
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Typography variant="h6" gutterBottom>
        {currentLanguage === "se"
          ? "Ansvarsbegränsning"
          : "Limitation of Liability"}
      </Typography>
      <Typography align="left" paragraph>
        {terms.liability}
      </Typography>
    </Box>
  );
}

export default TermsOfUse;
