import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import imageCompression from "browser-image-compression";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { LuSendHorizonal } from "react-icons/lu";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  Rating,
  styled,
  Alert,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { url } from "../../../App";
import { BusinessType } from "../../../types/businessTypes";
import { RootState, AppDispatch } from "../../../redux/store";
import { commentActions } from "../../../redux/slice/comment";
import { businessActions } from "../../../redux/slice/business";
import {
  fetchAllComments,
  fetchCommentByBusinessId,
} from "../../../redux/thunk/comment";
import { SubCommentType } from "../../../types/subCommentTypes";
import { UserType } from "../../../types/userTypes";
import { Favorite } from "../../../types/favoriteTypes";

import CommentItem from "../../comment/CommentItem";
import SearchBarComments from "../../search/SearchBarComments";
import { commentSchema } from "./commentSchema";
import { useLanguage } from "../../languages/LanguageContext";

import {
  businessCardStyle,
  contentCardStyle,
  textFieldStyle,
  alertStyle,
  formContainerStyle,
  commentListStyle,
  snackbarStyle,
  backButtonStyle,
  businessDetailsStyle,
  introTextStyle,
  businessDetailCardStyle,
  businessRating,
  cardAvatarStyle,
  cardAvatarImageStyle,
  commentItemButtonsBox,
  LinkLoginSxProps,
} from "../../../constants/styleConstants";
import {
  BUSINESS_DETAILS_LOGIN,
  BUSINESS_DETAILS_LOGIN_SE,
  BUSINESS_DETAILS_WELCOME,
  BUSINESS_DETAILS_WELCOME_SE,
} from "../../../constants/textConstants";

import SpinnerContainer from "./SpinnerContainer";

type PropType = {
  business: BusinessType;
  user: UserType;
  favorite?: Favorite;
};

type CommentFormValues = {
  text: string;
  rate: number | null;
  imageUrl?: string | null;
  businessLocation: string;
  subComments?: SubCommentType[] | null;
};

const initialFormValues: CommentFormValues = {
  text: "",
  rate: null,
  imageUrl: null,
  businessLocation: "",
  subComments: null,
};

const BusinessItemRateFav = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const BusinessDetail = ({ business }: PropType) => {
  const [open, setOpen] = useState(false);
  const [loginAlertOpen, setLoginAlertOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageSizeError, setImageSizeError] = useState(false);
  const [loading, setLoading] = useState(true);

  const isLoggedIn = useSelector((state: RootState) => state.user.isLogin);

  const { currentLanguage } = useLanguage();

  const [hasRated, setHasRated] = useState(false);
  const [userRate, setUserRate] = useState<number>(business.rate);
  const [isSavingRate, setIsSavingRate] = useState(false);

  const [imageSelected, setImageSelected] = useState(false);

  type AlertState = {
    message: string;
    severity: "success" | "error" | "warning" | "info";
  };

  const [alertRate, setAlertRate] = useState<AlertState>({
    message: "",
    severity: "success",
  });

  // const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch<AppDispatch>();

  const commentList = useSelector((state: RootState) => state.comment.comments);

  const commentSearchList = useSelector(
    (state: RootState) => state.search.commentSearchResultList
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch(fetchAllComments());
  }, [dispatch]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await dispatch(fetchCommentByBusinessId(business._id));
  //     } catch (error) {
  //       console.error("Error in useEffect:", error);
  //     }
  //   };

  //   fetchData();
  // }, [dispatch, business._id]);
  useEffect(() => {
    const fetchData = async () => {
      if (!business?._id) {
        console.error("Business ID is undefined or business is null");
        setLoading(false); // Set loading to false if there's no valid business ID
        return;
      }

      try {
        await dispatch(fetchCommentByBusinessId(business._id));
      } catch (error) {
        console.error("Error in useEffect:", error);
      } finally {
        setLoading(false); // Set loading to false once fetch is complete
      }
    };

    fetchData();
  }, [dispatch, business]);

  const handleLoginAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setLoginAlertOpen(false);
  };

  const handleRateChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | null
  ) => {
    if (newValue !== null) {
      setUserRate(newValue);
    }
  };

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleLoginAlertOpen = () => {
    setLoginAlertOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const token = localStorage.getItem("token");

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImageSelected(true);
      // Options for image compression
      const options = {
        maxSizeMB: 2, // Max file size in MB (1000 KB)
        maxWidthOrHeight: 1920, // Increase max width or height to ensure high quality
        useWebWorker: true, // Use web worker for faster compression
        initialQuality: 0.8, // Start with the highest quality setting
        alwaysKeepResolution: true, // Ensure the resolution is preserved
      };

      try {
        const compressedFile = await imageCompression(file, options);
        setImageSizeError(false); // Reset imageSizeError
        setSelectedFile(compressedFile); // Set the compressed file as selectedFile
      } catch (error) {
        console.error("Image compression failed:", error);
        setImageSizeError(true); // Set imageSizeError if compression fails
        setSelectedFile(null); // Clear selectedFile
      }
    }
  };

  const handleCloseErrorAlert = () => {
    setImageSizeError(false);
  };
  //-------------------------------------RATE---------------
  const submitRate = async () => {
    // Check if user is logged in
    if (!isLoggedIn) {
      setAlertRate({
        message: "Please log in to submit a rate",
        severity: "warning",
      });
      setOpen(true);
      return; // Exit the function
    }

    if (userRate !== 0) {
      setIsSavingRate(true);
      try {
        const requestBody = JSON.stringify({ rate: userRate });

        // Perform POST request to add rate to the backend (MongoDB)
        const response = await fetch(
          `${url}/business/addRate/${business._id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Set Content-Type header
            },
            body: requestBody,
          }
        );

        if (response.ok) {
          const updatedBusiness = await response.json();
          dispatch(
            businessActions.addRate({
              businessId: business._id,
              rate: updatedBusiness.rate,
            })
          );
          setAlertRate({
            message: "Thank you for your rate!",
            severity: "success",
          });
          setOpen(true);
          setHasRated(true);
        } else {
          // Handle error if the request fails
          const errorData = await response.json(); // Try to get error details from the response
          setAlertRate({
            message: "Error adding rate.",
            severity: "error",
          });
          setOpen(true);
          console.error("Error adding rate:", errorData);
        }
      } catch (error) {
        setAlertRate({
          message: "An error occurred. Please try again.",
          severity: "error",
        });
        setOpen(true);
        console.error("Error:", error);
      } finally {
        setIsSavingRate(false);
      }
    }
  };
  //----------------------------------------RATE ENDS------------

  const submitHandler = async (
    values: CommentFormValues,
    { resetForm }: FormikHelpers<CommentFormValues>
  ) => {
    if (!token) {
      console.log("No token available, user might not be logged in.");

      handleLoginAlertOpen();
      return;
    }

    const commentData = new FormData();
    commentData.append("text", values.text);
    if (values.rate) {
      commentData.append("rate", String(values.rate));
    }
    commentData.append("businessLocation", values.businessLocation);

    if (business && business._id) {
      commentData.append("businessId", business._id);
    } else {
      console.log("Business ID is not available.");
    }

    if (business && business.icon) {
      commentData.append("businessIcon", business.icon);
    } else {
      console.log("Business Icon is not available.");
    }

    if (selectedFile) {
      commentData.append("image", selectedFile);
    }

    try {
      const response = await axios.post(
        `${url}/comment/create/${business._id}`,
        commentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      if (response.status === 201) {
        dispatch(commentActions.addComment(response.data));
        resetForm();
        setSelectedFile(null);
      } else {
        console.error("Failed to post the comment:", response.data);
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
      console.log("Failed request details:", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        values,
      });
    }
  };

  // const displayComments =
  //   commentSearchList && commentSearchList.length > 0
  //     ? commentSearchList
  //     : commentList;

  const displayComments =
    commentSearchList && commentSearchList.length > 0
      ? commentSearchList
      : commentList;

  // Optional: Render loading indicator
  if (loading) {
    return (
      <SpinnerContainer /> // Render your SpinnerContainer when loading
    );
  }

  return (
    <Box className="business-details" sx={businessDetailsStyle}>
      <Box sx={{ marginTop: "50px" }}>
        <Typography sx={introTextStyle}>
          {currentLanguage === "se"
            ? BUSINESS_DETAILS_WELCOME_SE
            : BUSINESS_DETAILS_WELCOME}
        </Typography>
      </Box>
      <Box>
        <Box className="business-recipe" sx={businessDetailCardStyle}>
          <Box className="image-card" sx={businessCardStyle}>
            <Avatar sx={cardAvatarStyle} aria-label="recipe">
              <img
                src={business.icon}
                style={cardAvatarImageStyle}
                alt={business.title}
                loading="lazy"
              />
            </Avatar>
            {business.image && (
              <Card>
                <CardMedia
                  component="img"
                  image={business.image.toString()}
                  alt={business.title}
                  width="100%"
                  loading="lazy"
                />
              </Card>
            )}
            <Link to="/business">
              <Button title="Go Back" sx={backButtonStyle}>
                <ArrowBackIcon />
              </Button>
            </Link>
          </Box>
          <Box className="content-card" sx={contentCardStyle}>
            <Typography textAlign={"center"} variant="h6">
              {business.title}
            </Typography>
            <Typography textAlign={"center"} variant="body1">
              {business.category}
            </Typography>
            <Box>
              {" "}
              {business.description.split("\n").map((description, index) => (
                <Typography key={index}>{description.trim()}</Typography>
              ))}
            </Box>
            <Box sx={businessRating}>
              <BusinessItemRateFav>
                <Rating
                  name="simple-controlled"
                  value={userRate}
                  onChange={handleRateChange}
                />

                <Tooltip
                  title={!isLoggedIn ? "Please log in to submit a rate" : ""}
                >
                  <span>
                    <Button
                      onClick={submitRate}
                      disabled={isSavingRate || hasRated}
                    >
                      {isSavingRate ? "Rate Submitted" : "Rate"}
                    </Button>
                  </span>
                </Tooltip>
              </BusinessItemRateFav>
            </Box>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={alertRate.severity}
                sx={alertStyle}
              >
                {alertRate.message}
              </Alert>
            </Snackbar>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "50px" }}>
        <SearchBarComments businessId={business._id} />
      </Box>
      <Box className="business-textfields" sx={formContainerStyle}>
        <Formik
          initialValues={initialFormValues}
          validationSchema={commentSchema}
          onSubmit={submitHandler}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form>
              {isLoggedIn ? (
                <Box>
                  <Box>
                    <TextField
                      className="textBox"
                      label="Enter branch location (e.g., Huddinge, Vällingby)"
                      name="businessLocation"
                      onChange={handleChange}
                      value={values.businessLocation}
                      error={
                        touched.businessLocation &&
                        Boolean(errors.businessLocation)
                      }
                      helperText={
                        touched.businessLocation && errors.businessLocation
                      }
                      sx={textFieldStyle}
                    />
                  </Box>

                  <TextField
                    className="textBox"
                    label="Write your comment"
                    name="text"
                    multiline
                    rows={8}
                    onChange={handleChange}
                    value={values.text}
                    error={touched.text && Boolean(errors.text)}
                    helperText={touched.text && errors.text}
                    sx={textFieldStyle}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            position: "absolute",
                            bottom: "15px",
                            right: "10px",
                          }}
                        >
                          <Box
                            className="comment-submit-box"
                            sx={commentItemButtonsBox}
                          >
                            <Box sx={{ margin: "auto" }}>
                              <label
                                htmlFor="file-input"
                                title="Attach Picture"
                              >
                                <Button
                                  sx={{
                                    color: imageSelected ? "black" : "grey",
                                  }}
                                >
                                  <MdOutlinePhotoCamera
                                    size={"1.5em"}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => inputRef.current?.click()}
                                  />
                                </Button>
                              </label>

                              <input
                                ref={inputRef}
                                id="file-input"
                                type="file"
                                name="image"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: "none" }}
                              />

                              <Button
                                sx={{ color: "grey" }}
                                type="submit"
                                title="Submit Comment"
                              >
                                <LuSendHorizonal size={"1.5em"} />
                              </Button>
                            </Box>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              ) : (
                <Typography sx={LinkLoginSxProps}>
                  <Link
                    to="/login"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {currentLanguage === "se"
                      ? BUSINESS_DETAILS_LOGIN_SE
                      : BUSINESS_DETAILS_LOGIN}
                  </Link>
                </Typography>
              )}
            </Form>
          )}
        </Formik>
        {imageSizeError && (
          <Snackbar
            open={imageSizeError}
            autoHideDuration={6000}
            onClose={handleCloseErrorAlert}
          >
            <Alert onClose={handleCloseErrorAlert} severity="error">
              The selected image exceeds the size limit (10 KB). Please choose a
              smaller image.
            </Alert>
          </Snackbar>
        )}
        <Snackbar
          open={loginAlertOpen}
          autoHideDuration={3000}
          onClose={handleLoginAlertClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleLoginAlertClose}
            severity="error"
            sx={snackbarStyle}
          >
            Please log in in order to leave a comment
          </Alert>
        </Snackbar>
      </Box>

      <Box className="comment-list" sx={commentListStyle}>
        {displayComments.length > 0 ? (
          displayComments
            .slice()
            .reverse()
            .map((comment) => (
              <CommentItem key={comment._id} comment={comment} />
            ))
        ) : (
          <Box className="loading">
            <i className="fas fa-spinner fa-spin fa-xl" />
            <em>No comments found</em>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BusinessDetail;
