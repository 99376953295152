import TopReviews from "../../components/topReviews/TopReviews";
import SearchBarHome from "../../components/search/SearchBarHome";
import { Box, Typography, IconButton } from "@mui/material";
import BusinessCarousel from "../../components/carousel/BusinessCarousel";
import { INTRO_TEXT, INTRO_TEXT_SE } from "../../constants/textConstants";
import { introTextStyle } from "../../constants/styleConstants";
import OurMission from "../../components/about/OurMission";
import Assistance from "../../components/about/Assistance";
import { useLanguage } from "../../components/languages/LanguageContext";
import { useState, useEffect } from "react";
import arrowImg from "../../assets/arrow.png";

function Home() {
  const { currentLanguage } = useLanguage();
  const [showScrollArrow, setShowScrollArrow] = useState(false);

  // Function to scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Effect to track scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollArrow(true);
      } else {
        setShowScrollArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box>
      <BusinessCarousel />
      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        <SearchBarHome />
      </Box>

      <Box>
        <Typography sx={introTextStyle}>
          {currentLanguage === "se" ? INTRO_TEXT_SE : INTRO_TEXT}
        </Typography>
      </Box>

      <TopReviews />

      <OurMission />
      <Assistance />

      {/* Arrow for scrolling back to the top */}
      {showScrollArrow && (
        <IconButton
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            width: "60px",
            height: "80px",
            backgroundColor: "transparent",
            padding: 0, // Remove any default padding for the icon button
            "&:hover": {
              transform: "scale(1.1)", // Slight scale-up on hover
            },
            "&:focus": {
              outline: "none", // Remove the default focus outline
              boxShadow: "none", // Remove any default box-shadow (if applied)
            },
          }}
        >
          <img
            src={arrowImg} // Use the imported image
            alt="Scroll to top"
            loading="lazy"
            style={{
              width: "40px", // Adjust width and height as necessary
              height: "auto",
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}

export default Home;
