import { useEffect } from "react";
import { Box, Typography, Button, IconButton, Tooltip } from "@mui/material";
import BookmarkFavorite from "@mui/icons-material/BookmarkAddOutlined";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import FavoriteItem from "../favoriteItem/FavoriteItem";
import { fetchFavoriteBusinesses } from "../../../redux/thunk/user";
import { BusinessType } from "../../../types/businessTypes";
import {
  FAVORITE_PAGE_WELCOME,
  FAVORITE_PAGE_WELCOME_SE,
} from "../../../constants/textConstants";
import {
  cardContainerStyle,
  introTextStyle,
} from "../../../constants/styleConstants";
import { useLanguage } from "../../languages/LanguageContext";

const FavoriteList = () => {
  const isLogin = useSelector((state: RootState) => state.user.isLogin);

  const user = useSelector((state: RootState) => state.user.user);
  const favoriteBusinesses = useSelector(
    (state: RootState) => state.user.user.favoriteBusinesses
  );
  const { currentLanguage } = useLanguage();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (isLogin && user._id) {
      dispatch(fetchFavoriteBusinesses(user._id));
    }
  }, [dispatch, isLogin, user._id]);

  if (!isLogin) {
    return (
      <Box
        sx={{
          width: "90%",
          marginInline: "auto",
          textAlign: "center",
        }}
      >
        <Typography variant="h6">
          Please log in to view your favorites!
        </Typography>
        <Link to="/logIn">
          <Button sx={{ textAlign: "center" }}>
            <Typography>LOG IN</Typography>
          </Button>
        </Link>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "90%", marginInline: "auto" }}>
      <Box sx={{ marginTop: "50px" }}>
        <Typography variant="h4" sx={introTextStyle}>
          {currentLanguage === "se"
            ? FAVORITE_PAGE_WELCOME_SE
            : FAVORITE_PAGE_WELCOME}{" "}
        </Typography>
      </Box>

      {favoriteBusinesses.length === 0 ? (
        <Box>
          <Tooltip title="Back to products">
            <Link to="/products">
              <Button>
                <BookmarkFavorite sx={{ fontSize: "50px" }} />
              </Button>
            </Link>
          </Tooltip>
          <em>Please add businesses to your favorites!</em>
        </Box>
      ) : (
        <Box sx={cardContainerStyle}>
          {favoriteBusinesses.map((business, index) => {
            if (business && business._id) {
              return (
                <FavoriteItem key={`favorite-${index}`} business={business} />
              );
            }
            return null;
          })}
        </Box>
      )}
    </Box>
  );
};

export default FavoriteList;
