// components/LoginPopup.tsx
import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth"; // Adjust the import according to your file structure
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LoginPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Show popup after 5 seconds if the user is not authenticated
    if (!isAuthenticated) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 10000);

      return () => clearTimeout(timer);
    } else {
      setShowPopup(false);
    }
  }, [isAuthenticated]);

  // Function to handle navigation and close popup
  const handleNavigation = (path: string) => {
    setShowPopup(false);
    navigate(path);
  };

  return (
    <Modal
      open={showPopup}
      // Prevent the modal from closing on clicking outside or pressing ESC key
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          return;
        }
      }}
      aria-labelledby="login-popup-title"
      aria-describedby="login-popup-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography id="login-popup-title" variant="h6">
          Please login to use ShoutItOut
        </Typography>
        <Typography id="login-popup-description" sx={{ mt: 2 }}>
          You need to be logged in to use all features of ShoutItOut.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          onClick={() => handleNavigation("/login")} // Navigate to login
        >
          Login
        </Button>
        <Button
          variant="outlined"
          color="primary"
          sx={{ mt: 2 }}
          onClick={() => handleNavigation("/registration")} // Navigate to registration
        >
          Register
        </Button>
      </Box>
    </Modal>
  );
};

export default LoginPopup;
