import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import emailjs from "emailjs-com"; // No more TypeScript error
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

const SERVICE_ID = process.env.REACT_APP_SERVICEID || "";
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATEID || "";
const USER_ID = process.env.REACT_APP_USERID || "";

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    from_name: "",
    user_email: "",
    message: "",
  });

  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, e.target as HTMLFormElement, USER_ID)
      .then(
        (result) => {
          alert("Message was sent successfully");
          navigate("/"); // Redirects to the home page
        },
        (error) => {
          console.error("EmailJS error:", error); // Log the full error object
          alert(`Error occurred: ${error.text || "Check console for details"}`);
        }
      );

    e.currentTarget.reset(); // Reset the form
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "50vh",
        padding: "20px",
        backgroundColor: "#F0F2F5",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>

      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        onSubmit={handleOnSubmit}
      >
        <TextField
          label="Your Name"
          variant="outlined"
          name="from_name"
          value={formData.from_name}
          onChange={handleInputChange}
          fullWidth
          required
        />

        <TextField
          label="Your Email"
          variant="outlined"
          name="user_email"
          type="email"
          value={formData.user_email}
          onChange={handleInputChange}
          fullWidth
          required
        />

        <TextField
          label="Your Message"
          variant="outlined"
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={6}
          required
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ padding: "10px" }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Contact;
