// import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { BusinessType } from "../../types/businessTypes";

// type InitialType = {
//   businesses: BusinessType[];
//   allBusinesses: BusinessType[];
//   businessDetails: BusinessType;
//   alert: string; // Moved alert here to handle all UI related messages
// };

// const initialState: InitialType = {
//   businesses: [],
//   allBusinesses: [],
//   businessDetails: {
//     _id: "",
//     title: "",
//     description: "",
//     category: "",
//     rate: 0,
//     icon: "",
//     image: "",
//     branches: [],
//     isFavorite: {}
//   },
//   alert: "" 
// };

// const businessSlice = createSlice({
//   name: "business",
//   initialState,
//   reducers: {
//     getAllBusinesses: (state, action: PayloadAction<BusinessType[]>) => {
//       state.allBusinesses = action.payload;
//     },
//     addBusiness: (state, action: PayloadAction<BusinessType>) => {
//       state.businesses.push(action.payload);
//     },
//     updateBusinessFavorite: (state, action: PayloadAction<{ userId: string; businessId: string; isFavorite: boolean }>) => {
//       const { userId, businessId, isFavorite } = action.payload;
//       const business = state.businesses.find(b => b._id === businessId) || state.businessDetails;
//       if (business) {
//         business.isFavorite[userId] = isFavorite;
//       }
//     },
//     showAlert: (state, action: PayloadAction<string>) => {
//       state.alert = action.payload;
//     },
 
 
//     addRate: (state, action: PayloadAction<{ businessId: string; rate: number }>) => {
//       const { businessId, rate } = action.payload;
//       const businessToUpdate = state.businesses.find((business) => business._id === businessId);
//       if (businessToUpdate) {
//         businessToUpdate.rate = rate;
//       }
//     },
//     getBusinessDetails: (state, action: PayloadAction<BusinessType>) => {
//       // Directly set the businessDetails state to the payload
//       state.businessDetails = action.payload;
//     },
//     deleteBusiness: (state, action: PayloadAction<string>) => {
//       const businessIdToDelete = action.payload;
//       state.businesses = state.businesses.filter((business) => business._id !== businessIdToDelete);
//     },
 
//   },
// });

//  export const businessActions = businessSlice.actions;

// export default businessSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BusinessType } from "../../types/businessTypes";

type InitialType = {
  businesses: BusinessType[];
  allBusinesses: BusinessType[];
  businessDetails: BusinessType | null; // Changed to null initially
  alert: string; // Moved alert here to handle all UI related messages
  loading: boolean; // New loading state
  error: string | null; // New error state
};

const initialState: InitialType = {
  businesses: [],
  allBusinesses: [],
  businessDetails: null, // Changed to null to signify no data initially
  alert: "",
  loading: false, // Initial loading state
  error: null, // Initial error state
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    getAllBusinesses: (state, action: PayloadAction<BusinessType[]>) => {
      state.allBusinesses = action.payload;
    },
    addBusiness: (state, action: PayloadAction<BusinessType>) => {
      state.businesses.push(action.payload);
    },
    updateBusinessFavorite: (state, action: PayloadAction<{ userId: string; businessId: string; isFavorite: boolean }>) => {
      const { userId, businessId, isFavorite } = action.payload;
      const business = state.businesses.find(b => b._id === businessId) || state.businessDetails;
      if (business) {
        business.isFavorite[userId] = isFavorite;
      }
    },
    showAlert: (state, action: PayloadAction<string>) => {
      state.alert = action.payload;
    },
    addRate: (state, action: PayloadAction<{ businessId: string; rate: number }>) => {
      const { businessId, rate } = action.payload;
      const businessToUpdate = state.businesses.find((business) => business._id === businessId);
      if (businessToUpdate) {
        businessToUpdate.rate = rate;
      }
    },
    getBusinessDetails: (state, action: PayloadAction<BusinessType>) => {
      // Set businessDetails to the payload
      state.businessDetails = action.payload;
      state.loading = false; // Set loading to false when data is received
      state.error = null; // Clear any previous errors
    },
    clearBusinessDetails: (state) => {
      state.businessDetails = null; // Clear business details
      state.loading = true; // Set loading to true when a new fetch starts
      state.error = null; // Clear any previous errors
    },
    fetchBusinessDetailsError: (state, action: PayloadAction<string>) => {
      state.error = action.payload; // Set error message
      state.loading = false; // Set loading to false when there's an error
    },
    setBusinessLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload; // Action to set loading state
    },
    deleteBusiness: (state, action: PayloadAction<string>) => {
      const businessIdToDelete = action.payload;
      state.businesses = state.businesses.filter((business) => business._id !== businessIdToDelete);
    },
  },
});

export const businessActions = businessSlice.actions;

export default businessSlice.reducer;

