import * as React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import UpdateBusiness from "../updateBusiness/UpdateBusiness";
import { BusinessType } from "../../../types/businessTypes";
import { updateBusinessModal } from "../../../constants/styleConstants";

type PropType = {
  open: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  businessToModal: BusinessType | undefined;
};

export default function UpdateBusinessModal({
  open,
  setOpenModal,
  businessToModal,
}: PropType) {
  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={updateBusinessModal}>
            <UpdateBusiness
              businessToUpdate={businessToModal}
              setOpenModal={setOpenModal}
            />
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}
