// src/constants/textConstants.ts

import TermsOfUse from "../pages/legal/TermsOfUse";


export const INTRO_WELCOME = "Share Your Sweden: Reviews That Enhance Everyday Life"

// export const INTRO_TEXT = "Explore our community-driven review platform for sharing and reviewing everything that matters in Sweden. From grocery stores to SL services, post your experiences, share photos, and stay informed. Together, we enhance daily life across our diverse country.";
export const INTRO_TEXT = "Welcome to our community review platform, where your experiences shape Sweden's future. Share reviews of everyday goods and services, from grocery stores to SL. Together, we can influence businesses to improve.\nJoin us and make a difference!";


export const REVIEW_INSTRUCTION = "Please share your recent experiences to help others make informed decisions.";

export const HIGH_QUALITY_PRODUCT_TEXT = "Discover the latest hot topics and most talked-about information.";

export const TOP_COMMENTS_TEXT = "Explore the top-rated comments from our community!";

export const WEEKLY_OFFERS_TEXT = "Don’t miss out on these weekly offers from your favorite stores.";

export const ENGAGING_CUSTOMER_TEXT = "Engage with the community and make your shopping experience better.";


// -------------------------------------------OUR MISSION
export const OUR_MISSION_TITLE = "Our Mission";
export const WHY_TRUST_US_TITLE = "Why Trust Us" 
export const WHY_TRUST_US = "Your well-being is invaluable. We're committed to guiding you through a world of wholesome and reliable choices, inspired by community wisdom.";
export const WHY_TRUST_US_TEXT1="New Users Every Day"
export const WHY_TRUST_US_TEXT2="Reviews Posted"
export const WHY_TRUST_US_TEXT3="Top Services"

export const OUR_MISSION_SUB_TITLE  = "At ShoutItOut, we aim to empower savvy consumers by providing a dedicated space to exchange honest feedback on everyday essentials.";
export const OUR_MISSION_SUB_P1_TITLE  = "Real Feedback";
export const OUR_MISSION_SUB_P1  = "Assess and discuss the quality of services with a community dedicated to transparency and authenticity.";
export const OUR_MISSION_SUB_P2_TITLE  = "Daily Insights";
export const OUR_MISSION_SUB_P2  = "Gain daily insights from our community on the latest services and experiences, helping you make informed decisions every day.";
export const OUR_MISSION_SUB_P3_TITLE  = "Community Picks";
export const OUR_MISSION_SUB_P3  = "Discover top-rated services vetted by our vibrant community, ensuring you receive only the best in your daily life."
export const DISCOVER_MORE_TITLE  = "Discover More";
export const DISCOVER_MORE_SUBTITLE = "Delve deeper into our features and see how ShoutItOut can transform your everyday experiences into a journey of gourmet discoveries.";
export const DISCOVER_EASY_SEARCH = "Quickly find reviews for the services, goods, or experiences you're considering, ensuring you make informed decisions.";

export const DISCOVER_USER = "Contribute and earn a spot as a top reviewer, gaining trust and influence within the community.";

export const DISCOVER_PHOTOS = "Browse through photos shared by our community to get a visual insight into the services, goods, or experiences you're interested in.";

export const ASSISTANCE_TITLE  = "Need Assistance?";
export const ASSISTANCE_SUBTITLE  = "Uncover answers to common inquiries and learn how to make the most of our platform with these helpful pointers.";
export const ASSISTANCE_TEXT1  = "To leave a review for a product or service, simply create an account, select the store or business, and share your feedback along with photos. Your insights will assist others in making informed decisions based on your firsthand experience.";
export const ASSISTANCE_TEXT2  = "Yes, you can update or delete your review anytime to reflect changes in your opinion or new experiences. Keeping your reviews up-to-date helps maintain accurate and helpful information for the community.";
export const ASSISTANCE_TEXT3  = "You can easily find reviews for a specific product by using the search bar on our BUSINESS page. Simply enter the product name, and you'll see a list of reviews and ratings from other users. This helps you quickly gather insights before making a purchase.";
export const ASSISTANCE_TEXT4  = "Our ratings are determined by averaging scores provided by users. Each review contributes to the overall rating of a business or store. Services with higher ratings usually receive consistent positive feedback from the community. Users rate businesses/stores from a list and also rate posts from other users.";

export const BUSINESS_PAGE_WELCOME="Welcome to the Business Directory, your ultimate resource for finding and reviewing the finest businesses in town. Whether you are looking for top-rated supermarkets, local markets, specialty food stores, or any other services, we've got you covered.All business and store pictures/icons are for informational purposes to aid recognition by users."
export const BUSINESS_DETAILS_WELCOME="Share your experience with other users by posting comments and pictures. Whether you bought a unique food item or had an exceptional service, your input helps others make informed decisions."

export const BUSINESS_DETAILS_LOGIN='Please log in in order to leave a comment'
export const BUSINESS_DETAILS_LOGIN_SE = 'Logga in för att lämna en kommentar';

export const FAVORITE_PAGE_WELCOME ="Welcome to your Favorites! Here, you'll find all the stores you've marked as favorites. Click on any store to view its details, leave a comment, or see what others are saying. Enjoy exploring your favorite spots!" 
export const TOPREVIEW_BUTTON2="TOP RATED"
export const TOPREVIEW_BUTTON1="MOST REPLIED"

export const CHOOSE_STORE_TEXT_EN = "Choose the store";


//---------------ABOUT------------------------------------


// export const ABOUT_TITLE = "Welcome to ShoutItOut";
// export const ABOUT_INTRO = "  Your community review platform dedicated to enhancing everyday shopping experiences in Sweden. We understand the frustration of purchasing low-quality goods and the hassle of returning them. Often, the effort of driving back to the store and spending time for a small refund is just not worth it. As a result, many people end up wasting money and throwing away spoiled food.";

// export const ABOUT_GOAL_TITLE = "Our Mission";
// export const ABOUT_GOAL = "Our mission is to empower consumers by fostering a community of transparency and trust. We believe that collective wisdom can lead to better choices and improved quality of life. Whether you're reviewing a supermarket, a local market, or any service, your insights are invaluable.";

// export const ABOUT_TRUST_TITLE = "Why Trust Us";
// export const ABOUT_TRUST = "We prioritize your well-being and satisfaction. Our platform is built on genuine user feedback, ensuring that the information you find here is reliable and helpful. With new reviews being posted daily, you can trust that our content is current and community-driven.";

// export const ABOUT_EXPERIENCES_TITLE = "Real Experiences";
// export const ABOUT_EXPERIENCES = "Share your daily shopping experiences to help others avoid the pitfalls of low-quality products and services. Whether it’s about a delightful discovery or a disappointing purchase, every review counts in creating a comprehensive resource for our community.";

// export const ABOUT_COMMUNITY_TITLE = "Community Benefits";
// export const ABOUT_COMMUNITY = "By actively participating, you contribute to a collective effort to improve consumer experiences. Discover top-rated services and products vetted by fellow community members, ensuring you spend your money wisely and enjoy quality in every purchase.";

// export const ABOUT_ENGAGE_TITLE = "Engage and Influence";
// export const ABOUT_ENGAGE = "Join us and be a part of a movement that can influence businesses to improve their offerings. Your reviews not only help other consumers but also encourage stores and service providers to maintain high standards. Together, we can create a positive impact on the market.";

// export const ABOUT_THANKS = "Thank you for being a part of ShoutItOut. Your voice matters, and together, we can build a better shopping experience for everyone in Sweden.";

export const ABOUT_TITLE = "Welcome to ShoutItOut";
export const ABOUT_INTRO = "Your community review platform dedicated to enhancing daily shopping experiences in Sweden. We understand the frustration of buying low-quality goods and the hassle of returning them. Often, the effort to drive back to the store for a small refund isn't worth it. Many end up wasting money and throwing away spoiled food.";

export const ABOUT_GOAL_TITLE = "Our Mission";
export const ABOUT_GOAL = "Our mission is to empower consumers by creating a community of transparency and trust. We believe collective wisdom leads to better choices and improved quality of life. Whether reviewing a supermarket, local market, or service, your insights are invaluable.";

export const ABOUT_TRUST_TITLE = "Why Trust Us";
export const ABOUT_TRUST = "We prioritize your well-being and satisfaction. Our platform is built on genuine user feedback, ensuring the information you find is reliable and helpful. With new reviews posted daily, our content is always current and community-driven.";

export const ABOUT_EXPERIENCES_TITLE = "Real Experiences";
export const ABOUT_EXPERIENCES = "Share your daily shopping experiences to help others avoid low-quality products and services. Whether it’s about a delightful discovery or a disappointing purchase, every review is essential in creating a comprehensive resource for our community.";

export const ABOUT_COMMUNITY_TITLE = "Community Benefits";
export const ABOUT_COMMUNITY = "By actively participating, you contribute to a collective effort to improve consumer experiences. Discover top-rated services and products reviewed by other community members, ensuring you spend your money wisely and enjoy quality in every purchase.";

export const ABOUT_ENGAGE_TITLE = "Engage and Influence";
export const ABOUT_ENGAGE = "Join us and be part of a movement that can influence businesses to improve their offerings. Your reviews help not only other consumers but also encourage stores and service providers to maintain high standards. Together, we can create a positive impact on the market.";

export const ABOUT_THANKS = "Thank you for being a part of ShoutItOut. Your voice matters, and together, we can create a better shopping experience for everyone in Sweden.";


//swedish ABOUT------------------------------------

export const ABOUT_TITLE_SE = "Välkommen till ShoutItOut";
export const ABOUT_INTRO_SE = "Din plattform för communityrecensioner som hjälper dig att förbättra din dagliga shoppingupplevelse i Sverige. Vi förstår frustrationen med att köpa lågkvalitativa produkter och den krångeliga processen att returnera dem. Många konsumenter slösar pengar på mat av låg kvalitet som kastas bort. Här kan du läsa och dela recensioner för att göra smartare köp.";

export const ABOUT_GOAL_TITLE_SE = "Vårt Uppdrag";
export const ABOUT_GOAL_SE = "Vårt uppdrag är att stärka konsumenter genom att skapa ett transparent community där ärliga recensioner leder till bättre beslut och högre livskvalitet. Recensera allt från stormarknader som ICA och COOP till lokala butiker – dina insikter gör skillnad.";

export const ABOUT_TRUST_TITLE_SE = "Varför Lita På Oss";
export const ABOUT_TRUST_SE = "Vi sätter din tillfredsställelse främst. Vår plattform bygger på genuina användarrecensioner och kontinuerlig uppdatering av innehåll. Detta säkerställer att informationen är pålitlig, aktuell och relevant för dina dagliga behov.";

export const ABOUT_EXPERIENCES_TITLE_SE = "Dela Dina Erfarenheter";
export const ABOUT_EXPERIENCES_SE = "Dela dina upplevelser av matbutiker, kampanjer och produkter för att hjälpa andra att undvika dåliga köp. Varje ärlig recension bidrar till att skapa en omfattande guide för smartare konsumentbeslut.";

export const ABOUT_COMMUNITY_TITLE_SE = "Starka Community Fördelar";
export const ABOUT_COMMUNITY_SE = "Genom att delta aktivt hjälper du till att höja standarden för konsumentupplevelser i Sverige. Se vad andra tycker om ICA, Willys, Lidl och fler. Upptäck de bäst recenserade produkterna och tjänsterna som garanterar dig kvalitet i varje köp.";

export const ABOUT_ENGAGE_TITLE_SE = "Engagera Dig och Gör Skillnad";
export const ABOUT_ENGAGE_SE = "Bli en del av vårt community och påverka butiker och tjänsteleverantörer att förbättra sina erbjudanden. Ditt engagemang hjälper inte bara andra konsumenter, utan skapar även en positiv förändring på marknaden.";

export const ABOUT_THANKS_SE = "Tack för att du bidrar till ShoutItOut. Din åsikt gör skillnad, och tillsammans skapar vi en bättre shoppingupplevelse för alla i Sverige.";


//-------------------------------------------------



// src/constants/textConstants.ts

export const INTRO_WELCOME_SE = "Dela med dig av Sverige: Recensioner som förbättrar vardagen";

// export const INTRO_TEXT_SE = "Utforska vår community-drivna plattform för att dela och recensera allt som är viktigt i Sverige. Från mataffärer till SL-tjänster, dela dina upplevelser, foton och håll dig informerad. Tillsammans förbättrar vi vardagen i vårt mångsidiga land.";
export const INTRO_TEXT_SE = "Välkommen till vår community-drivna recensionsplattform, där dina erfarenheter formar Sveriges framtid. Dela recensioner av vardagliga varor och tjänster, från mataffärer till SL. Tillsammans kan vi påverka företag att förbättras. Gå med och gör skillnad!";

export const REVIEW_INSTRUCTION_SE = "Vänligen dela dina senaste erfarenheter för att hjälpa andra att fatta informerade beslut.";

export const HIGH_QUALITY_PRODUCT_TEXT_SE = "Upptäck de senaste heta ämnena och mest diskuterade informationen.";

export const TOP_COMMENTS_TEXT_SE = "Utforska de topprankade kommentarerna från vår community!";

export const WEEKLY_OFFERS_TEXT_SE = "Missa inte dessa veckovisa erbjudanden från dina favoritbutiker.";

export const ENGAGING_CUSTOMER_TEXT_SE = "Engagera dig i communityn och gör din shoppingupplevelse bättre.";


// -------------------------------------------OUR MISSION
export const OUR_MISSION_TITLE_SE = "Vårt Uppdrag";
export const WHY_TRUST_US_TITLE_SE = "Varför Lita På Oss";
export const WHY_TRUST_US_SE = "Ditt välbefinnande är ovärderligt. Vi är engagerade i att guida dig genom en värld av hälsosamma och pålitliga val, inspirerade av gemenskapens visdom.";
export const WHY_TRUST_US_TEXT1_SE = "Nya användare varje dag";
export const WHY_TRUST_US_TEXT2_SE = "Recensioner publicerade";
export const WHY_TRUST_US_TEXT3_SE = "Topptjänster";


export const OUR_MISSION_SUB_TITLE_SE = "På ShoutItOut strävar vi efter att stärka kloka konsumenter genom att erbjuda en dedikerad plats för att utbyta ärlig feedback om vardagliga nödvändigheter.";
export const OUR_MISSION_SUB_P1_TITLE_SE = "Ärlig Feedback";
export const OUR_MISSION_SUB_P1_SE = "Bedöm och diskutera kvaliteten på livsmedel med en community dedikerad till transparens och äkthet.";
export const OUR_MISSION_SUB_P2_TITLE_SE = "Dagliga Insikter";
export const OUR_MISSION_SUB_P2_SE = "Få dagliga insikter från vår gemenskap om de senaste tjänsterna och upplevelserna, vilket hjälper dig att fatta informerade beslut varje dag.";
export const OUR_MISSION_SUB_P3_TITLE_SE = "Communityns Val";
export const OUR_MISSION_SUB_P3_SE = "Upptäck högt betygsatta tjänster granskade av vår livliga gemenskap, vilket säkerställer att du får bara det bästa i din vardag.";
export const DISCOVER_MORE_TITLE_SE = "Upptäck Mer";
export const DISCOVER_MORE_SUBTITLE_SE = "Gräv djupare i våra funktioner och se hur ShoutItOut kan förvandla dina vardagliga upplevelser till en resa av gourmet-upptäckter.";
export const DISCOVER_EASY_SEARCH_SE = "Hitta snabbt recensioner för de tjänster, varor eller upplevelser du överväger, vilket säkerställer att du fattar välgrundade beslut.";
export const DISCOVER_USER_SE = "Bidra och tjäna en plats som en topprecensent, vilket ger förtroende och inflytande inom gemenskapen.";
export const DISCOVER_PHOTOS_SE = "Bläddra bland foton delade av vår gemenskap för visuell inblick i de tjänster, varor eller upplevelser du är intresserad av.";
export const ASSISTANCE_TITLE_SE = "Behöver du Hjälp?";
export const ASSISTANCE_SUBTITLE_SE = "Upptäck svar på vanliga frågor och lär dig hur du bäst använder vår plattform med dessa hjälpsamma tips.";
export const ASSISTANCE_TEXT1_SE = "För att recensera en produkt eller tjänst, skapa ett konto, välj affär eller företag, och dela din åsikt tillsammans med bilder. Dina insikter kommer att hjälpa andra att fatta informerade beslut baserade på din erfarenhet.";
export const ASSISTANCE_TEXT2_SE = "Ja, du kan uppdatera eller ta bort din recension när som helst för att återspegla förändringar i din åsikt eller nya upplevelser. Att hålla dina recensioner uppdaterade hjälper till att behålla korrekt och hjälpsam information för communityn.";
export const ASSISTANCE_TEXT3_SE = "Du kan enkelt hitta recensioner för en specifik produkt genom att använda sökfältet på vår BUTIKS-sida. Ange bara produktnamnet, så ser du en lista med recensioner och betyg från andra användare. Detta hjälper dig att snabbt samla in information innan du gör ett köp.";
export const ASSISTANCE_TEXT4_SE = "Våra betyg beräknas utifrån genomsnittspoängen som användare ger. Varje recension bidrar till den övergripande bedömningen av ett företag eller en butik. Tjänster med högre betyg är vanligtvis de som konsekvent får positiv respons från gemenskapen. Användare bedömer företag/butiker från en lista och bedömer även inlägg från andra användare.";

export const BUSINESS_PAGE_WELCOME_SE = "Välkommen till Företagsregistret, din ultimata resurs för att hitta och recensera de finaste företagen i stan. Oavsett om du letar efter högt betygsatta stormarknader, lokala marknader, specialbutiker för mat eller andra tjänster, så har vi dig täckt. Alla bilder/ikoner på företag och butiker är endast för informativa ändamål för att underlätta igenkänning av användare.";
export const BUSINESS_DETAILS_WELCOME_SE = "Dela din upplevelse med andra användare genom att publicera kommentarer och bilder. Oavsett om du köpt en unik matvara eller fått en exceptionell service, hjälper ditt bidrag andra att fatta välgrundade beslut.";
export const FAVORITE_PAGE_WELCOME_SE = "Välkommen till dina Favoriter! Här hittar du alla butiker du har markerat som favoriter. Klicka på vilken butik som helst för att se dess detaljer, lämna en kommentar eller se vad andra säger. Utforska dina favoritställen med glädje!";


export const NAVBAR_HOME_EN = "Home";
export const NAVBAR_HOME_SE = "Hem";

export const NAVBAR_BUSINESS_EN = "Business";
export const NAVBAR_BUSINESS_SE = "Företag";

export const NAVBAR_ADD_EN = "Add New";
export const NAVBAR_ADD_SE = "Lägg till";

export const NAVBAR_CONTACT_EN = "Contact";
export const NAVBAR_CONTACT_SE = "Kontakta oss";


export const NAVBAR_FAVORITE_EN = "Favorite";
export const NAVBAR_FAVORITE_SE = "Favorit"; 

export const NAVBAR_SIGN_IN_EN = "Log In";
export const NAVBAR_SIGN_IN_SE = "Logga In";

export const NAVBAR_SIGN_UP_EN = "Sign Up";
export const NAVBAR_SIGN_UP_SE = "Registrera dig";

export const TOPREVIEW_BUTTON2_SE = "HÖGST BETYG";
export const TOPREVIEW_BUTTON1_SE = "MEST BESVARAD";

export const CHOOSE_STORE_TEXT_SE = "Välj butik";


//-------------------------------------TermsOfUse
export const TERMS_ACCEPTANCE_OF_TERMS = `
By using this website, you agree to these Terms and Conditions. If you do not agree, do not use the website.
`;

export const TERMS_USER_CONDUCT_PART1 = `
You agree to use the website lawfully. You are prohibited from posting content that is:
`;
export const TERMS_USER_CONDUCT_PART2 = `
• Unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, pornographic, invasive of privacy, hateful, or racially/ethnically objectionable.
• Encouraging illegal conduct or violating any laws.
• Involving junk mail, chain letters, spamming, or political campaigns.
• Containing software viruses or harmful codes.
`;

export const TERMS_USER_GENERATED_CONTENT = `
By submitting content (photos, videos, comments), you grant us a non-exclusive, royalty-free license to use, reproduce, and display such content. You warrant that you own or control all rights to the content and that it does not violate these terms.
`;

export const TERMS_PROHIBITED_CONTENT_PART1 = `
Content that is prohibited includes:
`;
export const TERMS_PROHIBITED_CONTENT_PART2 = `
• Pornography or sexually explicit material. 
• Hate speech or violence.
• Political campaigning.
• Infringement of intellectual property rights.
• Private or confidential information.
`;

export const TERMS_MONITORING_AND_ENFORCEMENT_PART1 = `
We may:
`;
export const TERMS_MONITORING_AND_ENFORCEMENT_PART2 = `
• Monitor and review user content.
• Remove or refuse content at our discretion.
• Disclose your identity to third parties if your content violates their rights.
• Suspend or terminate your access for violating these terms.
`;

export const TERMS_INTELLECTUAL_PROPERTY_RIGHTS = `
All website content is owned by us or our content suppliers and is protected by intellectual property laws. Unauthorized use is prohibited.
`;

export const TERMS_PRIVACY = `
Use of the website is subject to our Privacy Policy, which explains our data collection practices.
`;

export const TERMS_DISCLAIMER_OF_WARRANTIES = `
The website is provided "as is" without warranties of any kind. We disclaim all warranties, express or implied, to the fullest extent permissible by law.
`;

export const TERMS_LIMITATION_OF_LIABILITY = `
We are not liable in any way for any damages arising from your use of this website.
`;

export const TERMS_ACCEPTANCE_OF_TERMS_SV = `
Genom att använda denna webbplats godkänner du dessa Villkor. Om du inte godkänner, använd inte webbplatsen.
`;

export const TERMS_USER_CONDUCT_PART1_SV = `
Du samtycker till att använda webbplatsen lagligt. Du är förbjuden att publicera innehåll som är:
`;
export const TERMS_USER_CONDUCT_PART2_SV = `
• Olagligt, skadligt, hotande, kränkande, trakasserande, ärekränkande, vulgärt, obscent, pornografiskt, integritetskränkande, hatiskt eller rasistiskt/etniskt stötande.
• Uppmuntrar olagligt beteende eller bryter mot några lagar.
• Innehåller skräppost, kedjebrev, spam eller politiska kampanjer.
• Innehåller programvaruvirus eller skadlig kod.
`;

export const TERMS_USER_GENERATED_CONTENT_SV = `
Genom att skicka in innehåll (foton, videor, kommentarer) ger du oss en icke-exklusiv, royaltyfri licens att använda, reproducera och visa sådant innehåll. Du garanterar att du äger eller kontrollerar alla rättigheter till innehållet och att det inte bryter mot dessa villkor.
`;

export const TERMS_PROHIBITED_CONTENT_PART1_SV = `
Innehåll som är förbjudet inkluderar:
`;
export const TERMS_PROHIBITED_CONTENT_PART2_SV = `
• Pornografi eller sexuellt explicit material.
• Hatpropaganda eller våld.
• Politiska kampanjer.
• Intrång i immateriella rättigheter.
• Privat eller konfidentiell information.
`;

export const TERMS_MONITORING_AND_ENFORCEMENT_PART1_SV = `
Vi kan:
`;
export const TERMS_MONITORING_AND_ENFORCEMENT_PART2_SV = `
• Övervaka och granska användarinnehåll.
• Ta bort eller vägra innehåll efter eget gottfinnande.
• Avslöja din identitet för tredje parter om ditt innehåll bryter mot deras rättigheter.
• Stänga av eller avsluta din åtkomst för att bryta mot dessa villkor.
`;

export const TERMS_INTELLECTUAL_PROPERTY_RIGHTS_SV = `
Allt webbplatsinnehåll ägs av oss eller våra innehållsleverantörer och skyddas av immateriella rättigheter. Obehörig användning är förbjuden.
`;

export const TERMS_PRIVACY_SV = `
Användning av webbplatsen är föremål för vår Sekretesspolicy, som förklarar våra datainsamlingsmetoder.
`;

export const TERMS_DISCLAIMER_OF_WARRANTIES_SV = `
Webbplatsen tillhandahålls "som den är" utan garantier av något slag. Vi friskriver oss från alla garantier, uttryckliga eller underförstådda, i den utsträckning som lagen tillåter.
`;

export const TERMS_LIMITATION_OF_LIABILITY_SV = `
Vi är inte på något sätt ansvariga för skador som uppstår från din användning av denna webbplats.
`;

//-----------PRIVACY POLICY AND COOKEYS 

// Constants file (e.g., textConstants.js)

export const PRIVACY_POLICY_LAST_UPDATED = "Last Updated: 2nd of June 2024";
export const PRIVACY_POLICY_LAST_UPDATED_SV = "Senast uppdaterad: 2 juni 2024";

export const PRIVACY_POLICY_INTRODUCTION = `Welcome to ShoutItOut ("we", "our", "us"). We are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, and share information about you when you visit our website and use our services.`;
export const PRIVACY_POLICY_INTRODUCTION_SV = `Välkommen till ShoutItOut ("vi", "vår", "oss"). Vi är engagerade i att skydda och respektera din integritet. Denna integritetspolicy förklarar hur vi samlar in, använder och delar information om dig när du besöker vår webbplats och använder våra tjänster.`;

export const PRIVACY_POLICY_INFORMATION_WE_COLLECT = "We collect and process the following data about you:";
export const PRIVACY_POLICY_INFORMATION_WE_COLLECT_SV = "Vi samlar in och behandlar följande data om dig:";

export const PRIVACY_POLICY_PERSONAL_DATA = "Personal Data: When you register on our website, we collect your email address and other information you provide.";
export const PRIVACY_POLICY_PERSONAL_DATA_SV = "Personuppgifter: När du registrerar dig på vår webbplats samlar vi in din e-postadress och annan information du tillhandahåller.";

export const PRIVACY_POLICY_HOW_WE_USE_YOUR_INFORMATION = "We use the information we collect in the following ways:";
export const PRIVACY_POLICY_HOW_WE_USE_YOUR_INFORMATION_SV = "Vi använder informationen vi samlar in på följande sätt:";

export const PRIVACY_POLICY_PROVIDE_AND_MAINTAIN_SERVICE = "To provide and maintain our service: Keeping you logged in and providing access to your account.";
export const PRIVACY_POLICY_PROVIDE_AND_MAINTAIN_SERVICE_SV = "För att tillhandahålla och underhålla vår tjänst: Hålla dig inloggad och ge tillgång till ditt konto.";

export const PRIVACY_POLICY_COMMUNICATE_WITH_YOU = "To communicate with you: Sending updates and notifications related to your account.";
export const PRIVACY_POLICY_COMMUNICATE_WITH_YOU_SV = "För att kommunicera med dig: Skicka uppdateringar och aviseringar relaterade till ditt konto.";

export const PRIVACY_POLICY_COOKIES = `Cookies:
- What Are Cookies?: Cookies are small text files that are placed on your device to help us provide a better user experience.
- Necessary Cookies: We use necessary cookies to keep you logged in and ensure the proper functioning of our services. These cookies do not store any personal data.
- Your Consent: By using our website, you consent to the use of necessary cookies as described in this policy.`;
export const PRIVACY_POLICY_COOKIES_SV = `Cookies:
- Vad är cookies?: Cookies är små textfiler som placeras på din enhet för att hjälpa oss att ge en bättre användarupplevelse.
- Nödvändiga cookies: Vi använder nödvändiga cookies för att hålla dig inloggad och säkerställa att våra tjänster fungerar korrekt. Dessa cookies lagrar inga personuppgifter.
- Ditt samtycke: Genom att använda vår webbplats samtycker du till användningen av nödvändiga cookies enligt beskrivningen i denna policy.`;

export const PRIVACY_POLICY_LEGAL_BASIS_FOR_PROCESSING = "We process your personal data under the following legal bases:";
export const PRIVACY_POLICY_LEGAL_BASIS_FOR_PROCESSING_SV = "Vi behandlar dina personuppgifter enligt följande rättsliga grunder:";

export const PRIVACY_POLICY_CONSENT = "Consent: By registering and using our services, you consent to the processing of your personal data as described in this policy.";
export const PRIVACY_POLICY_CONSENT_SV = "Samtycke: Genom att registrera dig och använda våra tjänster samtycker du till behandlingen av dina personuppgifter enligt beskrivningen i denna policy.";

export const PRIVACY_POLICY_DATA_STORAGE_AND_SECURITY = "We store your personal data securely in the cloud. We implement appropriate technical and organizational measures to protect your data from unauthorized access, loss, or destruction.";
export const PRIVACY_POLICY_DATA_STORAGE_AND_SECURITY_SV = "Vi lagrar dina personuppgifter säkert i molnet. Vi genomför lämpliga tekniska och organisatoriska åtgärder för att skydda dina data från obehörig åtkomst, förlust eller förstörelse.";

export const PRIVACY_POLICY_DATA_RETENTION = "We retain your personal data for as long as necessary to fulfill the purposes outlined in this policy or as required by law.";
export const PRIVACY_POLICY_DATA_RETENTION_SV = "Vi behåller dina personuppgifter så länge det är nödvändigt för att uppfylla ändamålen som anges i denna policy eller enligt lag.";

export const PRIVACY_POLICY_YOUR_RIGHTS = "Under the GDPR, you have the following rights:";
export const PRIVACY_POLICY_YOUR_RIGHTS_SV = "Enligt GDPR har du följande rättigheter:";

export const PRIVACY_POLICY_ACCESS = "Access: Request access to your personal data.";
export const PRIVACY_POLICY_ACCESS_SV = "Tillgång: Begär tillgång till dina personuppgifter.";

export const PRIVACY_POLICY_RECTIFICATION = "Rectification: Request correction of inaccurate or incomplete data.";
export const PRIVACY_POLICY_RECTIFICATION_SV = "Rättelse: Begär rättelse av felaktiga eller ofullständiga uppgifter.";

export const PRIVACY_POLICY_ERASURE = "Erasure: Request deletion of your personal data.";
export const PRIVACY_POLICY_ERASURE_SV = "Radering: Begär radering av dina personuppgifter.";

export const PRIVACY_POLICY_RESTRICTION = "Restriction: Request restriction of processing of your personal data.";
export const PRIVACY_POLICY_RESTRICTION_SV = "Begränsning: Begär begränsning av behandlingen av dina personuppgifter.";

export const PRIVACY_POLICY_DATA_PORTABILITY = "Data Portability: Request transfer of your data to another service provider.";
export const PRIVACY_POLICY_DATA_PORTABILITY_SV = "Dataportabilitet: Begär överföring av dina data till en annan tjänsteleverantör.";

export const PRIVACY_POLICY_OBJECTION = "Objection: Object to the processing of your personal data.";
export const PRIVACY_POLICY_OBJECTION_SV = "Invändning: Invänd mot behandlingen av dina personuppgifter.";

export const PRIVACY_POLICY_EXERCISE_RIGHTS = "To exercise any of these rights, please contact us at info@shoutitout.se";
export const PRIVACY_POLICY_EXERCISE_RIGHTS_SV = "För att utöva någon av dessa rättigheter, vänligen kontakta oss på info@shoutitout";

export const PRIVACY_POLICY_THIRD_PARTY_SERVICES = "We may use third-party services for cloud storage and email communications. These providers are compliant with GDPR and ensure the security and confidentiality of your data.";
export const PRIVACY_POLICY_THIRD_PARTY_SERVICES_SV = "Vi kan använda tredjepartstjänster för molnlagring och e-postkommunikation. Dessa leverantörer är GDPR-kompatibla och säkerställer säkerheten och konfidentialiteten för dina data.";

export const PRIVACY_POLICY_CHANGES_TO_POLICY = "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. You are advised to review this Privacy Policy periodically for any changes.";
export const PRIVACY_POLICY_CHANGES_TO_POLICY_SV = "Vi kan uppdatera denna integritetspolicy från tid till annan. Vi kommer att meddela dig om eventuella ändringar genom att publicera den nya policyn på vår webbplats. Du rekommenderas att granska denna integritetspolicy regelbundet för eventuella ändringar.";

export const PRIVACY_POLICY_CONTACT_US = "If you have any questions about this Privacy Policy, please contact us at: info@shoutitout.se";
export const PRIVACY_POLICY_CONTACT_US_SV = "Om du har några frågor om denna integritetspolicy, vänligen kontakta oss på: info@shoutitout.se";



//LOGIN USER
export const LOGIN_TITLE = 'Log In';
export const LOGIN_TITLE_SE = 'Logga In';

export const LOGIN_WITH_EMAIL = 'Log In with Email';
export const LOGIN_WITH_EMAIL_SE = 'Logga In med E-post';

export const LOGIN_WITH_GOOGLE = 'Log In with Google';
export const LOGIN_WITH_GOOGLE_SE = 'Logga In med Google';

export const NOT_A_MEMBER_YET = 'Not a member yet?';
export const NOT_A_MEMBER_YET_SE = 'Inte medlem än?';

export const SIGN_UP = 'Sign Up';
export const SIGN_UP_SE = 'Registrera Dig'; 

//------USER PROFILE TEXT

export const DELETE_ACCOUNT = ' Delete Account';