import { AppDispatch } from "../store";
import { url } from "../../App";

import { commentActions } from "./../slice/comment";
import axios from "axios";
import { fetchUser } from "./user";
import { CommentType } from "../../types/commentTypes";

 
export const fetchCommentsByUserId = (userId: string) => async (
  dispatch: AppDispatch 
) => {
  try {
    const response = await axios.get(`${url}/comment/user/${userId}`);
    const comments = response.data;

    if (comments.length === 0) {
      console.warn('No comments found for user ID:', userId); 
    }

    // Fetch user information for each comment
    const commentsWithUser = await Promise.all(
      comments.map(async (comment: CommentType) => {
        const user = fetchUser(comment.userId);
        return { ...comment, user };
      })
    );

    dispatch(commentActions.getCommentsByUserId(commentsWithUser));
    return commentsWithUser; // Return data for chaining promises if needed
  } catch (error) {
    console.error("Error fetching comments by user ID:", error);
    throw error; // Rethrow the error to handle it in the component or calling function
  } 
};



export const fetchRatesByCommentId = (commentId: string, userId?: string) => async (dispatch: AppDispatch) => {
  try {
    // Construct the URL, optionally including the userId as a query parameter
    const urlWithUserId = userId ? `${url}/comment/getRate/${commentId}?userId=${userId}` : `${url}/comment/getRate/${commentId}`;
    const response = await axios.get(urlWithUserId);

    // Assuming the backend response structure is { overallRates: RatingType[], userRate: number | null }
    const { overallRates, userRate } = response.data;

    // Dispatch actions to update the store based on the received data
    if (overallRates) {
      dispatch(commentActions.setRatesForComment({ commentId, rates: overallRates }));
    }

    // Return the data for further use if needed
    return { overallRates, userRate };
  } catch (error) {
    console.error("Error fetching rates by comment ID:", error);
    throw error;
  }
};


export const fetchCommentByBusinessId = (businessId: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${url}/comment/${businessId}`);
    const data = response.data; 
    dispatch(commentActions.getCommentByBusinessId(data));
    return data; // Return data for chaining promises if needed
  } catch (error) {
    console.error("Error fetching comments by business ID:", error);
    throw error; // Rethrow the error to handle it in the component or calling function
  }
}; 


export function fetchAllComments() { 
  return async (dispatch: AppDispatch) => {
    try {
      // const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/comment`);
      const data = await response.data;
      dispatch(commentActions.getAllComments (data));
    } catch (error) {
      console.error("Error fetching all comments:", error);    }
  };
}


export const fetchCommentsWithMostSubcomments = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(commentActions.setLoading(true)); // Set loading to true before making the request
    const response = await axios.get(`${url}/comment/most-subcomments`);
    const data = response.data;

    // Dispatch the data directly if it is structured like fetchCommentsWithMostRatings
    if (Array.isArray(data) && data.length > 0) {
      dispatch(commentActions.setCommentsWithMostSubcomments(data));
    } else {
      // Handle case where data is empty or not an array (if needed)
      console.warn("Received empty or invalid data:", data);
      // Optionally dispatch an action or log a message here
    }

    dispatch(commentActions.setLoading(false)); // Set loading to false after the request is successful
  } catch (error) {
    console.error("Error fetching comments with most subcomments:", error);
    dispatch(commentActions.setError("Failed to fetch comments with most subcomments"));
    dispatch(commentActions.setLoading(false)); // Ensure loading is set to false even if there's an error
    throw error; // Rethrow the error to handle it in the component or calling function
  }
};


export const fetchCommentsWithMostRatings = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(commentActions.setLoading(true)); // Set loading to true before making the request
    const response = await axios.get(`${url}/comment/most-rated`);
    const data = response.data;
    dispatch(commentActions.setCommentsWithMostRatings(data)); // Store these comments in 'commentsWithMostRatings'
    dispatch(commentActions.setLoading(false)); // Set loading to false after the request is successful
  } catch (error) {
    console.error("Error fetching comments with most ratings:", error);
    dispatch(commentActions.setError("Failed to fetch comments with most ratings"));
    dispatch(commentActions.setLoading(false)); // Ensure loading is set to false even if there's an error
    throw error; // Rethrow the error to handle it in the component or calling function
  }
};


export const fetchCommentById = (commentId: string | undefined) => async (dispatch: AppDispatch) => {
  try {
    // Make a request to the server to get the comment by ID
    const response = await axios.get(`${url}/comment/comment/${commentId}`);
    const data = response.data;

    // Dispatch the action to update the state with the fetched comment
    dispatch(commentActions.getCommentById(data));

    return data; // Return data for chaining promises if needed
  } catch (error) {
    console.error("Error fetching comment by ID:", error);
    throw error; // Rethrow the error to handle it in the component or calling function
  }
}; 


 

