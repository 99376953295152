import { AppDispatch } from "../store";
import { url } from "../../App";
import { subCommentActions } from "./../slice/subComment"; // Updated import
import axios from "axios";


export const fetchSubCommentByCommentId = (commentId: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${url}/subComment/get/${commentId}`);
    const data = response.data;

    // Check if the response data is defined and is an array
    if (Array.isArray(data) && data.length > 0) {
      dispatch(subCommentActions.getSubCommentByCommentId(data));
    } else {
      console.warn(`No subComments found for comment ID: ${commentId}`);
    }

    return data;
  } catch (error) {
    console.error('Error fetching subComments by comment ID:', error);
    return []; // Return an empty array or handle as per your application's requirement
  }
};

 
export function fetchAllSubComments() { 
  return async (dispatch: AppDispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/subComment`, { // Updated URL
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.data;
      dispatch(subCommentActions.getAllSubComments(data)); // Updated action
    } catch (error) {
      console.error("Error fetching all subComments:", error);
    }
  };
}


export const fetchRatesBySubCommentId = (subCommentId: string) => async (dispatch: AppDispatch) => {
  try {
    // Construct the URL to fetch overall rates for the subcomment
    const urls = `${url}/subComment/getRate/${subCommentId}`;
    const response = await axios.get(urls);

    // Assuming the backend response structure is { overallRates: RatingType[] }
    const { overallRates } = response.data;

    // Dispatch action to update the overall rates for the subcomment
    if (overallRates) {
      dispatch(subCommentActions.setRatesForSubComment({ subCommentId, rates: overallRates }));
    }

    // Return the data for further use if needed
    return overallRates;
  } catch (error) {
    console.error("Error fetching rates by sub-comment ID:", error);
    throw error;
  }
};