import { CSSProperties } from 'react';
import { SxProps,Theme } from '@mui/system';



export const introTextStyle: SxProps = {
  width: {
    xs:'90%',
    md:'90%',
    lg:'70%'
  },
  margin:'auto', 
  fontSize: {
    xs:'16px',
    md:'18px',
    lg:'20px'
  },
  fontStretch:'100%' ,
  color: '#333',
  lineHeight: {
    xs:'1.5',
    md:'1.7'
  },
  marginInline:'auto',
  marginBlock: '20px',
  textAlign:'justify',
fontWeight:'500',
fontFamily:'Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial',
wordSpacing:'2px',

};


export const cardMediaStyles: SxProps = {
  width: '100%',
  objectFit: 'contain',
  borderRadius: '4px',
  transition: 'transform 0.3s ease-in-out',
  '@media (max-width: 600px)': { // Small screens
    height: 'auto',
    width: '100%', 
  },
  '@media (min-width: 600px)': { // Medium screens
    height: 'auto',
    width: '100%',
  },
  '@media (min-width: 960px)': { // Large screens
    maxHeight: '300px',
    width: '100%',
  },
};




export const introWelcome: SxProps = {
  fontFamily: '"Libre Barcode 128 Text", system-ui',
  fontWeight: 400,
  fontStyle: 'normal',
    color: 'rgb(33, 37, 41)',
    fontSize:{
    xs:'14px',
    sm:'26px',
    md:'18px',
    lg:'56px' ,
    } ,
    flexWrap:'wrap',
    fontStretch:'100%' ,
    textAlign:'justify',
   width:'90%',
   margin:'auto',
  };

  export const allTytles: SxProps = {
    width: '90%',
  margin:'auto',
 flexWrap:'wrap',
  fontSize: '20px',
  fontStretch:'100%' ,
  color: '#333',
  lineHeight: '1.5',
  marginBlock: '20px',
  textAlign: 'center',
    fontWeight:'800',
    fontFamily: 'Libre Franklin, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
   marginBottom:'50px',
   marginTop:'50px',
  };
  export const discoverTitle: SxProps = {
    width: '90%',
  margin:'auto',
  fontSize: '20px',
  fontStretch:'100%' ,
  color: '#333',
  lineHeight: '1.5',
  marginBlock: '20px',
  textAlign: 'center',
    fontWeight:'800',
    fontFamily: 'Libre Franklin, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
   marginBottom:'50px',
   marginTop:'50px',
  };

  export const ourMission: SxProps = {
    backgroundAttachment: 'scroll',
    backgroundClip: 'border-box',
   backgroundColor: '#22223b',
    backgroundImage: 'none',
    backgroundOrigin: 'padding-box',
    backgroundPositionX: '0%', 
    backgroundPositionY: '0%',
    backgroundRepeat: 'repeat',
    backgroundSize: 'auto',
    borderBottomColor: 'rgb(200, 200, 200)',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    borderBottomStyle: 'solid',
    borderBottomWidth: '0px',
    borderLeftColor: 'rgb(200, 200, 200)',
    borderLeftStyle: 'solid',
    borderLeftWidth: '0px',
    borderRightColor: 'rgb(200, 200, 200)',
    borderRightStyle: 'solid',
    borderRightWidth: '0px',
    borderTopColor: 'rgb(200, 200, 200)',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderTopStyle: 'solid',
    borderTopWidth: '0px',
    boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.42)',
    boxSizing: 'border-box',
    color: 'rgb(200, 200, 200)',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
    fontFeatureSettings: 'normal',
    fontKerning: 'auto',
    fontOpticalSizing: 'auto',
    fontSize: '14px',
    fontStretch: '100%',
    fontWeight: 400,
    height: 'auto',
    lineHeight: '24px',
    overflowWrap: 'anywhere',
    textRendering: 'optimizeLegibility',
    textSizeAdjust: '100%',
    textAlign:'left',
    unicodeBidi: 'isolate',
    width: '90%',
    
    margin:'auto',
    marginTop:'30px',
    wordBreak: 'break-word',
    padding:'20px',
  };

  export const ourMissionTitle: SxProps = {
    width: {
      sx:'90%',
      lg:'80%',
    },
  margin:'auto',
  fontSize: '40px',
  fontStretch:'100%' ,
  color:'rgb(255, 255, 255)',
  lineHeight: '1.5',
  marginBlock: '20px',
  textAlign: 'center',
    fontWeight:'800',
    fontFamily: 'Libre Franklin, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
   marginBottom:'50px',
   marginTop:'50px',

  };

  export const whyTrustUs: CSSProperties = {
    alignItems: 'center',
    borderRightColor: 'rgb(200, 200, 200)',
    color: 'rgb(200, 200, 200)',
    display: 'flex',
    flexBasis: '0px',
    flexDirection:'row',
    flexGrow: 1,
    flexWrap:'wrap',
  
    justifyContent:'space-around',
    fontFamily: 'Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
    fontFeatureSettings: 'normal',
    fontKerning: 'auto',
    fontOpticalSizing: 'auto',
    fontSize: '16px',
    fontStretch: '100%',
    fontWeight: 400,
    padding: '16px',
    textSizeAdjust: '100%',
    wordBreak: 'break-word',
  
  }

  export const contentMission: CSSProperties = {
    textAlign:'left',
    paddingLeft:'50px',
    marginBlock:'20px',
    fontSize:'14px',
  }
  export const contentMissionIcons: CSSProperties = {
    textAlign:'left',
    marginInline:'10px',
    fontSize:30,
  }
  export const contentDiscoverIcons: CSSProperties = {
    textAlign:'center',
    marginBottom:'20px',

    fontSize:30,
  }

  export const contentAssistance: CSSProperties = {
    width:'90%',
    margin:'auto',
    borderRadius: '8px',
    fontFamily: 'Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
    color: '#343a40',
  };
  
  export const questionStyle: CSSProperties = {
    fontWeight: 'bold',
    marginBottom: '10px',
    fontFamily: 'Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
    fontSize: '14px',
    textAlign:'left'
  };
  
  export const answerStyle: CSSProperties = {
    marginBottom: '20px',
    fontSize: '14px',
    textAlign:'left'
  };

  // CARDS TOP REVIEW



export const btnTopReview: SxProps = {
  fontSize: "12px",
  color: "black", // Keep text color as black
  backgroundColor: "transparent", 
  boxShadow: "0 0 8px 2px rgba(105, 105, 105, 0.3)", // Transparent background for all buttons
  "&:hover": {
    transform: "scale(1.05)", // Slightly increase size on hover
  },
  "&:active": {
    transform: "scale(1.05)", // Retain size increase on click (active)
    backgroundColor: "transparent", // Ensure no background change
  },
  "&.Mui-selected": {
    boxShadow: "0 0 8px 2px rgba(15, 3, 3, 0.3)", 
    transform: "scale(1.05)",
    backgroundColor: "#C10401",
    color: "white",
  },
  // Responsive sizing
  width: "auto", // Default width
  maxWidth: "200px", // Maximum width for larger screens
  padding: "6px 12px", // Padding for consistency
  "@media (max-width: 600px)": {
    maxWidth: "100px", // Smaller buttons on small screens
    fontSize: "10px", // Adjust font size on small screens
    padding: "4px 8px", // Reduce padding for smaller screens
  },
};

  export const containerStyle: SxProps = {
    width: '100%',
    margin: 'auto',
  };
  
  export const cardContainerStyle: SxProps = {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap:'10px',
    '& > *': { 
      width: '100%',
      maxWidth: '100%',
    },
    '@media (min-width: 600px)': {
      '& > *': {
        width: 'calc(50% - 20px)',
        maxWidth: 'calc(50% - 20px)',
      },
    },
    '@media (min-width: 960px)': {
      '& > *': {
        width: 'calc(33.333% - 20px)',
        maxWidth: 'calc(33.333% - 20px)',
      },
    },
  };
  
  export const cardStyle: CSSProperties = {
    width: '100%',
    marginBottom: '10px',
    
    color: 'black',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    height:'auto',
    backgroundColor: '#ffffff',
    border: '1px solid #e0e0e0',  
    borderRadius: '8px', 
    padding: '20px',
    background: '#ffffff',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
  };
  
  export const cardHoveredStyle: CSSProperties = {
    transform: 'scale(1.02)',
  };
   
  export const cardContentStyle: CSSProperties = {
    height: '20px',
    paddingBottom:'none'
  };
  
  export const cardAvatarContainerStyle: CSSProperties = {
    position: 'relative',
    width: '100%', // Ensure the container takes full width
  height: 'auto', 
  };
  
  export const cardAvatarStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    border: '1px solid #8a898b',
    borderRadius: '50%',
   width:'50px',
   height:'50px', 
    overflow: 'hidden',
    background:'white',
  };
  export const favoriteItemStyle: CSSProperties = {
  //   position: 'absolute',
  //   top: 0,
  //   right: 0,
  //   zIndex: 1,
   
  //  width:'50px',
  //  height:'50px', 
  //   overflow: 'hidden',
 
  };
  
  
  export const cardAvatarImageStyle: CSSProperties = {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  };



  // FAVORITE CARDS
  export const favoriteCardStyle: CSSProperties = {
    width: '100%',
    marginBottom: '10px',
    
    color: 'black',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    height:'auto',
    backgroundColor: '#ffffff',
    border: '1px solid #e0e0e0', 
    borderRadius: '8px', 
    padding: '0',
    background: '#ffffff',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
  };
  
  export const favoriteLinkStyle: CSSProperties = {
    textDecoration: 'none',
    color: 'inherit',
    textAlign: 'left',
   margin:'auto',
  };
  
  export const favoriteMediaStyle: SxProps = {
    height: 240,
    width: '100%',
    position: 'relative',
  };
  
  export const favoriteTitleStyle: CSSProperties = {
    fontWeight: 'bold',
  };
  
  export const favoriteDescriptionStyle: CSSProperties = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  };
  
  export const favoriteButtonStyle: SxProps = {
    color: (theme: any) => (theme.isFav ? 'red' : 'gray'),
  };

  // BUSINESS DETAILS CARDS

  export const businessDetailCardStyle: SxProps<Theme> = {
    width: {
      xs: '100%',
      sm: '80%',
       lg: '70%',
    },
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap", 
    justifyContent:'space-evenly',

    background: '#ffffff', 
    
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
   height:'auto',
   backgroundColor: '#ffffff',
  //  border: '1px solid #e0e0e0', // You can specify the color directly
   borderRadius: '8px', // Or use a number directly for spacing
   color: 'black', // Directly specify the color
   textAlign: "center",
  };
  
  export const LinkLoginSxProps: SxProps<Theme> = {
    color: '#C10401',
    fontWeight: 'bold',
    borderBottom: '1px solid #C10401',
    fontSize: '18px',
    marginInline:'auto',
    fontFamily:'Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial',
    transition: 'transform 0.2s ease-in-out', // Smooth transition for hover effect
    '&:hover': {
      transform: 'scale(1.05)', 
      border: '1px solid #C10401',
    },
    width: {
      xs: '90%',
      sm: '90%',
      md:'50%',
      lg: '50%',
    },
  };


  export const contentCardStyle: SxProps<Theme> = {
    padding: '20px',
    width: {
      xs: '100%',
      sm: '100%',
      md:'50%',
      lg: '50%',
    },

    display: 'flex',
    flexDirection: 'column',
    textAlign: 'justify',
   
  };
  
  export const businessCardStyle: SxProps<Theme> = {
    margin: 'auto',
    width: {
      xs: '100%',
      sm: '100%',
      md:'50%',
      lg: '50%',
    },
    position:'relative',
  };
  
  export const businessRating: SxProps<Theme> ={
    marginTop: 'auto',
  }

  export const textFieldStyle: SxProps<Theme> = {
    width: {
      xs: "90%",
      sm: "80%",
    },
   
    paddingBottom: "20px",
  
  };
  
  export const submitButtonStyle: SxProps<Theme> = {
    mt: 1,
    marginInline: "20px",
    border: 'none',
  };
  
  export const alertStyle: SxProps<Theme> = {
    width: "100%",
  };
  
  export const formContainerStyle: SxProps<Theme> = {
    width: {
      xs: '100%',
      sm: '90%',
       lg: '80%',
    },
    margin: 'auto',
  };
  



  export const snackbarStyle: SxProps<Theme> = {
    width: "300px",
  };
  
  export const backButtonStyle: SxProps<Theme> = {
    mt: 2,
  };
  
  export const businessDetailsStyle: SxProps<Theme> = {
    width: {
      xs:'100%',
      sm:'90%',
      md:'80%'
    },
    margin: 'auto',
  };
  
  // BUSINESS LIST CARDS
  
  export const businessListContainerStyle: SxProps<Theme> = {
    width: '100%',
  
  };
  
  export const businessListTitleStyle: SxProps<Theme> = {
    marginBlock: '20px',
  };
  
  export const businessListItemContainerStyle: SxProps<Theme> = {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap:'10px',
    '& > *': { 
      width: '100%',
      maxWidth: '100%',
    },
    '@media (min-width: 600px)': {
      '& > *': {
        width: 'calc(50% - 20px)',
        maxWidth: 'calc(50% - 20px)',
      },
    },
    '@media (min-width: 960px)': {
      '& > *': {
        width: 'calc(33.333% - 20px)',
        maxWidth: 'calc(33.333% - 20px)',
      },
    },
  };
  
  export const businessListItemStyle: SxProps<Theme> = {}; // Add styles for individual business items here if needed
  
  export const loadingBoxStyle: SxProps<Theme> = {}; // Add styles for loading box here if needed
  
  // BUSINESS CARD ITEM
  
  // export const businessItemContainerStyle: SxProps<Theme> = {
  //   border: '2px solid #ccc',
  //   borderRadius: '10px',
  //   padding: '100px',
  //   background: '#f5f5f5',
  //   boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  //   transition: 'transform 0.3s ease',
  // };
  
  export const businessItemAvatarContainerStyle: SxProps<Theme> = {
    position: 'relative',
  };
  
  export const businessItemAvatarStyle: SxProps<Theme> = {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    border: '1px solid #8a898b',
    borderRadius: '50%',
    maxWidth: '50px',
    height: '50px',
    overflow: 'hidden',
  };
  
  export const businessItemAvatarImageStyle: SxProps<Theme> = {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  };


  export const commentListStyle: SxProps<Theme> = {
    width: {
      xs:'100%',
      sm:'80%',
      md:'80%',
      lg:'70%',
    },
    margin: 'auto',
  };
  

  export const commentItemContainerStyle: SxProps<Theme> = {
    
    height: 'auto',
    margin: 'auto',
    marginBlock: '20px',
    position: 'relative', 
    borderRadius: '10px',
    padding: '20px 20px 1px 20px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
  };

  export const commentItem: SxProps<Theme> = { 
    borderBottom:'1px solid grey', 
    paddingBottom:'80px',
    
  }
  

  export const subCommentViewMoreButton: CSSProperties ={

  fontSize: '10px',
  color: 'black',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
  transition: 'transform 0.3s ease', // Smooth transition when hovered
  cursor: 'pointer',
  margin:'auto',
  marginTop: '-50px',
  backgroundColor: '#e9e8e8',
  }

  export const subCommentItem: SxProps<Theme> ={
    width: {
      xs: '100%',
       sm:'90%',
       md: '85%', 
    }, 
    
    marginTop: '50px',
   marginLeft:'auto'
  }

  export const subCommentCard: SxProps<Theme> ={
  marginBlock: '10px',
   padding: '20px',
    position:'relative' ,
  };

  export const subCommentEditButton: SxProps<Theme> ={
    width:'100px',
     position: 'absolute',
      right: '10px',
      top:'5px',
  }

  export const commentItemBoxStyle: SxProps<Theme> = {
  color: 'black',
    borderBottom:'1px solid grey',
    position:'relative',
  };
  
  export const commentItemBoxRightStyle: SxProps<Theme> = {
    position: 'absolute',
    right: '10px',
  
  };
  
  export const commentItemAvatarStyle: SxProps<Theme> = {
    width: {
      xs: '50px',
      sm: '50px',
      md: '70px',
    },
    height: {
      xs: '50px',
      sm: '50px',
      md: '70px',
    },
  };
  
  export const commentItemImageCardStyle: SxProps<Theme> = {
    width: '100%',
    margin: 'auto',
    marginBlock:'20px'
  };
  
  export const commentItemRatingBoxStyle: SxProps<Theme> = {
    position: 'absolute',
    right: '20px',
    paddingTop: '25px',
  };
  
  export const commentItemTimeBoxStyle: SxProps<Theme> = {
    position: 'absolute',
    right: '10%',
   
  };
  
  export const commentItemUserInfoBoxStyle: SxProps<Theme> = {
    color: 'black',
  };
  
  export const commentItemTextStyle: SxProps<Theme> = {
    marginLeft: 'auto',
    marginBlock: '20px',
 
  };

    export const commentItemButtonsBox: SxProps<Theme> = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: '10px',
   
  };
  
  export const navBarContainer: SxProps<Theme> = {
  display: "flex", 
  justifyContent:'space-evenly',
        flex: 1,
        width:{
          xs:'100%',
          sm: '100%',
          lg: '80%',
        },
        margin: "auto",
        textDecoration: "none",
        color: "black",
        fontSize:'22px',
        fontWeight: 500,
        fontFamily: 'Raleway, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
        borderBottom: '1px solid #b9b5b5',
        paddingBlock: '20px',
        
        
      }
  export const navbarBTN: SxProps<Theme> = {
      
      gap:1,
      display: {
         xs: "none",
         sm:'flex',
         md: "flex",
      },
      width:'100%',
      justifyContent:'flex-end',
    // border:'1px solid green'
      }

      export const logoIcon: SxProps<Theme> = {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        height: "100px",
        transition: "transform 0.3s ease", // Smooth transition for scaling
        "&:hover": {
          transform: "scale(1.1)", // Scale up by 10% on hover
        },
      };



      export const homeBTNnavbar: SxProps<Theme> = {
      display: {
        xs:'none',
        sm: "none",
        md: "flex",
        justifyContent: "flex-start",
        gap:'5%',
        alignItems: "center",
        height: "100px",
        width: "100%",
       // border:'1px solid blue'
      },
    }

      export const footerColumns: SxProps<Theme> = {
        display:'flex',
        flexWrap:'wrap',
        justifyContent:'space-around',
        width:'100%',
     
      }
      export const footerTitle: SxProps<Theme> = {
        color:'black',
        fontWeight:'bold'
      }
      export const footerColumnOne: SxProps<Theme> = {
        display:'flex',
        flexDirection:'column',
        alignContent:'center',
        textAlign:'left',
       // border:'1px solid red',
     
      }
    

      export const linkStyle: CSSProperties = { 
        textDecoration: "none",
        color: "black",
        fontSize: "16px",
       
      };
    
    export const updateBusinessModal: SxProps<Theme>  = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        height: "auto",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        marginBlock: "50px",
        p: 4,     
        };
      
        export const userProfileAvatar: SxProps<Theme>  = {
          border:'1px solid grey',
          textAlign:'start',
          paddingLeft:'10px',
          paddingTop:'10px',
          borderRadius:'4px',
          '&:hover': {
            transform: 'scale(1.05)', 
            border:'2px solid grey',
            cursor: 'pointer',
          },
        };
        
        export const emailConfirmAnimation: SxProps = {
          '@keyframes fadein': {
            from: { opacity: 0 },
            to: { opacity: 1 },
          },
          '.confirmation-message': {
            animation: 'fadein 2s', // Animation duration: 2 seconds
          },
        };
        //------------ABOUT PAGE ----------
        export const aboutItemContainerStyle: SxProps<Theme> = {
          margin:'10px',
          borderRadius: '10px',
          padding: '20px',
          background: '#f5f5f5',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          display:'flex',
          flexWrap:'wrap',
        };
        
        
        export const aboutContentCardStyle: SxProps<Theme> = {
           padding: {
            xs:0,
            sm:0,
            md:'20px'},

          width: {
            xs: '100%',
            sm: '100%',
            md:'50%',
            lg: '50%',
          },
          textAlign:'left',
          display: 'flex',
          flexDirection: 'column',
          
         
        };
        
       
        export const midleBoxForLarge: SxProps<Theme> = {
          display:{
            xs:'none',
            sm:'none',
            md:'flex',
            lg:'flex',
          },
          width: {
            xs: '100%',
            sm: '100%',
            md:'50%',
            lg: '50%',
          },
          margin:'auto',
        };
        export const midleBoxForSmall: SxProps<Theme> = {
          display:{
            xs:'flex',
            sm:'flex',
            md:'none',
            lg: 'none',
          },
          width: {
            xs: '100%',
            sm: '100%',
            md:'50%',
            lg: '50%',
          },
          margin:'auto',
        };

        export const aboutItemImageStyle: SxProps<Theme> = {
        
          width: {
            xs: '100%',
            sm: '100%',
            md:'50%',
            lg: '50%',
          },
          margin:'auto',
        };


        export const aboutItemImage: CSSProperties = {
          objectFit: 'cover',
          width: '100%',
         
          margin:'auto'
        };