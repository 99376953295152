import * as YUP from "yup";

const subCommentSchema = YUP.object().shape({
  text: YUP.string()
    .min(2, "Title too short!")
    .required("*Please provide a text"),
  rate: YUP.number()
    .required("*Please provide a rating")
    .nullable()
    .notRequired(),
  image: YUP.string()
    .url("Invalid image URL format") // Validates if the image is a URL
    .nullable() // Allows the field to be null
    .notRequired(), // Makes the field optional
});

export default subCommentSchema;
