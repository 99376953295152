import React, { useEffect, useState } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { deleteUser, fetchUsers } from "../../../redux/thunk/userList";
import { fetchUser } from "../../../redux/thunk/user";
import { usersActions } from "../../../redux/slice/userList";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { SlBan } from "react-icons/sl";
import { MdOutlineAdminPanelSettings } from "react-icons/md";

import { url } from "../../../App";

function UserManagement() {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.user);
  const userId = user._id;
  const users = useSelector((state: RootState) => state.userList.usersData);

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");

  useEffect(() => {
    if (userId) {
      dispatch(fetchUser(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  // Function to handle delete button click
  const handleDeleteClick = (userId: string) => {
    setUserIdToDelete(userId);
    setOpenDeleteConfirm(true);
  };

  // Function to handle dialog close
  const handleCloseDialog = () => {
    setOpenDeleteConfirm(false);
  };

  // Function to delete user account
  const deleteUserAccount = async () => {
    try {
      await dispatch(deleteUser(userIdToDelete));

      setOpenDeleteConfirm(false);

      // Optionally, fetch users again after deletion
      dispatch(fetchUsers());
    } catch (error) {
      console.error("Error deleting user account:", error);
      // Handle error scenarios
    }
  };

  const handleToggleBan =
    (userId: string): React.MouseEventHandler<HTMLButtonElement> =>
    async (e) => {
      e.preventDefault();
      const token = localStorage.getItem("token");
      //.get is to retrieve the current state of the user data from the server.
      try {
        const userResponse = await axios.get(`${url}/users/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        //then saves this data to user
        const user = userResponse.data;
        //spreading user.data into a new object and setting the isBanned to the opposite of its current value
        const updatedUser = { ...user, isBanned: !user.isBanned };

        if (updatedUser.isBanned && updatedUser.isAdmin) {
          updatedUser.isAdmin = false;
        }
        //updated user data is sent back to the server
        const response = await axios.put(
          `${url}/users/update/${userId}`,
          updatedUser,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        dispatch(usersActions.toggleBanStatus(userId));
        if (updatedUser.isAdmin) {
          dispatch(usersActions.toggleAdminStatus(userId));
        }
      } catch (error) {
        console.error(error);
      }
    };

  const handleToggleAdmin =
    (userId: string): React.MouseEventHandler<HTMLButtonElement> =>
    async (e) => {
      e.preventDefault();
      const token = localStorage.getItem("token");

      try {
        const userResponse = await axios.get(`${url}/users/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const user = userResponse.data;

        // Check if user is banned
        if (user.isBanned) {
          alert("Cannot change admin status of a banned user.");
          return;
        }

        const updatedUser = { ...user, isAdmin: !user.isAdmin };

        const response = await axios.put(
          `${url}/users/update/${userId}`,
          updatedUser,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        dispatch(usersActions.toggleAdminStatus(userId));
      } catch (error) {
        console.error(error);
      }
    };

  return (
    <Box style={{ width: "90%", marginInline: "auto" }}>
      <Typography variant="h5" style={{ paddingBottom: "50px" }}>
        User information
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>FIRST NAME</TableCell>
              <TableCell align="left">LAST NAME</TableCell>
              <TableCell align="left">EMAIL</TableCell>
              <TableCell align="left">ROLE</TableCell>
              {/* ROLE - USER OD ADMIN */}
              <TableCell align="left">BAN</TableCell>
              {/* STATUS - BANNED OR NOT*/}
              <TableCell align="left">ADMIN</TableCell>
              {/* SPECIAL - CREATE ADMIN */}
              <TableCell align="left">DELETE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow
                key={user._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell component="th" scope="row">
                  {user.firstName}
                </TableCell>
                <TableCell align="left">{user.lastName}</TableCell>
                <TableCell align="left">{user.email}</TableCell>
                <TableCell align="left">
                  {user.isAdmin ? "Admin" : "User"}
                </TableCell>
                <TableCell align="left">
                  <Button onClick={handleToggleBan(user._id)}>
                    {user.isBanned ? (
                      <SlBan style={{ color: "red" }} />
                    ) : (
                      <SlBan />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button onClick={handleToggleAdmin(user._id)}>
                    {user.isAdmin ? (
                      <MdOutlineAdminPanelSettings
                        size={22}
                        style={{ color: "red" }}
                      />
                    ) : (
                      <MdOutlineAdminPanelSettings size={22} />
                    )}
                  </Button>
                </TableCell>
                <TableCell align="left">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDeleteClick(user._id)}
                  >
                    Delete
                  </Button>
                </TableCell>{" "}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDeleteConfirm} onClose={handleCloseDialog}>
        <DialogTitle>Delete User Account?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user's account? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteUserAccount} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default UserManagement;
