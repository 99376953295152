import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Favorite } from "../../types/favoriteTypes";

// Initial State
interface InitialState {
  favorites: Favorite[];
  alert: string;
}

const initialState: InitialState = {
  favorites: [],
  alert: "",
}; 

// Create Slice 
const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    // Add Favorite
    addToFavorite: (state, action: PayloadAction<Favorite>) => {
      // Find if the favorite already exists in the state
      const index = state.favorites.findIndex(
        (item) => item._id === action.payload._id
      );
      // Add the favorite if it doesn't exist
      if (index === -1) {
        state.favorites.push(action.payload);
      }
    },

    // Remove Favorite
    removeFromFavorite: (state, action: PayloadAction<{ _id: string }>) => {
      const index = state.favorites.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index >= 0) {
        state.favorites.splice(index, 1);
      }
    },

    // Update favorite businesses
    updateFavoriteBusinesses: (state, action: PayloadAction<Favorite[]>) => {
      state.favorites = action.payload;
    },

    // Show alert
    showAlert: (state, action: PayloadAction<string>) => {
      state.alert = action.payload;
    },
  },
});

export const favoriteActions = favoriteSlice.actions;

export default favoriteSlice.reducer;



