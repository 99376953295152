import { AppDispatch } from "../store";
import axios from "axios";
import { url } from "./../../App";
import { userActions } from "./../slice/user";

export function userUpdate(id: string, formData: FormData) {
  const token = localStorage.getItem("token");
  return async (dispatch: AppDispatch) => {
    axios
      .put(`${url}/users/update/${id}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => dispatch(userActions.getUser(res.data)))
      .catch((error) => dispatch(userActions.errorHandler(error)));
  }; 
}
  