import { Box } from "@mui/material";
import CmtManagement from "../components/admin/comentMng/CmtManagement";

const CommentMng = () => {
  return (
    <Box className="comment-page">
      <CmtManagement />
    </Box>
  );
};
export default CommentMng;
