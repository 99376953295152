import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../redux/store";
import BusinessDetail from "./../components/business/businessDetail/BusinessDetail";
import { fetchBusinessDetail } from "./../redux/thunk/businessDetail";
import { Box, Typography } from "@mui/material";

const DetailedBusiness = () => {
  const { id } = useParams<{ id: string }>(); // Ensure that the type of 'id' is correctly inferred
  const favState = useSelector((state: RootState) => state.favorite.favorites);
  const businessDetail = useSelector(
    (state: RootState) => state.business.businessDetails
  );
  const users = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (id) {
      dispatch(fetchBusinessDetail(id));
    }
  }, [dispatch, id]);

  if (!businessDetail) {
    return (
      <Box>
        <p>Loading business details...</p>
      </Box>
    );
  }

  if (!favState.length) {
    return (
      <Box>
        <BusinessDetail business={businessDetail} user={users} />
      </Box>
    );
  }

  return (
    <Box>
      <Typography>Business Details:</Typography>
      {favState
        .slice()
        .reverse()
        .map((favItem) => (
          <BusinessDetail
            key={favItem._id}
            business={businessDetail}
            user={users}
            favorite={favItem}
          />
        ))}
    </Box>
  );
};

export default DetailedBusiness;
