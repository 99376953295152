import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { searchActions } from "../../redux/slice/search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Button, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchBusinessData } from "../../redux/thunk/business";
import { questionStyle } from "../../constants/styleConstants";
import { useLanguage } from "../languages/LanguageContext";
import {
  CHOOSE_STORE_TEXT_EN,
  CHOOSE_STORE_TEXT_SE,
} from "../../constants/textConstants";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "100%",
    margin: "0 auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export function SearchBarHome() {
  const { currentLanguage } = useLanguage();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const businessList = useSelector(
    (state: RootState) => state.business.allBusinesses
  );
  const businessSearchList = useSelector(
    (state: RootState) => state.search.businessSearchResultList
  );

  // Filtered list based on user input
  const resultList = businessList.filter((business) =>
    business.title.toLowerCase().includes(selectedBusiness.toLowerCase())
  );

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (selectedBusinessId: string) => {
    setSelectedBusiness(selectedBusinessId);
    navigate(`/business/${selectedBusinessId}`);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Update resultList when selectedBusiness changes

  useEffect(() => {
    dispatch(fetchBusinessData());
    // Clear search results when the component mounts or unmounts
    return () => {
      dispatch(searchActions.setBusinessSearchResults([]));
    };
  }, [dispatch]);

  return (
    <Search
      style={{
        color: "grey",
        backgroundColor: "none",
      }}
    >
      <Button
        style={questionStyle}
        aria-controls="business-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        variant="text"
        sx={{
          border: "1px solid grey",
          color: "grey",
          "&:hover": { backgroundColor: "darkgrey" },
        }}
      >
        {currentLanguage === "se" ? CHOOSE_STORE_TEXT_SE : CHOOSE_STORE_TEXT_EN}
      </Button>
      <Menu
        // sx={{ border: "5px solid black" }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {resultList.map((business) => (
          <MenuItem
            key={`result-${business._id}`}
            onClick={() => handleMenuItemClick(business._id)}
          >
            {business.title}
          </MenuItem>
        ))}
      </Menu>
    </Search>
  );
}

export default SearchBarHome;
