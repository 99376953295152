import * as YUP from "yup";

export const commentSchema = YUP.object().shape({
  text: YUP.string()
    .min(2, "Comment too short!") 
    .required("*Please write a comment"),
  rate: YUP.number()
    .nullable() // Allows the field to be null
    .notRequired(), // Makes the field optional
  image: YUP.string()
    .url("Invalid image URL format") // Validates if the image is a URL
    .nullable() // Allows the field to be null
    .notRequired(), // Makes the field optional
  businessLocation: YUP.string() // Add validation for branch location
  .required("*Please add store location")
  .matches(/^[\w\säöåÄÖÅ]+$/, "Business location can only contain letters and spaces")
  .test("word-count", "Business location should consist of at most 2 words", value => {
    if (!value) return true; // Skip validation if value is empty
    return value.trim().split(/\s+/).length <= 2;
  })
  .max(20, "Business location must be at most 20 characters long"),
});

export const subCommentSchema = YUP.object().shape({
  text: YUP.string()
    .min(2, 'Subcomment too short!')
    .required('*Please write a subcomment'),
  rate: YUP.number()
    .nullable() // Allows the field to be null
    .notRequired(), // Makes the field optional
  image: YUP.string()
    .url('Invalid image URL format') // Validates if the image is a URL
    .nullable() // Allows the field to be null
    .notRequired(), // Makes the field optional
});
  