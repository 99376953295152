import { Box, Typography } from "@mui/material";
import { useLanguage } from "../components/languages/LanguageContext";
import {
  ABOUT_TITLE,
  ABOUT_INTRO,
  ABOUT_GOAL,
  ABOUT_TRUST,
  ABOUT_EXPERIENCES,
  ABOUT_COMMUNITY,
  ABOUT_ENGAGE,
  ABOUT_THANKS,
  ABOUT_TITLE_SE,
  ABOUT_INTRO_SE,
  ABOUT_GOAL_SE,
  ABOUT_TRUST_SE,
  ABOUT_EXPERIENCES_SE,
  ABOUT_COMMUNITY_SE,
  ABOUT_ENGAGE_SE,
  ABOUT_THANKS_SE,
} from "../constants/textConstants";
import image1 from "../assets/1.webp";
import image3 from "../assets/2.webp";
import image4 from "../assets/3.webp";
import {
  aboutContentCardStyle,
  aboutItemContainerStyle,
  aboutItemImage,
  aboutItemImageStyle,
  logoIcon,
  midleBoxForLarge,
  midleBoxForSmall,
} from "../constants/styleConstants";
import logo3 from "../assets/logo3.png";

const About = () => {
  const { currentLanguage } = useLanguage();

  const isSwedish = currentLanguage === "se";

  return (
    <Box className="about" sx={{ margin: "auto" }}>
      <Box className="box-first" sx={aboutItemContainerStyle}>
        <Box sx={aboutContentCardStyle}>
          <Typography
            textAlign={"center"}
            sx={{ marginBottom: "15px" }}
            variant="h5"
          >
            {isSwedish ? ABOUT_TITLE_SE : ABOUT_TITLE}
          </Typography>
          <Typography textAlign={"left"}>
            {isSwedish ? ABOUT_INTRO_SE : ABOUT_INTRO}
          </Typography>
          <Typography textAlign={"left"}>
            {isSwedish ? ABOUT_GOAL_SE : ABOUT_GOAL}
          </Typography>
        </Box>

        <Box sx={aboutItemImageStyle}>
          <img style={aboutItemImage} src={image1} alt="image" loading="lazy" />
        </Box>
      </Box>
      <Box className="box-second" sx={aboutItemContainerStyle}>
        <Box sx={midleBoxForLarge}>
          <img width="100%" src={image3} alt="image" loading="lazy" />
        </Box>
        <Box sx={aboutContentCardStyle}>
          <Typography>{isSwedish ? ABOUT_TRUST_SE : ABOUT_TRUST}</Typography>
          <Typography>
            {isSwedish ? ABOUT_EXPERIENCES_SE : ABOUT_EXPERIENCES}
          </Typography>
          <Box
            className="navbar-center"
            sx={{ marginBlock: "20px", marginInline: "auto" }}
          >
            <img width="200px" src={logo3} alt="logo" loading="lazy" />
          </Box>
        </Box>
        <Box sx={midleBoxForSmall}>
          <img width="100%" src={image3} alt="image" loading="lazy" />
        </Box>
      </Box>

      <Box className="box-third" sx={aboutItemContainerStyle}>
        <Box sx={aboutContentCardStyle}>
          <Typography>
            {isSwedish ? ABOUT_COMMUNITY_SE : ABOUT_COMMUNITY}
          </Typography>

          <Typography>{isSwedish ? ABOUT_ENGAGE_SE : ABOUT_ENGAGE}</Typography>
        </Box>
        <Box sx={aboutItemImageStyle}>
          <img width="100%" src={image4} alt="image" loading="lazy" />
        </Box>
      </Box>
      <Typography variant="h6" sx={{ marginTop: 2, padding: "10px" }}>
        {isSwedish ? ABOUT_THANKS_SE : ABOUT_THANKS}
      </Typography>
    </Box>
  );
};

export default About;
