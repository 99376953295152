import { Box } from "@mui/system";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userActions } from "../../../redux/slice/user";
import { url } from "../../../App";

// Ensure Axios sends cookies with each request
axios.defaults.withCredentials = true;

function GoogleLogIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", paddingBlock: "50px" }}
    >
      <GoogleLogin
        onSuccess={async (credentialResponse) => {
          const credential = credentialResponse.credential;
          try {
            const res = await axios.post(
              `${url}/users/googleLogIn`,
              { id_token: credential },
              { withCredentials: true } // Ensure cookies are sent
            );
            console.log("Response data from Google login:", res.data);

            if (res.status === 200) {
              const { user, token } = res.data;
              if (user && token) {
                dispatch(userActions.getUser(user));
                dispatch(userActions.loginHandler(true));

                // Set token in localStorage and cookies
                localStorage.setItem("token", token);
                document.cookie = `token=${token}; path=/; secure; HttpOnly; SameSite=Strict`;

                navigate("/user");
              } else {
                console.error(
                  "Login successful but user data or token is missing"
                );
                alert("Log in failed due to missing user data or token");
              }
            } else {
              alert("Log in failed");
            }
          } catch (error) {
            console.error("Login error", error);
            alert("Log in failed");
          }
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </Box>
  );
}

export default GoogleLogIn;

// import { Box } from "@mui/system";
// import axios from "axios";
// import { GoogleLogin } from "@react-oauth/google";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { userActions } from "../../../redux/slice/user";
// import { url } from "../../../App";

// // Ensure Axios sends cookies with each request
// axios.defaults.withCredentials = true;

// function GoogleLogIn() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   return (
//     <Box
//       sx={{ display: "flex", justifyContent: "center", paddingBlock: "50px" }}
//     >
//       <GoogleLogin
//         onSuccess={async (credentialResponse) => {
//           const credential = credentialResponse.credential;
//           try {
//             const res = await axios.post(
//               `${url}/users/googleLogIn`,
//               { id_token: credential },
//               { withCredentials: true } // Ensure cookies are sent
//             );
//             console.log("Response data from Google login:", res.data);

//             if (res.status === 200) {
//               const { userData, token } = res.data;
//               if (userData && token) {
//                 dispatch(userActions.getUser(userData));
//                 dispatch(userActions.loginHandler(true));

//                 // Set token in localStorage and cookies
//                 localStorage.setItem("token", token);
//                 document.cookie = `token=${token}; path=/; secure; SameSite=Strict`;

//                 console.log("User dispatched:", userData);
//                 console.log("Token dispatched:", token);
//                 navigate("/user");
//               } else {
//                 console.error(
//                   "Login successful but user data or token is missing"
//                 );
//                 alert("Log in failed due to missing user data or token");
//               }
//             } else {
//               alert("Log in failed");
//             }
//           } catch (error) {
//             console.error("Login error", error);
//             alert("Log in failed");
//           }
//         }}
//         onError={() => {
//           console.log("Login Failed");
//         }}
//       />
//     </Box>
//   );
// }

// export default GoogleLogIn;
