import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { searchActions } from "../../redux/slice/search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from '@mui/icons-material/Search';


type PropType = {
  businessId: string;

};


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "100%",
    margin: "0 auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.grey[800],  // Changed to a darker grey
  border: `1px solid ${theme.palette.grey[400]}`, // Border styling
  borderRadius: theme.shape.borderRadius, // Rounded corners
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`, // Padding calculation with theme spacing
    transition: theme.transitions.create("width"), // Animation for width
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch", // Fixed width on medium devices and up
    },
  },
  marginTop:'50px',
}));


export function SearchBarComments({ businessId }: PropType) {
  const [userInput, setUserInput] = useState<string>("");
  const [noResults, setNoResults] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const commentsList = useSelector((state: RootState) => state.comment.comments);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setUserInput(input);

    const filteredResults = commentsList.filter((comment) =>
      (comment.businessId === businessId) &&
      (
        comment.businessLocation?.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ||
        comment.text?.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ||
        comment.firstName?.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ||
        comment.lastName?.toLocaleLowerCase().includes(input.toLocaleLowerCase())
      )
    );

    if (input === "") {
      dispatch(searchActions.setCommentSearchResults({ comments: commentsList, businessId }));
      setNoResults(false);
    } else {
      dispatch(searchActions.setCommentSearchResults({ comments: filteredResults, businessId }));
      setNoResults(filteredResults.length === 0);
    }
  };

  useEffect(() => {
    dispatch(searchActions.setCommentSearchResults({ comments: commentsList, businessId }));
  }, [commentsList, businessId, dispatch]);

  return (
    <>
    <Search style={{ color: "grey", backgroundColor: "none" }}>
      <SearchIcon />
      <StyledInputBase
        placeholder="search by location"
        inputProps={{ "aria-label": "search" }}
        value={userInput}
        onChange={onChangeHandler}
      />
    </Search>
     {noResults && <div>Nothing found on your search</div>}
    </>
  );
}

export default SearchBarComments;