import { Box } from "@mui/material";
import UserProfile from "../components/users/userinformation/UserProfile";

const UserInformation = () => {
  return (
    <Box className="user-info-page">
    
      <UserProfile />
    </Box>
  );
};

export default UserInformation;