import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/user";
import usersReducer from "./slice/userList";
import commentReducer from "./slice/comment";
import subCommentReducer from "./slice/subComment";
import businessReducer from "./slice/business";
import userListReducer from "./slice/userList";
import imageReducer from "./slice/image";
import favoriteReducer from "./slice/favorite"
import searchReducer from "./slice/search";

const store = configureStore({ 
  reducer: {
    user: userReducer,
    users: usersReducer,
    comment: commentReducer,
    subComment:subCommentReducer,
    business: businessReducer,
    userList: userListReducer,
    image: imageReducer,
    favorite:favoriteReducer,
    search: searchReducer,
  }, 
  middleware: (getDefaultMiddleware) => {
    return process.env.NODE_ENV === 'development'
      ? getDefaultMiddleware({
          serializableCheck: false,
        })
      : getDefaultMiddleware();
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;


