import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommentType, RatingType } from "../../types/commentTypes";
import { SubCommentType } from "../../types/subCommentTypes";


const initialSubComment: SubCommentType = {
  userId: '',
  businessId: '',
  commentId: '',
  text: '',
  date: undefined,
  rate: 0,
  isConfirmed: false, 
  image: '',
  firstName: '',
  lastName: '',
  userImage:'',
  ratings: [],  
};

type InitialType = {
  comments: CommentType[];
  subComments: SubCommentType[] | null;
  allComments: CommentType[];
  commentsWithMostSubcomments: CommentType[];
  commentsWithMostRatings: CommentType[];
  commentById: CommentType | null;
  loading: boolean;
  error: string | null;
};

const initialState: InitialType = {
  comments: [],
  subComments: null,
  allComments: [],
  commentsWithMostSubcomments: [],
  commentsWithMostRatings: [],
  commentById: {
    userId: '',
    businessId: '',
    businessIcon:'',
    text: '',
    rate: null,
    ratings: [], 
    firstName: '',
    lastName: '',
    subComments: [initialSubComment],
    userImage:'',
    businessLocation:'' // Initialize branches array
  },
  loading: false,
  error: null,
};

const commentSlice = createSlice({ 
  name: "comment",
  initialState,
  reducers: {
    getCommentByBusinessId: (state, action: PayloadAction<CommentType[]>) => {
      state.comments = action.payload;
    },
    getAllComments: (state, action: PayloadAction<CommentType[]>) => {
      state.allComments = action.payload;
    },
    addComment: (state, action: PayloadAction<CommentType>) => {
      if (!state.comments) {
        state.comments = []; // Initialize comments array if it's null or undefined
      }
      state.comments.push(action.payload);
    },

    addRate: (state, action: PayloadAction<{ commentId: string; userId: string; rate: number | null }>) => {
      const { commentId, userId, rate } = action.payload;
      const commentItem = state.comments.find((item) => item._id === commentId);
      console.log("Adding rate:", { commentId, userId, rate });
      if (commentItem) {
        // Initialize ratings if it doesn't exist
        if (!commentItem.ratings) {
          commentItem.ratings = [];
        }
    
        // Update or remove the user's rating
        const existingRatingIndex = commentItem.ratings.findIndex((rating) => rating.userId === userId);
    
        if (existingRatingIndex !== -1) {
          // Remove the rating if the rate is null or update it otherwise
          if (rate === null) {
            commentItem.ratings.splice(existingRatingIndex, 1);
          } else {
            commentItem.ratings[existingRatingIndex].rate = rate;
          }
        } else if (rate !== null) {
          // Add a new rating if it doesn't exist and rate is not null
          commentItem.ratings.push({ userId, rate });
        }
    
        // Recalculate the aggregate rate
        let totalRate = 0;
        let nonNullRatingsCount = 0;
    
        commentItem.ratings.forEach((r) => {
          if (r.rate !== null) {
            totalRate += r.rate;
            nonNullRatingsCount++;
          }
        });
    
        commentItem.rate = nonNullRatingsCount > 0 ? totalRate / nonNullRatingsCount : null;
      }
    },
    setRatesForComment: (state, action: PayloadAction<{ commentId: string; rates: RatingType[] }>) => {
      const { commentId, rates } = action.payload;
      const comment = state.comments.find(c => c._id === commentId);
      if (comment) {
        comment.ratings = rates;
      }
    },

    addSubcomments: (
      state,
      action: PayloadAction<{ commentId?: string; subcomments: SubCommentType[] }>
    ) => {
      const { commentId, subcomments } = action.payload;
      console.log(`Received action to add subcomments:`, action.payload);
    
      if (commentId) {
    
        const updatedComments = state.comments.map((comment) => {
          // Check if we are at the comment that needs updating
          if (comment._id === commentId) {
            // Initialize subComments array if it's null or undefined
            const currentSubComments = comment.subComments || [];
            // Combine the existing subcomments with the new ones
            const updatedSubComments = [...currentSubComments, ...subcomments];
            // Return a new comment object with updated subComments
            return { ...comment, subComments: updatedSubComments };
          }
          // Return the comment as is if it's not the one we're updating
          return comment;
        });
    
        // Update the state with the new comments array
        state.comments = updatedComments;
      } else {
        console.error('Invalid commentId provided');
      }
    },
    
    
    
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    }, 
   
  
    setComments: (state, action: PayloadAction<CommentType[]>) => {
      state.comments = action.payload;
    },
    setCommentsWithMostSubcomments: (state, action: PayloadAction<CommentType[]>) => {
      state.commentsWithMostSubcomments = action.payload;
    },
    setCommentsWithMostRatings: (state, action: PayloadAction<CommentType[]>) => {
      state.commentsWithMostRatings = action.payload; 
    },
    getCommentById: (state, action: PayloadAction<CommentType>) => {
      const commentItem = action.payload;

      // Map the comment and its subcomments if subComments exists
      state.commentById = { 
        ...commentItem,
        subComments: commentItem.subComments ? commentItem.subComments.map(subComment => ({
          ...subComment, 
          // Additional properties or modifications can be made here if needed
        })) : [],
      };
    },
   getCommentsByUserId: (state, action: PayloadAction<CommentType[]>) => {
      state.comments = action.payload;
 
    }, 
    updateComment: (state, action: PayloadAction<CommentType>) => {
      const updatedComment = action.payload;
      const index = state.comments.findIndex(comment => comment._id === updatedComment._id); 
      if (index !== -1) {
        state.comments[index] = updatedComment;
      }
    }, 

    updateSubComment: (state, action: PayloadAction<SubCommentType>) => {
      const updatedSubComment = action.payload;
      const commentIndex = state.comments.findIndex(comment =>
        comment.subComments && comment.subComments.some(subComment => subComment._id === updatedSubComment._id)
      );
    
      if (commentIndex !== -1) {
        const commentToUpdate = state.comments[commentIndex];
        if (commentToUpdate.subComments) {
          const subCommentIndex = commentToUpdate.subComments.findIndex(subComment => subComment._id === updatedSubComment._id);
          
          if (subCommentIndex !== -1) {
            // Create a new array of subcomments to update the state immutably
            const updatedSubComments = [...commentToUpdate.subComments];
            // Update the subcomment within the new array
            updatedSubComments[subCommentIndex] = updatedSubComment;
            // Update the comment with the new array of subcomments
            const updatedComment = {
              ...commentToUpdate,
              subComments: updatedSubComments
            };
            // Update the comments array immutably
            state.comments = [
              ...state.comments.slice(0, commentIndex),
              updatedComment,
              ...state.comments.slice(commentIndex + 1)
            ];
          }
        }
      }
    },
    
    deleteComment: (state, action: PayloadAction<string>) => {
      const commentIdToDelete = action.payload;
      // Filter out the comment with the specified ID
      state.comments = state.comments.filter(comment => comment._id !== commentIdToDelete);
    },
    
    deleteSubComment: (state, action: PayloadAction<{ subCommentId: string }>) => {
      const { subCommentId } = action.payload;
      state.comments.forEach(comment => {
        if (comment.subComments) {
          comment.subComments = comment.subComments.filter(subComment => subComment._id !== subCommentId);
        }
      });
    }
  }
});

export const commentActions = commentSlice.actions;
export default commentSlice.reducer;

