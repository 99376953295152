import { userActions } from "../slice/user";
import { AppDispatch } from "../store";
import axios from "axios"; 
import { url } from "./../../App";

export function fetchUser(userId: string | null) {
  return async (dispatch: AppDispatch) => {
    if (!userId) {
      return { message: "no such user " };
    }
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${url}/users/${userId}`, {
        headers: { Authorization: `Bearer ${token} ` },
      });
      dispatch(userActions.getUser(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}


export const addFavoriteBusiness = (userId: string, businessId: string) => async (dispatch: AppDispatch) => {
  try {
    const token = localStorage.getItem('token');

    // Send a request to add the favorite business
    const response = await axios.post(`${url}/users/addFavorite/${userId}/${businessId}`,  {
      headers: { Authorization: `Bearer ${token}` }
    });

    // Update the store with the added favorite business
    dispatch(userActions.addFavoriteBusiness(response.data));

    // Return the added business ID
    return businessId;
  } catch (error) {
    console.error('Error adding favorite business:', error);
    dispatch(userActions.errorHandler('Failed to add favorite business'));
    throw error; // Throw the error to handle it in the caller
  }
};


export const removeFavoriteBusiness = (userId: string, businessId: string) => async (dispatch: AppDispatch) => {
  try {
    const token = localStorage.getItem('token');

    const response = await axios.delete(`${url}/users/removeFavorite/${userId}/${businessId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    dispatch(userActions.removeFavoriteBusiness(response.data));
  } catch (error) {
    console.error('Error removing favorite business:', error);
    dispatch(userActions.errorHandler('Failed to remove favorite business'));
  }
};

export const fetchFavoriteBusinesses = (userId: string) => async (dispatch: AppDispatch) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${url}/users/${userId}/favoriteBusinesses`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    dispatch(userActions.setFavoriteBusinesses(response.data));
  } catch (error) {
    console.error('Error fetching favorite businesses:', error);
    dispatch(userActions.errorHandler('Failed to fetch favorite businesses'));
  }
};