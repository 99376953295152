import * as YUP from "yup";

export const updateCommentSchema = YUP.object().shape({
  text: YUP.string()
    .min(2, "Comment too short!")
    .required("*Please write a comment"),
 
  image: YUP.string()
    .url("Invalid image URL format") // Validates if the image is a URL
    .nullable() // Allows the field to be null
    .notRequired(), // Makes the field optional
});

export const updateSubCommentSchema = YUP.object().shape({
  text: YUP.string()
    .min(2, 'Subcomment too short!')
    .required('*Please write a subcomment'),
  image: YUP.string()
    .url('Invalid image URL format') // Validates if the image is a URL
    .nullable() // Allows the field to be null
    .notRequired(), // Makes the field optional
});
  