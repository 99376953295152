import React from "react";
import "./SpinnerContainer.css"; // Import CSS file for spinner styles

const SpinnerContainer = () => (
  <div className="spinner-container">
    <div className="spinner"></div>
  </div>
);

export default SpinnerContainer;
