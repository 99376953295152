import { usersActions } from "../slice/userList";
import { AppDispatch } from "../store";
import axios from "axios";
import { url } from "../../App";


axios.defaults.withCredentials = true;


export function fetchUsers() {
  return async (dispatch: AppDispatch) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${url}/users`, {
        headers: { Authorization: `Bearer ${token} ` },
      });
      dispatch(usersActions.getUsersList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
} 



export const deleteUser = (userId: string) => {
  return async (dispatch: AppDispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(usersActions.errorHandler("User not authenticated."));
      return;
    } 
    try {
      const response = await axios.delete(`${url}/users/deleteUser/${userId}`, {
        headers: { Authorization: `Bearer ${token}` } 
      });
      if (response.data.success) { // Assuming the response looks like { success: true } 
        dispatch(usersActions.deleteUser(userId));
      } else {
        dispatch(usersActions.errorHandler("Failed to delete the user."));
      }
    } catch (error) {
      console.error('Error deleting user:', error); // Log the error
      dispatch(usersActions.errorHandler("Error deleting the user."));
    }
  };
};

