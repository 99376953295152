import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { userActions } from "../../../redux/slice/user";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { url } from "../../../App";
import { BusinessType } from "../../../types/businessTypes";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  favoriteMediaStyle,
  favoriteDescriptionStyle,
  favoriteLinkStyle,
  cardStyle,
  cardContentStyle,
} from "../../../constants/styleConstants";

type PropType = {
  business: BusinessType; // Directly use BusinessType now
};

const FavoriteItem = ({ business }: PropType) => {
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.user.user._id);
  const token = localStorage.getItem("token"); // Ensure token is retrieved securely
  const isFav = useSelector((state: RootState) =>
    state.user.user.favoriteBusinesses.some((b) => b._id === business._id)
  );
  const handleRemoveFavorite = async () => {
    const urlPath = `${url}/users/removeFavorite/${userId}/${business._id}`;

    try {
      const response = await fetch(urlPath, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // Dispatch Redux action to update the local state after successful API call
        dispatch(userActions.removeFavoriteBusiness(business._id));
      } else {
        const errorData = await response.json();
        console.error("Error removing favorite:", errorData);
        dispatch(
          userActions.errorHandler("Failed to remove favorite business")
        );
      }
    } catch (error) {
      console.error("Error during fetch operation:", error);
      dispatch(
        userActions.errorHandler(
          "Failed to remove favorite business due to network error"
        )
      );
    }
  };

  return (
    <Box sx={{ marginTop: "20px" }}>
      <Card
        sx={cardStyle}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = "scale(1.02)";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = "scale(1)";
        }}
      >
        <Link to={`/business/${business._id}`} style={favoriteLinkStyle}>
          <CardMedia
            sx={favoriteMediaStyle}
            image={business.image || "default_placeholder_image.jpg"}
            title={business.title}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={cardContentStyle}
            >
              {business.title}
            </Typography>
            <Typography>{business.category}</Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              style={favoriteDescriptionStyle}
            >
              {(business.description ?? "").slice(0, 50)} ...
            </Typography>
          </CardContent>
        </Link>
        <Button onClick={handleRemoveFavorite}>
          <FavoriteBorderIcon sx={{ color: isFav ? "red" : "gray" }} />
        </Button>
      </Card>
    </Box>
  );
};

export default FavoriteItem;
