import * as YUP from "yup";

// const branchSchema = YUP.object().shape({
//   location: YUP.string()
//     .min(2, "Location Too Short!")
//     .max(100, "Location Too Long!") // adjust max length if needed
//     .required("*Required"),

//   address: YUP.string()
//     .min(2, "Address Too Short!")
//     .max(100, "Address Too Long!") // adjust max length if needed
//     .required("*Required"),
// });

const updateBusinessSchema = YUP.object().shape({
  title: YUP.string()
    .min(2, "Name Too Short!")
    .max(50, "Name Too Long!") // adjust max length if needed 
    .nullable() // Allows the field to be null
    .notRequired(),

  category: YUP.string() 
    .min(2, "Category Too Short!")
    .max(50, "Category Too Long!") // adjust max length if needed
    .nullable() // Allows the field to be null
    .notRequired(),

   image: YUP.string()
    .url("Invalid image URL format") // Validates if the image is a URL
    .nullable() // Allows the field to be null
    .notRequired(),
    icon: YUP.string()
    .url("Invalid image URL format") // Validates if the image is a URL
    .nullable() // Allows the field to be null
    .notRequired(),

  description: YUP.string() 
    .min(2, "Description Too Short!")
    .max(999, "Description Too Long!") // adjust max length if needed
    .required("*Required"),

  rate: YUP.number()
    .min(1, "Rate Too Low!") // adjust min value if needed
    .max(5, "Rate Too High!") // adjust max value if needed
    .nullable() // Allows the field to be null
    .notRequired(),
  // location: YUP.string()
  //   .min(2, "Location Too Short!")
  //   .max(100, "Location Too Long!") // adjust max length if needed
  //   .required("*Required"),
  // branches: YUP.array()
  //   .of(branchSchema)
  //   .required("*Required"),
});

export default updateBusinessSchema; 
