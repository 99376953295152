import React, { useEffect } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";

import { Box, useMediaQuery } from "@mui/material";

import { RootState, AppDispatch } from "../../redux/store";
import { fetchBusinessData } from "../../redux/thunk/business";

import "./businessCarousel.css";
import { Link } from "react-router-dom";

const BusinessCarousel: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const businesses = useSelector(
    (state: RootState) => state.business.allBusinesses
  );

  useEffect(() => {
    dispatch(fetchBusinessData());
  }, [dispatch]);

  const matchesMd = useMediaQuery("(min-width: 768px)");

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: true,
  };

  const sliderSettings = {
    ...settings,
    slidesToShow: matchesMd ? 5 : 3,
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "auto",
        background: "#f5f5f5",
        marginBlock: "20px",
      }}
    >
      <Slider {...sliderSettings}>
        {businesses.map((business) => (
          <Box className="carousel-item" key={business._id}>
            <Link to={`/business/${business._id}`} key={business._id}>
              <img
                src={business.icon}
                alt={business.title}
                className="carousel-image"
                style={{
                  // boxShadow: "1px 1px 4px  rgba(0, 0, 0, 0.2)",
                  backgroundColor: "white",
                }}
                loading="lazy"
              />
            </Link>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default BusinessCarousel;
