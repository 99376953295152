import { Box } from '@mui/material'
import RegistrationForm from '../../components/users/registrationForm/RegistrationForm'

function Registration() {
  return (
    <Box>
        <RegistrationForm/>
    </Box>
  )
}

export default Registration