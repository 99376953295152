import { useState } from "react";
import { Link } from "react-router-dom";

import { Box, Button, Container, Typography } from "@mui/material";
import LoginForm from "../../components/users/loginForm/LoginForm";
import GoogleLogIn from "../../components/users/googleLogIn/GoogleLogIn";
import { useLanguage } from "../../components/languages/LanguageContext";
import {
  LOGIN_WITH_EMAIL,
  LOGIN_WITH_EMAIL_SE,
  LOGIN_WITH_GOOGLE,
  LOGIN_WITH_GOOGLE_SE,
  NOT_A_MEMBER_YET,
  NOT_A_MEMBER_YET_SE,
  SIGN_UP,
  SIGN_UP_SE,
} from "../../constants/textConstants";

function LogIn() {
  const { currentLanguage } = useLanguage();
  const [selectedLoginMethod, setSelectedLoginMethod] = useState<string>("");

  const handleEmailLogin = () => {
    setSelectedLoginMethod("email");
  };

  const handleGoogleLogin = () => {
    setSelectedLoginMethod("google");
  };

  return (
    <Box>
      <Box sx={{ marginY: "110px" }}>
        {/* <Typography style={{ marginTop: '100px' }} variant="h5" component="h1" gutterBottom>
          {currentLanguage === 'se' ? LOGIN_TITLE_SE : LOGIN_TITLE}
        </Typography> */}

        {selectedLoginMethod === "" && (
          <Container>
            <Box
              sx={{ width: "250px", marginBlock: "20px", marginInline: "auto" }}
            >
              <Button variant="contained" onClick={handleEmailLogin} fullWidth>
                {currentLanguage === "se"
                  ? LOGIN_WITH_EMAIL_SE
                  : LOGIN_WITH_EMAIL}
              </Button>
            </Box>

            <Box
              sx={{ width: "250px", marginBlock: "20px", marginInline: "auto" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoogleLogin}
                fullWidth
              >
                {currentLanguage === "se"
                  ? LOGIN_WITH_GOOGLE_SE
                  : LOGIN_WITH_GOOGLE}
              </Button>
            </Box>
          </Container>
        )}

        {selectedLoginMethod === "email" && <LoginForm />}
        {selectedLoginMethod === "google" && <GoogleLogIn />}
      </Box>

      <Box>
        <Typography style={{ marginTop: "50px" }}>
          {currentLanguage === "se" ? NOT_A_MEMBER_YET_SE : NOT_A_MEMBER_YET}
        </Typography>
        <Box
          sx={{
            width: "250px",
            marginTop: "20px",
            marginInline: "auto",
            border: "2px solid #1976D2",
            borderRadius: "5px",
            color: "#1976D2",
          }}
        >
          <Link to="/registration">
            <Typography variant={"h6"}>
              {currentLanguage === "se" ? SIGN_UP_SE : SIGN_UP}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default LogIn;
