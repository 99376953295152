import React, { useEffect } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { fetchAllComments } from "../../../redux/thunk/comment";
import { fetchUsers } from "../../../redux/thunk/userList";
import { fetchAllSubComments } from "../../../redux/thunk/subComment";

import {
  Box,
  Button,
  CardMedia,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { url } from "../../../App";

const CmtManagement = () => {
  const userList = useSelector((state: RootState) => state.userList.usersData);
  const allBusiness = useSelector(
    (state: RootState) => state.business.allBusinesses
  );
  const allComments = useSelector(
    (state: RootState) => state.comment.allComments
  );
  const allSubComments = useSelector(
    (state: RootState) => state.subComment.allSubComments
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchAllComments());
    dispatch(fetchAllSubComments());
    dispatch(fetchUsers());
  }, [dispatch]);

  const token = localStorage.getItem("token");

  const removeComment = (id: string) => {
    axios
      .delete(`${url}/comment/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          alert("Comment removed!");
          dispatch(fetchAllComments());
        }
      });
  };

  const removeSubComment = (id: string) => {
    axios
      .delete(`${url}/subComment/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          alert("SubComment removed!");
          dispatch(fetchAllSubComments());
        }
      });
  };

  return (
    <Box style={{ width: "90%", marginInline: "auto" }}>
      <Typography variant="h5" style={{ paddingBottom: "50px" }}>
        User information
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>USER</TableCell>
              <TableCell>IMAGE</TableCell>
              <TableCell align="center">COMMENT</TableCell>
              <TableCell align="center">BUSINESS</TableCell>
              <TableCell align="center">REMOVE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allComments
              .slice()
              .reverse()
              .map((comment, index) => {
                const indexUser = userList.findIndex(
                  (item) => item._id === comment.userId
                );
                const business = allBusiness.find(
                  (item) => item._id === comment.businessId
                );
                const subComments = allSubComments.filter(
                  (subComment) => subComment.commentId === comment._id
                );

                return (
                  <React.Fragment key={comment._id}>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        style={{ border: "1px solid grey" }}
                        align="left"
                      >
                        <strong>{index + 1}</strong>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {`${userList[indexUser]?.firstName} ${userList[indexUser]?.lastName}`}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <CardMedia
                          component="img"
                          height="140"
                          image={comment.image}
                          alt="Comment Image"
                          loading="lazy"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">{comment.text}</Typography>
                      </TableCell>
                      <TableCell align="center">{business?.title}</TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() =>
                            comment?._id && removeComment(comment._id)
                          }
                        >
                          remove comment
                        </Button>
                      </TableCell>
                    </TableRow>
                    {subComments
                      .slice()
                      .reverse()
                      .map((subComment, subIndex) => {
                        const subUser = userList.find(
                          (item) => item._id === subComment.userId
                        );
                        return (
                          <TableRow key={subComment._id}>
                            <TableCell align="left">
                              {`${index + 1}.${subIndex + 1}`}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {`${subUser?.firstName} ${subUser?.lastName}`}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <CardMedia
                                component="img"
                                height="140"
                                image={subComment.image}
                                alt="SubComment Image"
                                loading="lazy"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="body2">
                                {subComment.text}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">-</TableCell>
                            <TableCell align="center">
                              <Button
                                onClick={() =>
                                  subComment._id &&
                                  removeSubComment(subComment._id)
                                }
                              >
                                remove reply
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CmtManagement;
