
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { BusinessType } from "../../types/businessTypes";
import { CommentType } from "../../types/commentTypes";

type InitialState = {
  businessSearchResultList: BusinessType[];
  commentSearchResultList: CommentType[];
};

const initialState: InitialState = {
  businessSearchResultList: [],
  commentSearchResultList: [],
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setBusinessSearchResults: (state, action: PayloadAction<BusinessType[]>) => {
      state.businessSearchResultList = action.payload;
    },
    setCommentSearchResults: (state, action: PayloadAction<{ comments: CommentType[], businessId: string }>) => {
      const { comments, businessId } = action.payload;
      state.commentSearchResultList = comments.filter(comment => comment.businessId === businessId);
    },
  },
});

export const searchActions = searchSlice.actions;

export default searchSlice.reducer;